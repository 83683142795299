import {GET_CARRINHO, CARRINHO_ERROR, SALVA_CARRINHO} from '../types';
export const getCarrinho = () => async dispatch => {
  try {
    var res = {};
    if (localStorage.getItem ('state') !== null) {
      res = await localStorage.getItem ('state').carrinho;
    }
    dispatch ({
      type: GET_CARRINHO,
      payload: res,
    });
  } catch (e) {
    dispatch ({
      type: CARRINHO_ERROR,
      payload: console.log (e),
    });
    console.error (e);
    throw new Error (e);
  }
};

export const salvaCarrinho = carrinho => async dispatch => {
  try {
    // console.log (carrinho);
    dispatch ({
      type: SALVA_CARRINHO,
      payload: carrinho,
    });
  } catch (e) {
    dispatch ({
      type: CARRINHO_ERROR,
      payload: console.log (e),
    });
    console.error (e);
    throw new Error (e);
  }
};
