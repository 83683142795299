import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import ApiService from "../service/ApiService";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import LoadingOverlay from "react-loading-overlay";
import { getLoja } from "../store/actions/lojaAction";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./util";

class FormPagar extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      textoloading: "",
      pedido: {},
      message: "",
      erros: [],
      submitted: false,
      selectedOption: "cartaocredito",
      submitted2: false,
      pedidon: "",
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",
      issuer: "",
      focused: "",
      formData: null,
      parcelamento: [],
      redireciona2: "false",
      parcela: "",
      visa: "",
      mastercard: "",
      american: "",
      hiper: "",
      cabal: "",
      diners: "",
      elo: "",
      pix: "",
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeParcela = this.onChangeParcela.bind(this);
  }

  onChangeParcela(e) {
    this.setState({
      parcela: e.target.value,
    });
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  componentDidMount() {
    this.props.getLoja();
    ApiService.lojatitulo()
      .then((response) => {
        if (response.status === 200) {
          document.title = response.data + " | Pagamento";
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
    var user = this.props.cookies.get("user") || "";
    if (user === "") {
      this.setState({ submitted2: true });
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    var numero = params.get("numero");

    ApiService.pedidopendente(numero, user.usuario)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            pedido: response.data,
          });
          console.log(response.data);
          if (
            this.state.pedido.status !== "Pix" &&
            this.state.pedido.status !== "Processando"
          ) {
            this.setState({ redireciona2: "true" });
          }
          localStorage.setItem("totalcarrinho", this.state.pedido.total);
          this.parcelamento();
        } else {
          this.setState({ message: "Erro ao recuperar pedido" });
        }
      })
      .catch((e) => {
        this.setState({ message: "Erro ao recuperar pedido" });
        console.log(e);
      });
  }

  parcelamento() {
    var total =
      this.state.pedido.subtotal -
      this.state.pedido.desconto +
      this.state.pedido.valormontagem +
      this.state.pedido.frete;
    console.log("xxxx" + total);
    var maxparcelas = localStorage.getItem("maxparcelas");
    console.log(maxparcelas);
    var maxparcelas2 = localStorage.getItem("maxparcelas2");
    console.log(maxparcelas2);
    var taxa = localStorage.getItem("taxa");
    console.log(taxa);
    var valorminimo = localStorage.getItem("valorminimo");
    console.log(valorminimo);
    if (total !== undefined) {
      total = Number(total);
    }
    if (maxparcelas !== undefined) {
      maxparcelas = Number(maxparcelas);
    }
    if (valorminimo !== undefined) {
      valorminimo = Number(valorminimo);
    }
    if (maxparcelas2 !== undefined) {
      maxparcelas2 = Number(maxparcelas2);
    }
    if (taxa !== undefined) {
      taxa = Number(taxa);
    }

    this.state.parcelamento = [];
    var p = {};
    p.parcela = 1;
    p.valor = total;
    p.tipo = "sem juros";
    this.state.parcela =
      p.parcela + "x" + Number(p.valor).toFixed(2) + " sem juros";
    this.state.parcelamento.push(p);

    if (total < valorminimo) {
      this.state.parcelamento = [];
      p = {};
      p.parcela = 1;
      p.valor = total;
      p.tipo = "sem juros";
      this.state.parcela =
        p.parcela + "x" + Number(p.valor).toFixed(2) + " sem juros";
      this.state.parcelamento.push(p);
    }
    if (total > valorminimo) {
      this.state.parcelamento = [];
      for (var i = 1; i <= maxparcelas; i++) {
        p = {};
        p.parcela = i;
        p.valor = Number(total / i).toFixed(2);
        p.tipo = "sem juros";
        if (i === 1) {
          this.state.parcela =
            p.parcela + "x" + Number(p.valor).toFixed(2) + " sem juros";
          this.state.parcelamento.push(p);
        } else {
          if (p.valor >= valorminimo) {
            this.state.parcelamento.push(p);
          }
        }
      }
      if (taxa > 0) {
        var pos = 1;
        for (var i = maxparcelas + 1; i <= maxparcelas2; i++) {
          p = {};
          p.parcela = i;

          var totald = Number(total) * (Number(taxa) / 100.0) * pos;
          var totalc = Number(total + totald).toFixed(2);
          p.valor = Number(totalc / i).toFixed(2);
          p.tipo = "com juros(" + totalc + ")";

          if (p.valor >= valorminimo) {
            this.state.parcelamento.push(p);
          }
          pos++;
        }
      }
    }
    this.setState({ parcela: this.state.parcela });
    this.setState({ parcelamento: this.state.parcelamento });
  }

  handleOptionChange(evt) {
    this.setState({
      selectedOption: evt.target.value,
    });
  }

  async salvar(loja) {
    this.setState({ isActive: true });
    this.setState({ textoloading: "Processando seu pedido..." });
    var ok = true;

    if (loja.tipointegracao == "cielo"  || loja.tipointegracao == "rede" || loja.tipointegracao == "getnet") {
      this.state.pedido.bandeira = "master";
      if (
        document.getElementsByClassName("rccs__card rccs__card--visa").length >
        0
      ) {
        this.state.pedido.bandeira = "visa";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--elo").length > 0
      ) {
        this.state.pedido.bandeira = "elo";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--hipercard")
          .length > 0
      ) {
        this.state.pedido.bandeira = "hipercard";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--amex").length >
        0
      ) {
        this.state.pedido.bandeira = "amex";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--dinersclub")
          .length > 0
      ) {
        this.state.pedido.bandeira = "diners";
      }
    }

    this.state.pedido.tipopagamento = this.state.selectedOption;

    if (this.state.pedido.tipopagamento === "cartaocredito") {
      this.state.pedido.number = this.state.number;
      this.state.pedido.cvc = this.state.cvc;
      this.state.pedido.expiry = this.state.expiry;
      this.state.pedido.parcela = this.state.parcela;

      this.state.pedido.despesa = 0;
      if (this.state.pedido.parcela.includes("com juros")) {
        var oo = this.state.pedido.parcela.split("com juros");
        var voo = oo[1].replaceAll("(", "").replaceAll(")", "");
        var totaldesp =
          Number(voo) -
          Number(
            this.state.pedido.subtotal -
              this.state.pedido.desconto +
              this.state.pedido.valormontagem +
              this.state.pedido.frete
          );
        this.state.pedido.despesa = Number(totaldesp).toFixed(2);
        this.state.pedido.total =
          Number(
            this.state.pedido.subtotal -
              this.state.pedido.desconto +
              this.state.pedido.valormontagem +
              this.state.pedido.frete
          ) + Number(this.state.pedido.despesa);
      }
    }
    //
    console.log(this.state.pedido);

    var erros = "";

    if (this.state.pedido.tipopagamento === "cartaocredito") {
      var valid = require("card-validator");

      var card = null;

      if (
        this.state.number.trim().startsWith("37") ||
        this.state.number.trim().startsWith("34")
      ) {
        card = {
          card: "american-express",
          isPotentiallyValid: true,
          isValid: true,
        };
      }
      console.log(this.state.number.trim());
      console.log(this.state.number.trim().startsWith("37"));
      console.log(this.state.cvc.trim());
      console.log(card);

      var numberValidation = valid.number(this.state.number.trim());
      console.log(numberValidation);
      /*if (!numberValidation.isPotentiallyValid) {
        ok = false;
        erros += 'Número do Cartão de Crédito Inválido! ';
      }*/

      var datacartao = valid.expirationDate(this.state.expiry.trim());
      if (!datacartao.isPotentiallyValid) {
        ok = false;
        erros += "Data de Validade do Cartão de Crédito Inválido!";
      }

      var tamcvv = 3;
      if (
        this.state.number.trim().startsWith("37") ||
        this.state.number.trim().startsWith("34")
      ) {
        tamcvv = 4;
      }

      var cvc = valid.cvv(this.state.cvc.trim(), this.state.cvc.length);
      if (!cvc.isPotentiallyValid) {
        ok = false;
        erros += "CVC do Cartão de Crédito Inválido! ";
      }

      if (this.state.name.length < 0) {
        ok = false;
        erros += "Nome do Cartão de Crédito Inválido! ";
      }
    }

    if (this.state.selectedOption === "cartaocredito") {
      if (this.state.number.replaceAll(" ", "").length < 14) {
        ok = false;
        erros += "Número do Cartão de Crédito Inválido! ";
      }

      if (this.state.name.trim().length === 0) {
        ok = false;
        erros += "Nome Titular do Cartão de Crédito Inválido! ";
      }

      if (this.state.expiry.replaceAll("/", "").length !== 4) {
        ok = false;
        erros += "Validade do Cartão de Crédito Inválido! ";
      }

      /* if (this.state.cvc.length !== tamcvv) {
        ok = false;
        erros += 'CVC do Cartão de Crédito Inválido! ';
      }*/
    }

    if (ok === false) {
      window.scrollTo(0, 0);
      this.setState({ isActive: false });
      this.setState({ textoloading: "" });
      this.setState({ message: erros });
    }

    if (ok) {
      this.setState({ pedido: this.state.pedido });
      await ApiService.salvarpedido(this.state.pedido)
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("itenscarrinho", 0);
            this.setState({ pedidon: response.data.numeropedido });
            this.setState({
              submitted: true,
            });
            
            this.setState({ isActive: false });
            this.setState({ textoloading: "" });
          }
        })
        .catch((e) => {
          this.setState({ isActive: false });
          this.setState({ textoloading: "" });
          this.setState({ message: "Erro ao Salvar Pedido" });
          console.log(e);
        });
    }
  }

  render() {
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=pagamento" />;
    }

    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to={"/confirmacao?numero=" + this.state.pedidon} />;
    }

    const redireciona2 = this.state.redireciona2;
    if (redireciona2 == "true") {
      return <Redirect to={"/pedido?numero=" + this.state.pedido.numeropedido} />;
    }

    const { loja } = this.props.loja;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="checkout-section spad">
          <div className="container">
            <div className="checkout-form">
              {this.state.message && (
                <div className="alert alert-danger alert-dismissible">
                  {this.state.message}
                </div>
              )}
              {this.state.pedido.status == "Processando" && (
                <div className="row">
                  <div className="col-lg-4" style={{ marginBottom: "25px" }}>
                    <div className="place-order">
                      <h4>Seu Pedido</h4>
                      <div className="order-total">
                        <ul className="order-table">
                          <li>
                            Produtos <span>Total</span>
                          </li>

                          {this.state.pedido.itens &&
                            this.state.pedido.itens.map((item, index) => (
                              <li
                                key={index}
                                className="fw-normal"
                                style={{ textTransform: "uppercase" }}
                              >
                                {item.nome}
                                {item.cor != undefined
                                  ? "(" + item.cor + ")"
                                  : ""}
                                {" x "} {item.quantidade}
                                <span>
                                  R${" "}
                                  {Number(item.valor * item.quantidade).toFixed(
                                    2
                                  )}
                                </span>
                              </li>
                            ))}
                          <li
                            className="fw-normal"
                            style={{ fontWeight: "bold" }}
                          >
                            Subtotal{" "}
                            <span>
                              R$ {Number(this.state.pedido.subtotal).toFixed(2)}
                            </span>
                          </li>
                          <li
                            className="fw-normal"
                            style={{ fontWeight: "bold" }}
                          >
                            Frete{" "}
                            <span>
                              R$ {Number(this.state.pedido.frete).toFixed(2)}
                            </span>
                          </li>
                          <li
                            className="fw-normal"
                            style={{ fontWeight: "bold", color: "red" }}
                          >
                            Desconto{" "}
                            <span>
                              R$ {Number(this.state.pedido.desconto).toFixed(2)}
                            </span>
                          </li>
                          {loja.segmento == 11 && (
                            <li
                              className="fw-normal"
                              style={{ fontWeight: "bold" }}
                            >
                              Montagem{" "}
                              <span>
                                R${" "}
                                {Number(
                                  this.state.pedido.valormontagem
                                ).toFixed(2)}
                              </span>
                            </li>
                          )}
                          <li
                            className="total-price"
                            style={{ color: "#00b0ef", fontSize: "18px" }}
                          >
                            Total{" "}
                            <span>
                              R$ {Number(this.state.pedido.total).toFixed(2)}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <h4>Detalhes da Entrega</h4>
                    <div className="row">
                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="fir"
                        >
                          NOME:&nbsp;
                          <span />
                        </label>
                        <label
                          id="fir"
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.nome}
                        </label>
                      </div>

                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="cun-name"
                        >
                          CPF:&nbsp;
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.cpfcnpj}
                        </label>
                      </div>
                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="zip"
                        >
                          CEP:&nbsp;
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.cep}
                        </label>
                      </div>

                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="street"
                        >
                          ENDEREÇO:&nbsp;
                          <span />
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.logradouro}
                          ,&nbsp;
                          {this.state.pedido.numero}
                        </label>
                      </div>

                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="street"
                        >
                          BAIRRO:&nbsp;
                          <span />
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.bairro}
                        </label>
                      </div>

                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="street"
                        >
                          COMP:&nbsp;
                          <span />
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.complemento}
                        </label>
                      </div>

                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="town"
                        >
                          CIDADE E UF:&nbsp;
                          <span />
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.cidade}
                          ,&nbsp;
                          {this.state.pedido.uf}
                        </label>
                      </div>

                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="phone"
                        >
                          TELEFONE:&nbsp;
                          <span />
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.telefone}
                        </label>
                      </div>
                      <div className="col-lg-12">
                        <label
                          style={{
                            fontWeight: "bold",
                            width: "100px",
                            fontSize: "15px",
                          }}
                          htmlFor="phone"
                        >
                          EMAIL:&nbsp;
                          <span />
                        </label>
                        <label
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.pedido.email}
                        </label>
                      </div>
                    </div>
                    <br />
                  </div>

                  <div className="col-lg-4">
                    <div className="place-order">
                      <h4>Pagamento</h4>

                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          value="cartaocredito"
                          checked={
                            this.state.selectedOption === "cartaocredito"
                          }
                          onChange={(evt) => this.handleOptionChange(evt)}
                          id="defaultGroupExample1"
                          name="groupOfDefaultRadios"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="defaultGroupExample1"
                        >
                          Cartão de Crédito
                        </label>
                        <br />
                        <img
                          src={
                            this.state.visa !== ""
                              ? "data:image/jpeg;base64," + this.state.visa
                              : "img/visa.png"
                          }
                          alt={"visa"}
                          height="30px"
                          width="45px"
                        />
                        <img
                          src={
                            this.state.mastercard !== ""
                              ? "data:image/jpeg;base64," +
                                this.state.mastercard
                              : "img/mastercard.png"
                          }
                          alt={"mastercard"}
                          height="30px"
                          width="45px"
                        />
                        <img
                          src={
                            this.state.american !== ""
                              ? "data:image/jpeg;base64," + this.state.american
                              : "img/american-express.png"
                          }
                          alt={"american-express"}
                          height="30px"
                          width="45px"
                        />
                        <img
                          src={
                            this.state.hiper !== ""
                              ? "data:image/jpeg;base64," + this.state.hiper
                              : "img/hiper.png"
                          }
                          alt={"hiper"}
                          height="30px"
                          width="45px"
                        />
                        <img
                          src={
                            this.state.cabal !== ""
                              ? "data:image/jpeg;base64," + this.state.cabal
                              : "img/cabal.png"
                          }
                          alt={"cabal"}
                          height="30px"
                          width="45px"
                        />
                        <img
                          src={
                            this.state.diners !== ""
                              ? "data:image/jpeg;base64," + this.state.diners
                              : "img/diners.png"
                          }
                          alt={"diners"}
                          height="30px"
                          width="45px"
                        />
                        <img
                          src={
                            this.state.elo !== ""
                              ? "data:image/jpeg;base64," + this.state.elo
                              : "img/elo.png"
                          }
                          alt={"elo"}
                          height="30px"
                          width="45px"
                        />
                      </div>
                      {loja.tipointegracao === "esitef" && (
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            value="pix"
                            checked={this.state.selectedOption === "pix"}
                            onChange={(evt) => this.handleOptionChange(evt)}
                            id="defaultGroupExample2"
                            name="groupOfDefaultRadios"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="defaultGroupExample2"
                          >
                            Pix
                          </label>
                          <br />
                          <img
                            src={
                              this.state.pix !== ""
                                ? "data:image/jpeg;base64," + this.state.pix
                                : "img/pix.png"
                            }
                            alt={"pix"}
                            height="30px"
                            width="45px"
                          />
                        </div>
                      )}
                      <br />
                      {this.state.selectedOption === "cartaocredito" && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Cards
                              cvc={this.state.cvc}
                              expiry={this.state.expiry}
                              focused={this.state.focus}
                              name={this.state.name}
                              number={this.state.number}
                              placeholders={{ name: "" }}
                            />
                          </div>
                          &nbsp;
                          <div className="row">
                            <div className="col-6">
                              <label
                                style={{ fontWeight: "bold" }}
                                htmlFor="town"
                              >
                                Número do Cartão<span>*</span>:
                              </label>
                              <input
                                type="tel"
                                name="number"
                                className="form-control"
                                placeholder="**** **** **** ****"
                                pattern="[\d| ]{16,22}"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                style={{ width: "180px" }}
                              />
                            </div>

                            <div className="col-6">
                              <label
                                style={{ fontWeight: "bold" }}
                                htmlFor="town"
                              >
                                Nome<span>*</span>:
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Nome Completo"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                style={{ width: "220px" }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label
                                style={{ fontWeight: "bold" }}
                                htmlFor="town"
                              >
                                Validade<span>*</span>:
                              </label>
                              <input
                                type="tel"
                                name="expiry"
                                className="form-control"
                                placeholder="**/**"
                                pattern="\d\d/\d\d"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                style={{ width: "82px" }}
                              />
                            </div>
                            <div className="col-3">
                              <label
                                style={{ fontWeight: "bold" }}
                                htmlFor="town"
                              >
                                {" "}
                                CVC<span>*</span>:
                              </label>
                              <input
                                type="password"
                                name="cvc"
                                maxLength={4}
                                className="form-control"
                                placeholder="CVC"
                                pattern="\d{3,4}"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                style={{ width: "82px" }}
                              />
                            </div>
                            <div className="col-6">
                              <label
                                style={{ fontWeight: "bold" }}
                                htmlFor="town"
                              >
                                Parcelamento
                                <span />:
                              </label>

                              <select
                                className="form-control"
                                value={this.state.parcela}
                                onChange={this.onChangeParcela}
                                name="status"
                                style={{ width: "220px", height: "46px" }}
                              >
                                {this.state.parcelamento &&
                                  this.state.parcelamento.map((parcela) => (
                                    <option
                                      value={
                                        parcela.parcela +
                                        "x" +
                                        Number(parcela.valor).toFixed(2) +
                                        " " +
                                        parcela.tipo
                                      }
                                    >
                                      {parcela.parcela +
                                        " x R$" +
                                        Number(parcela.valor).toFixed(2)}{" "}
                                      {parcela.tipo}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="order-btn">
                        <button
                          onClick={() => this.salvar(loja)}
                          className="site-btn place-btn"
                          style={{
                            backgroundColor: "green",
                            border: "none",
                            color: "white",
                            padding: "12px 27px",
                            textAlign: "center",
                            textDecoration: "none",
                            display: "inline-block",
                            fontSize: "14px",
                            borderRadius: "5px 5px 5px 5px",
                          }}
                        >
                          Finalizar Compra
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  loja: state.loja,
});

const mapDispatchToProps = (dispatch) => ({
  getLoja: () => dispatch(getLoja()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(FormPagar));
