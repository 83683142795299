import React, {Component} from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import FormContato from '../componentes/FormContato';
import Bread from '../componentes/Bread';


class Contato extends Component {
  render () {
    return (
      <div>
       <div id="preloder" >
          <div className="loader" />
        </div>
        <Header />
        <Bread nome={'Contato'}/>
        <FormContato />
        <Footer />
      </div>
    );
  }
}

export default Contato;
