import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {withCookies} from 'react-cookie';
import ApiService from '../service/ApiService';
import Moment from 'react-moment';

class FormPedidos extends Component {
  constructor () {
    super ();
    this.state = {
      nome: '',
      cpf: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      email: '',
      telefone: '',
      submitted2: false,
      submitted3: false,
      pedidos: [],
      saldoponto: 0,
      saldocash: 0,
    };
    this.editar = this.editar.bind (this);
  }

  editar () {
    this.setState ({submitted3: true});
  }

  componentDidMount () {
    ApiService.lojatitulo ()
    .then (response => {
      if (response.status === 200) {
        document.title = response.data + ' | Meus Pedidos';
      }
    })
    .catch (e => {
      ///  alert ('Erro ao recuperar idtawkto email');
      console.log (e);
    });
    var user = this.props.cookies.get ('user') || '';

    if (user === '') {
      this.setState ({submitted2: true});
    } else {
      console.log (user.nome);
      this.setState ({nome: user.nome});
      this.setState ({email: user.email});
      this.setState ({cpf: user.cpfcnpj});
      this.setState ({telefone: user.telefone});
      this.setState ({cep: user.cep});
      this.setState ({cidade: user.cidade});
      this.setState ({uf: user.uf});
      this.setState ({endereco: user.logradouro});
      this.setState ({complemento: user.complemento});
      this.setState ({numero: user.numero});
      this.setState ({bairro: user.bairro});

      console.log ('sdfasdf' + user.email);
      ApiService.pedidos(user.email)
        .then (response => {
          if (response.status === 200) {
            this.setState ({
              pedidos: response.data,
            });
            // console.log (response.data);
          }
        })
        .catch (e => {
          this.setState ({message: 'Erro ao recuperar pedidos'});
          console.log (e);
        });

      ApiService.saldoponto (user.email)
        .then (response => {
          if (response.status === 200) {
            this.setState ({
              saldoponto: response.data,
            });
            // console.log (response.data);
          }
        })
        .catch (e => {
          this.setState ({message: 'Erro ao recuperar saldoponto'});
          console.log (e);
        });

      ApiService.saldocash (user.email)
        .then (response => {
          if (response.status === 200) {
            this.setState ({
              saldocash: response.data,
            });
            console.log (response.data);
          }
        })
        .catch (e => {
          this.setState ({message: 'Erro ao recuperar saldocash'});
          console.log (e);
        });
    }

  }

  badge (status) {
    switch (status) {
      case "Enviado":
        return "badge badge-success";
      case "Pago":
        return "badge badge-success";
      case "Pendente":
        return 'badge badge-info';
      case "NFe Gerada.":
        return 'badge badge-primary';
      case "Cancelado":
        return 'badge badge-danger';
      case "Processando":
        return 'badge badge-warning';
      case "Processando.":
        return 'badge badge-warning';
      case "Danfe Gerada":
        return 'badge badge-primary';
      case "Em Transito":
        return "badge badge-success";
      default:
        return '';
    }
  }

  render () {
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=conta" />;
    }
    const submitted3 = this.state.submitted3;
    if (submitted3 === true) {
      return <Redirect to="/editar-cadastro" />;
    }
    return (
      <section className="checkout-section spad">
        <div className="container">
          <form action="#" className="checkout-form">
            <div className="row">
              

              

              <div className="col-lg-12">
                <div className="place-order">
                  <h4>Meus Pedidos</h4>

                  <div className="col-12 table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Pedido</th>
                          <th>Data e Hora</th>
                          <th>Situação</th>
                          <th>Total</th>

                        </tr>
                      </thead>
                      <tbody>
                        {this.state.pedidos &&
                          this.state.pedidos.map ((pedido, index) => (
                            <tr key={index} style={{fontSize: '13px'}}>
                              <td>
                                {' '}{' '}
                                <a
                                  href={'/pedido?numero=' + pedido.numeropedido}
                                >
                                  {pedido.numeropedido}
                                </a>
                              </td>
                              <td>
                                {' '}<Moment format="DD/MM/YYYY HH:mm:ss">
                                  {pedido.datapedido}
                                </Moment>
                              </td>
                              <td>
                                <span className={this.badge (pedido.status)} style={{width:"100px", padding:"5px"}}>
                                  {pedido.status ==='Processando' ? 'Pendente' : pedido.status}
                                </span>  
                              </td>
                              <td>R$ {Number (pedido.total).toFixed (2)}</td>
                            </tr>
                          ))}

                      </tbody>
                    </table>
                    <div className="card-footer clearfix" >
                   
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default withCookies (FormPedidos);
