import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import LoadingOverlay from "react-loading-overlay";
import ApiService from "../service/ApiService";

class FormLista extends Component {
  constructor() {
    super();
    this.state = {
      submitted2: false,
      submitted: false,
      nome: "",
      descricao: "",
      email: "",
      message: "",
    };
    this.salvar = this.salvar.bind(this);
    this.onChangenome = this.onChangenome.bind(this);
    this.onChangedescricao = this.onChangedescricao.bind(this);
  }

  onChangenome(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onChangedescricao(e) {
    this.setState({
      descricao: e.target.value,
    });
  }

  salvar() {
    var ok = true;
    var erros = "";
    if (this.state.nome.length <= 0) {
      erros += "digite nome da lista,";
    }
    if (this.state.descricao.length <= 0) {
      erros += "digite descricao da lista,";
    }
    if (erros.length > 0) {
      ok = false;
      alert(erros);
    }

    if (ok) {
      var data = {
        nome: this.state.nome,
        descricao: this.state.descricao,
        email: this.state.email,
        status: "Ativo",
      };

      ApiService.salvarlista(data)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              submitted: true,
            });
            // console.log (response.data);
          }
        })
        .catch((e) => {
          this.setState({ message: "Erro ao salvar lista!" });
          console.log(e);
        });
    }
  }

  componentDidMount() {
    ApiService.lojatitulo()
      .then((response) => {
        if (response.status === 200) {
          document.title = response.data + " | Criar Lista";
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
    var cli = this.props.cookies.get("user") || "";
    console.log("user ck" + cli);
    if (cli === "") {
      this.setState({ submitted2: true });
    }
    this.setState({ email: cli.email });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/listas" />;
    }

    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=criar-lista" />;
    }

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="checkout-section spad">
          <div className="container">
            <div className="checkout-form" style={{ backgroundColor: "#fff" }}>
              <div className="row">
                {this.state.message && (
                  <div className="alert alert-danger alert-dismissible">
                    {this.state.message}
                  </div>
                )}
                <div className="col-lg-12">
                  <h4>Nova Lista de Compras</h4>
                  <div className="row">
                    <div className="col-lg-5">
                      <label style={{ fontWeight: "bold" }} htmlFor="zip">
                        Nome da Lista<span>*</span>:
                      </label>
                      <input
                      autoFocus
                        required
                        maxLength={50}
                        value={this.state.nome}
                        onChange={this.onChangenome}
                        name="nome"
                        placeholder="Insira o Nome da Lista"
                        type="text"
                        //id="street"
                        style={{
                   
                          borderWidth: "1px",
                          borderRadius: "5px 5px 5px 5px",
                        }}
                        // className="street-first form-control"
                        // maxLength={100}
                        // value={this.state.logradouro}
                        // onChange={this.onChangeEndereco}
                      />
                    </div>

                    <div className="col-lg-7">
                      <label style={{ fontWeight: "bold" }} htmlFor="street">
                        Descrição<span>*</span>:
                      </label>
                      <input
                        required
                        maxLength={200}
                        value={this.state.descricao}
                        onChange={this.onChangedescricao}
                        name="descricao"
                        placeholder="Insira a Descrição da Lista"
                        type="text"
                        //id="street"
                        style={{
                       
                          borderWidth: "1px",
                          borderRadius: "5px 5px 5px 5px",
                        }}
                        // className="street-first form-control"
                        // maxLength={100}
                        // value={this.state.logradouro}
                        // onChange={this.onChangeEndereco}
                      />
                    </div>

                    {/* <div className="col-lg-3">
                      <label style={{fontWeight: 'bold'}} htmlFor="town">
                        BAIRRO<span>*</span>:
                      </label>
                      <input
                        placeholder="Insira o Bairro"
                        type="text"
                        id="bairro"
                        maxLength={50}
                        style={{
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        value={this.state.bairro}
                        onChange={this.onChangeBairro}
                        className="form-control"
                      />
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={this.salvar}
                      className="site-btn register-btn"
                      //   onClick={this.salvar}
                      style={{
                        border: "none",
                        color: "white",
                        textAlign: "center",
                        textDecoration: "none",
                        display: "inline-block",
                        fontSize: "16px",
                        borderRadius: "5px 5px 5px 5px",
                      }}
                    >
                      Salvar Lista
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(FormLista));
