import axios from "axios";
import ConfigJson from './config.json'
const serverurl = ConfigJson.host_tomcat;

class ApiService {
  apiurl() {
    var url = window.location.href;
    //console.log(window.location)
    let domain = window.location.hostname;
    console.log('dominio '+domain)
    var api = this.serverapi(window.location.href)
    console.log('api '+api)
    if (api == undefined) {
      if (url.includes("https://")) {
        return "https://" + domain + ":" + ConfigJson.porta_tomcat_seguro;
      } else {
        return "http://" + domain + ":" + ConfigJson.porta_tomcat;
      }
    } else {
      return api;
    }
  }

  header() {
    //console.log ('site url' + window.location.href);
    var h = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        tid: this.site(window.location.href),
      },
      timeout: 60000,
    };
    return h;
  }

  headersemtimeout() {
    //console.log ('site url' + window.location.href);
    var h = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        tid: this.site(window.location.href),
      },
    };
    return h;
  }

  site(url) {
 //   console.log(url);
   // console.log(ConfigJson.sites)
    var site = ConfigJson.sites.filter(l => {
      return url.includes(l.dominio.toLowerCase() );
    });
  //  console.log(site)
    if(site.length>0){
      return site[0].cnpj;
    }else {
      window.location.assign("http://google.com");
    }
  }

  serverapi(url) {
    //   console.log(url);
    // console.log(ConfigJson.sites)
    var site = ConfigJson.sites.filter((l) => {
      return url.includes(l.dominio.toLowerCase());
    });
    //  console.log(site)
    if (site.length > 0) {
      return site[0].api;
    } else {
      return undefined
    }
  }

  headerd() {
    var h = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        tid: this.site(window.location.href),
      },
      timeout: 60000,
      responseType: "arraybuffer",
    };
    return h;
  }

  //CATEGORIAS
  categoriasp() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/categorias/listarp",
      this.header()
    );
    return g;
  }

  categorias() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/categorias",
      this.header()
    );
    return g;
  }

  categoria(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/categorias/" + id,
      this.header()
    );
    // console.log (g);
    return g;
  }

  newsletter(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/categorias/newsletter",
      data,
      this.header()
    );
    return g;
  }

  salvarcategoria(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/categorias",
      data,
      this.header()
    );
    return g;
  }

  atualizarcategoria(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/categorias",
      data,
      this.header()
    );
    return g;
  }

  //SUBCATEGORIAS
  subcategorias() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/subcategorias",
      this.header()
    );
    return g;
  }

  subcategoria(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/subcategorias/" + id,
      this.header()
    );
    //console.log (g);
    return g;
  }

  salvarsubcategoria(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/subcategorias",
      data,
      this.header()
    );
    return g;
  }

  atualizarsubcategoria(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/subcategorias",
      data,
      this.header()
    );
    return g;
  }

  //PRODUTOS
  produtos() {
    var g = axios.get(this.apiurl() + "/ecommerce/produtos", this.header());
    return g;
  }

  produtopesquisa(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/produtos/pesquisa",
      data,
      this.header()
    );
    return g;
  }

  produtopesquisa5(data) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/pesquisar5/" + data,
      this.header()
    );
    return g;
  }

  produtosp() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/listarp",
      this.header()
    );
    return g;
  }

  cprodutosp() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/clistarp",
      this.header()
    );
    return g;
  }

  produtospd() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/listarpd",
      this.header()
    );
    return g;
  }

  produtospc(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/listarpc/" + id,
      this.header()
    );
    return g;
  }

  produto(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/" + id,
      this.header()
    );
    // console.log (g);
    return g;
  }

  salvarproduto(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/produtos",
      data,
      this.header()
    );
    return g;
  }

  fotoproduto(produto) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/foto/" + produto,
      this.header()
    );
    return g;
  }

  fotosdestaque(destaque) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/destaques/foto/" + destaque,
      this.header()
    );
    return g;
  }

  fotosdestaque2(destaque) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/destaques/foto2/" + destaque,
      this.header()
    );
    return g;
  }

  excluirlista(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/listas/excluir/" + id,
      this.header()
    );
    return g;
  }

  excluiritemlista(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/listas/excluir/item/" + id,
      this.header()
    );
    return g;
  }

  fotosproduto(produto) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/fotos/" + produto,
      this.header()
    );
    return g;
  }

  uploadproduto(file, produto, nome, padrao) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("produto", produto);
    formData.append("nome", nome);
    formData.append("padrao", padrao);

    var g = axios.post(
      this.apiurl() + "/ecommerce/produtos/upload",
      formData,
      this.header()
    );
    return g;
  }

  atualizarfoto(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/produtos/fotos",
      data,
      this.header()
    );
    return g;
  }

  excluirfoto(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/produtos/excluirfotos",
      data,
      this.header()
    );
    return g;
  }

  atualizarproduto(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/produtos",
      data,
      this.header()
    );
    return g;
  }

  //DESTAQUES
  destaquesp() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/destaques/listarp",
      this.header()
    );
    return g;
  }

  salvarusuario(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/clientes",
      data,
      this.header()
    );
    return g;
  }

  salvarlista(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/listas",
      data,
      this.header()
    );
    return g;
  }

  salvarcontato(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/contatos",
      data,
      this.header()
    );
    return g;
  }

  atualizarusuario(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/clientes",
      data,
      this.header()
    );
    return g;
  }

  entrar(usuario, senha) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/clientes/entrar/" + usuario + "/" + senha,
      this.header()
    );
    return g;
  }

  //CLIENTES
  clienteemail(email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/clientes/" + email,
      this.header()
    );
    return g;
  }

  salvarpedido(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/pedidos",
      data,
      this.headersemtimeout()
    );
    return g;
  }

  cancelarpedido(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/pedidos/cancelamento",
      data,
      this.header()
    );
    return g;
  }

  pedidos(email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/email/" + email,
      this.header()
    );
    return g;
  }

  pedidosx(email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/emailx/" + email,
      this.header()
    );
    return g;
  }

  listas(email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/listas/emailx/" + email,
      this.header()
    );
    return g;
  }

  saldoponto(email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/saldoponto/" + email,
      this.header()
    );
    return g;
  }

  saldocash(email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/saldocashback/" + email,
      this.header()
    );
    return g;
  }

  lista(codigo) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/listas/" + codigo,
      this.header()
    );
    return g;
  }

  itenslista(codigo) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/listas/itens/" + codigo,
      this.header()
    );
    return g;
  }

  pedido(numero, email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/numero/" + numero + "/" + email,
      this.header()
    );
    return g;
  }

  pedidopendente(numero, email) {
    var g = axios.get(
      this.apiurl() +
        "/ecommerce/pedidos/numero/" +
        numero +
        "/" +
        email +
        "/pendente",
      this.header()
    );
    return g;
  }

  pedidopago(numero, email) {
    var g = axios.get(
      this.apiurl() +
        "/ecommerce/pedidos/numero/" +
        numero +
        "/" +
        email +
        "/pago",
      this.header()
    );
    return g;
  }

  pedidopix(numero, email) {
    var g = axios.get(
      this.apiurl() +
        "/ecommerce/pedidos/numero/" +
        numero +
        "/" +
        email +
        "/pix",
      this.header()
    );
    return g;
  }

  eventosp(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/eventos/listarpn/" + pedido,
      this.header()
    );
    return g;
  }

  downloadnfe(pedido, tipo) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/nfes/downloadFile/" + pedido + "/" + tipo,
      this.headerd()
    );
    return g;
  }

  salvaritemlista(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/listas/item",
      data,
      this.header()
    );
    return g;
  }

  //
  criarpagamento(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/esitef/criar",
      data,
      this.header()
    );
    return g;
  }

  //
  loja() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/loja",
      this.header()
    );
    return g;
  }

  idtawkto() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/loja/idtawkto",
      this.header()
    );
    return g;
  }

  cores() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/loja/cores",
      this.header()
    );
    return g;
  }

  lojarodape() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/foto/rodape",
      this.header()
    );
    console.log(g);
    return g;
  }

  lojatopo() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/foto/topo",
      this.header()
    );
    console.log(g);
    return g;
  }

  calcularPrazoFrete(cep) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/calcularPrazoFrete/"+cep,
      this.header()
    );
    console.log(g);
    return g;
  }

  lojatitulo() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/titulo",
      this.header()
    );
    console.log(g);
    return g;
  }

  segmento() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/segmento",
      this.header()
    );
    console.log(g);
    return g;
  }

  produtospage(page, qtd, ordem) {
    var g = axios.get(
      this.apiurl() +
        `/ecommerce/produtos/page/${ordem}?page=${page}&size=${qtd}`,
      this.header()
    );
    console.log(g);
    return g;
  }

  produtospagedesconto(page, qtd, ordem) {
    var g = axios.get(
      this.apiurl() +
        `/ecommerce/produtos/page/desconto/${ordem}?page=${page}&size=${qtd}`,
      this.header()
    );
    console.log(g);
    return g;
  }

  produtospesquisapage(page, qtd, ordem, data) {
    var g = axios.post(
      this.apiurl() +
        `/ecommerce/produtos/pesquisa/page/${ordem}?page=${page}&size=${qtd}`,
      data,
      this.header()
    );
    console.log(g);
    return g;
  }
}
export default new ApiService();
