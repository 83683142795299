import {GET_DESTAQUES} from '../types';

const initialState = {
  destaques: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DESTAQUES:
      return {
        ...state,
        destaques: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
