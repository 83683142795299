import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getCProdutos, getProdutosd } from "../store/actions/produtoAction";
import { salvaCarrinho } from "../store/actions/carrinhoAction";
import ApiService from "../service/ApiService";
import { withCookies } from "react-cookie";

class Content extends Component {
  constructor() {
    super();
    this.state = {
      imgs: [],
      imgsd: [],
      user: "",
    };
  }
  componentDidMount() {
    try {
      //  window.scrollTo (0, 0);
      this.props.getCProdutos();
      this.props.getProdutosd();

      ApiService.lojatitulo()
        .then((response) => {
          if (response.status === 200) {
            document.title = response.data;
          }
        })
        .catch((e) => {
          ///  alert ('Erro ao recuperar idtawkto email');
          console.log(e);
        });
      var user = this.props.cookies.get("user") || "";
      this.setState({ user: user });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  adicionaritem(preco, desconto, promocao, nome, codigo, index, carrinho) {
    try {
      var item = {};
      item.quantidade = 1;
      item.preco = Number(preco);
      item.promocao = Number(promocao);
      item.desconto = Number(desconto);
      item.nome = nome;
      item.codigo = codigo;
      item.foto = {};
      item.foto.data = this.state.imgs[index];
      item.valormontagem=0;

      if (carrinho.itens === undefined) {
        carrinho.itens = [];
        carrinho.total = 0;
      }
      var tem = false;
      var pos = 0;
      for (var i = 0; i < carrinho.itens.length; i++) {
        var it = carrinho.itens[i];
        if (it.codigo === codigo) {
          tem = true;
          break;
        }
        pos++;
      }
      if (tem === false) {
        carrinho.itens.push(item);
      } else {
        carrinho.itens[pos].quantidade += 1;
      }

      var total = 0;
      var subtotal = 0;
      var descontot = 0;
      var montagem=0;

    montagem = carrinho.itens.reduce(
      (prev, next) => prev + next.valormontagem,
      0
    );

      subtotal = carrinho.itens.reduce(
        (prev, next) => prev + next.preco * next.quantidade,
        0
      );
      total = carrinho.itens.reduce(
        (prev, next) =>
          prev +
          (next.preco * next.quantidade - next.desconto * next.quantidade),
        0
      );
      descontot = carrinho.itens.reduce(
        (prev, next) => prev + next.desconto * next.quantidade,
        0
      );
      carrinho.total = total+montagem;
      carrinho.subtotal = subtotal;
      carrinho.desconto = descontot;
      carrinho.valormontagem=montagem;
      //console.log (carrinho);
      this.props.salvaCarrinho(carrinho);
      //window.scrollTo (0, 0);
      this.addCarrinho();
      localStorage.setItem("itenscarrinho", 1);
    } catch (error) {
      console.log(error);
    }
  }

  adicionaritem2(preco, desconto, promocao, nome, codigo, index, carrinho) {
    try {
      var item = {};
      item.quantidade = 1;
      item.preco = Number(preco);
      item.promocao = Number(promocao);
      item.desconto = Number(desconto);
      item.nome = nome;
      item.codigo = codigo;
      item.foto = {};
      item.foto.data = this.state.imgsd[index];
      item.valormontagem=0;

      if (carrinho.itens === undefined) {
        carrinho.itens = [];
        carrinho.total = 0;
      }
      var tem = false;
      var pos = 0;
      for (var i = 0; i < carrinho.itens.length; i++) {
        var it = carrinho.itens[i];
        if (it.codigo === codigo) {
          tem = true;
          break;
        }
        pos++;
      }
      if (tem === false) {
        carrinho.itens.push(item);
      } else {
        carrinho.itens[pos].quantidade += 1;
      }

      var total = 0;
      var subtotal = 0;
      var descontot = 0;
      var montagem=0;

    montagem = carrinho.itens.reduce(
      (prev, next) => prev + next.valormontagem,
      0
    );

      subtotal = carrinho.itens.reduce(
        (prev, next) => prev + next.preco * next.quantidade,
        0
      );
      total = carrinho.itens.reduce(
        (prev, next) =>
          prev +
          (next.preco * next.quantidade - next.desconto * next.quantidade),
        0
      );
      descontot = carrinho.itens.reduce(
        (prev, next) => prev + next.desconto * next.quantidade,
        0
      );
      carrinho.total = total+montagem;
      carrinho.subtotal = subtotal;
      carrinho.desconto = descontot;
      carrinho.valormontagem=montagem;
      //console.log (carrinho);
      this.props.salvaCarrinho(carrinho);
      //window.scrollTo (0, 0);
      this.addCarrinho();
      localStorage.setItem("itenscarrinho", 1);
    } catch (error) {
      console.log(error);
    }
  }

  addCarrinho() {
    // Get the snackbar DIV
    var x = document.getElementById("addcarrinho");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  async pegarimg(cprodutos, index, index2) {
    // console.log ('categoria ' + cprodutos[index].nome);
    // console.log ('produto ' + cprodutos[index].produtos[index2].nome);
    ApiService.fotoproduto(cprodutos[index].produtos[index2].id)
      .then((response) => {
        if (response.status === 200) {
          this.state.imgs[index + "" + index2] = response.data.data;
          //    console.log ('pppp' + this.state.imgs[index + '' + index2]);
          this.setState({ imgs: this.state.imgs });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async pegarimgd(produtosd, index2) {
    console.log("produtod... " + produtosd[index2].nome);
    ApiService.fotoproduto(produtosd[index2].id)
      .then((response) => {
        if (response.status === 200) {
          this.state.imgsd[index2] = response.data.data;
          // console.log ('pppp' + this.state.imgsd[index2]);
          this.setState({ imgsd: this.state.imgsd });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  colunas(tamanho) {
    switch (tamanho) {
      case 1:
        return 'col-lg-8 col-sm-2 col-md-10';
      case 2:
        return 'col-lg-6 col-sm-2 col-md-6';
      case 3:
        return 'col-lg-4 col-sm-2 col-md-4';
      case 4:
        return 'col-lg-3 col-sm-2 col-md-6';
      case 5:
        return 'col-lg-2 col-sm-2 col-md-4';
      case 6:
        return 'col-lg-2 col-sm-2 col-md-4';
      default:
        return "";
    }
  }

  adicionarfavorito(pp) {
    console.log("favorito ");
    console.log(pp);
    console.log(this.state.user);
    // this.props.salvafavorito(pp);
    //window.scrollTo (0, 0);
    if (this.state.user !== "") {
      var data = {};
      data.email = this.state.user.usuario;
      var prod = {};
      prod.id = pp.id;
      data.produto = prod;

      console.log(data.email);
      console.log(data.produto);

      ApiService.salvaritemlista(data)
        .then((response) => {
          if (response.status === 200) {
            this.addfavorito();
          }
        })
        .catch((e) => {
          //this.setState ({message: 'Erro ao recuperar listas'});
          console.log(e);
        });
    }
  }

  addfavorito() {
    // Get the snackbar DIV
    var x = document.getElementById("addfavorito");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  render() {
    const { cprodutos } = this.props.cprodutos;
    const { produtosd } = this.props.produtosd;
    const { carrinho } = this.props.carrinho;
    return (
      <section className="women-banner spad">
        {/* <div id="preloder">
        <div className="loader"></div>
    </div> */}

        <div className="container-fluid">
          {cprodutos &&
            cprodutos.map((cproduto, index) => (
              <div className="row">
                <div
                  className="product-list"
                  style={{ justifyContent: "center" }}
                >
                  <div className="filter-control">
                    <ul>
                      <li
                        className="active"
                        style={{ textTransform: "uppercase" }}
                      >
                        <a
                          style={{ color: "#ED3237" }}
                          href={"/pesquisa?categoria=" + cproduto.nome}
                        >
                          {cproduto.nome}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="row" style={{ alignItems: "center" }}>
                    {cproduto.produtos &&
                      cproduto.produtos.map((produto, index2) => (
                        <div
                          key={index2}
                          className={this.colunas(cproduto.produtos.length)}
                        >
                          
                          <div className="product-item">                          
                            <div className="pi-pic">        
                                <div class="ht3">                                  
                                  <i class="icon_heart" style={{color:"#ff5252"}}
                                    onClick={() => this.adicionarfavorito(produto)}
                                  ></i>                                    
                                </div>                      
                              <NavLink
                                to={`/produto?i=${
                                  produto.id
                                }&n=${produto.nome.replaceAll(" ", "-")}`}
                              >
                                <img
                                  src={
                                    this.state.imgs[index + "" + index2] != null
                                      ? "data:image/jpeg;base64," +
                                        this.state.imgs[index + "" + index2]
                                      : "/img/lo.gif"
                                  }
                                  alt={
                                    produto.foto != null
                                      ? produto.foto.nome
                                      : "img"
                                  }
                                  onLoad={() =>
                                    this.pegarimg(cprodutos, index, index2)
                                  }
                                  className="border"
                                  height="200px"
                                  widht="auto"
                                />                               

                              </NavLink>
                              {produto.desconto > 0 && 
                                <div className="sale2">
                                  {produto.descontop}% OFF
                                </div>}                                

                              {/*  <ul>
                            <li className="w-icon active"><a href="#"><i className="icon_bag_alt"></i>{' '}Comprar</a></li>
                          </ul> */}
                            </div>
                            <div className="pi-text">
                              <div className="catagory-name">
                                {produto.subcategoria.categoria.nome}
                              </div>
                               
                              <h5
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "6px",
                                  fontSize: "12px",
                                  height: "40px",
                                  widht: "50px",
                                  textTransform: "uppercase",
                                }}
                              >
                                {produto.nome}
                              </h5>
                              <h6
                                style={{
                                  fontWeight: "700",
                                  marginTop: "6px",
                                  color: "#00b0ef",
                                }}
                              >
                                {produto.laboratorio}
                              </h6>

                              <div
                                className="product-price"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {produto.desconto > 0 && (
                                  <span style={{ marginRight: "4px" }}>
                                    R$
                                    {produto.preco.toFixed(2)}
                                  </span>
                                )}
                                R$
                                {produto.desconto > 0
                                  ? (produto.preco - produto.desconto).toFixed(
                                      2
                                    )
                                  : produto.preco.toFixed(2)}{" "}
                                <h1
                                  style={{
                                    fontSize: "10px",
                                    marginLeft: "2.5px",
                                  }}
                                >
                                  {" "}
                                  cada
                                </h1>
                              </div>
                              {produto.estoque === 0 && (
                                <a
                                  href={`/produto?i=${
                                    produto.id
                                  }&n=${produto.nome.replaceAll(" ", "-")}`}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "gray" /* Green */,
                                      border: "none",
                                      color: "white",
                                      padding: "10px 27px",
                                      textAlign: "center",
                                      textDecoration: "none",
                                      display: "inline-block",
                                      fontSize: "16px",
                                      borderRadius: "10px 10px 10px 10px",
                                    }}
                                  >
                                    <i className="icon_bag_alt" /> AVISE-ME
                                  </button>
                                </a>
                              )}

                              {produto.estoque > 0 && (
                                <button
                                  className="btn-comprar"
                                  onClick={() =>
                                    this.adicionaritem(
                                      produto.preco,
                                      produto.desconto,
                                      produto.promocao,
                                      produto.nome,
                                      produto.id,
                                      index + "" + index2,
                                      carrinho
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#4CAF50" /* Green */,
                                    border: "none",
                                    color: "white",
                                    padding: "10px 27px",
                                    textAlign: "center",
                                    textDecoration: "none",
                                    display: "inline-block",
                                    fontSize: "16px",
                                    borderRadius: "10px 10px 10px 10px",
                                  }}
                                >
                                  <i className="icon_bag_alt" /> COMPRAR
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}

          {produtosd.length > 0 && (
            <div className="row">
              <div
                className="product-list"
                style={{ justifyContent: "center" }}
              >
                <div className="filter-control">
                  <ul>
                    <li className="active">
                      {" "}
                      <a style={{ color: "#ED3237" }} href={"/desconto-do-dia"}>
                        OFERTAS ESPECIAIS!
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row" style={{ alignItems: "center" }}>
                  {produtosd &&
                    produtosd.map((produtod, index2) => (
                      <div
                        key={index2}
                        className={this.colunas(produtosd.length)}
                      >
                        <div className="product-item">
                          <div className="pi-pic">
                            <div class="ht3">
                              <i
                                onClick={() => this.adicionarfavorito(produtod)}
                                class="icon_heart"
                                style={{ color: "#ff5252" }}
                              ></i>
                            </div>

                            <NavLink
                              to={`/produto?i=${
                                produtod.id
                              }&n=${produtod.nome.replaceAll(" ", "-")}`}
                            >
                              {" "}
                              <img
                                src={
                                  this.state.imgsd[index2] != null
                                    ? "data:image/jpeg;base64," +
                                      this.state.imgsd[index2]
                                    : "/img/lo.gif"
                                }
                                alt={
                                  produtod.foto != null
                                    ? produtod.foto.nome
                                    : "img"
                                }
                                onLoad={() => this.pegarimgd(produtosd, index2)}
                                className="border"
                                style={{ maxWidth: "170px" }}
                                height="200px"
                              />
                            </NavLink>
                            {produtod.desconto > 0 && (
                              <div className="sale2">
                                {produtod.descontop}% OFF
                              </div>
                            )}

                            {/*  <ul>
                            <li className="w-icon active"><a href="#"><i className="icon_bag_alt"></i>{' '}Comprar</a></li>
                          </ul> */}
                          </div>

                          <div className="pi-text">
                            <div className="catagory-name">
                              {produtod.subcategoria.categoria.nome}
                            </div>

                            <h5
                              style={{
                                fontWeight: "bold",
                                marginTop: "6px",
                                fontSize: "14px",
                              }}
                            >
                              {produtod.nome}
                            </h5>
                            <h6
                              style={{
                                fontWeight: "700",
                                marginTop: "6px",
                                color: "#00b0ef",
                              }}
                            >
                              {produtod.laboratorio}
                            </h6>

                            <div
                              className="product-price"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {produtod.desconto > 0 && (
                                <span style={{ marginRight: "4px" }}>
                                  R${produtod.preco.toFixed(2)}
                                </span>
                              )}
                              R$
                              {produtod.desconto > 0
                                ? (produtod.preco - produtod.desconto).toFixed(
                                    2
                                  )
                                : produtod.preco.toFixed(2)}
                              <h1
                                style={{
                                  fontSize: "10px",
                                  marginLeft: "2.5px",
                                }}
                              >
                                {" "}
                                cada
                              </h1>
                            </div>

                            {produtod.estoque === 0 && (
                              <a
                                href={`/produto?i=${
                                  produtod.id
                                }&n=${produtod.nome.replaceAll(" ", "-")}`}
                              >
                                <button
                                  style={{
                                    backgroundColor: "gray" /* Green */,
                                    border: "none",
                                    color: "white",
                                    padding: "10px 30px",
                                    textAlign: "center",
                                    textDecoration: "none",
                                    display: "inline-block",
                                    fontSize: "16px",
                                    borderRadius: "10px 10px 10px 10px",
                                  }}
                                >
                                  <i className="icon_bag_alt" /> AVISE-ME
                                </button>
                              </a>
                            )}

                            {produtod.estoque > 0 && (
                              <button
                                className="btn-comprar"
                                onClick={() =>
                                  this.adicionaritem2(
                                    produtod.preco,
                                    produtod.desconto,
                                    produtod.promocao,
                                    produtod.nome,
                                    produtod.id,
                                    index2,
                                    carrinho
                                  )
                                }
                                style={{
                                  backgroundColor: "#4CAF50" /* Green */,
                                  border: "none",
                                  color: "white",
                                  padding: "10px 30px",
                                  textAlign: "center",
                                  textDecoration: "none",
                                  display: "inline-block",
                                  fontSize: "16px",
                                  borderRadius: "10px 10px 10px 10px",
                                }}
                              >
                                <i className="icon_bag_alt" /> COMPRAR
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  cprodutos: state.produtos,
  produtosd: state.produtosd,
  carrinho: state.carrinho,
});

export default connect(mapStateToProps, {
  getCProdutos,
  getProdutosd,
  salvaCarrinho,
})(withCookies(Content));
