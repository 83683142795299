import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { salvaCarrinho } from "../store/actions/carrinhoAction";
import { withCookies } from "react-cookie";
import ApiService from "../service/ApiService";
import { getLoja } from "../store/actions/lojaAction";

class FormCarrinho extends Component {
  constructor() {
    super();
    this.state = {
      submitted2: false,
      submitted: false,
      submitted3: false,
    };

    this.prosseguir = this.prosseguir.bind(this);
  }

  handleChange(index, carrinho, event) {
    console.log(index);
    console.log(event.target.value);
    console.log(carrinho);
    carrinho.itens[index].quantidade = event.target.value;

    var total = 0;
    var subtotal = 0;
    var descontot = 0;
    var montagem = 0;

    montagem = carrinho.itens.reduce(
      (prev, next) => prev + next.valormontagem,
      0
    );

    subtotal = carrinho.itens.reduce(
      (prev, next) => prev + next.preco * next.quantidade,
      0
    );
    total = carrinho.itens.reduce(
      (prev, next) =>
        prev + (next.preco * next.quantidade - next.desconto * next.quantidade),
      0
    );
    descontot = carrinho.itens.reduce(
      (prev, next) => prev + next.desconto * next.quantidade,
      0
    );
    carrinho.total = total + montagem;
    carrinho.subtotal = subtotal;
    carrinho.desconto = descontot;
    carrinho.valormontagem = montagem;
    carrinho.frete = 0;
    carrinho.servico = "";
    carrinho.prazo = 0;

    // console.log (carrinho);
    this.props.salvaCarrinho(carrinho);
    localStorage.setItem("itenscarrinho", 1);
  }

  async componentDidMount() {
    try {
      this.props.getLoja();
      //    window.scrollTo (0, 0);
      ApiService.lojatitulo()
        .then((response) => {
          if (response.status === 200) {
            document.title = response.data + " | Carrinho";
          }
        })
        .catch((e) => {
          ///  alert ('Erro ao recuperar idtawkto email');
          console.log(e);
        });
      // this.props.getCarrinho();
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  excluir(codigo, carrinho) {
    try {
      carrinho.itens = carrinho.itens.filter((it) => it.codigo !== codigo);

      var total = 0;
      var subtotal = 0;
      var descontot = 0;
      var montagem = 0;

      montagem = carrinho.itens.reduce(
        (prev, next) => prev + next.valormontagem,
        0
      );

      subtotal = carrinho.itens.reduce(
        (prev, next) => prev + next.preco * next.quantidade,
        0
      );
      total = carrinho.itens.reduce(
        (prev, next) =>
          prev +
          (next.preco * next.quantidade - next.desconto * next.quantidade),
        0
      );
      descontot = carrinho.itens.reduce(
        (prev, next) => prev + next.desconto * next.quantidade,
        0
      );
      carrinho.total = total + montagem;
      carrinho.subtotal = subtotal;
      carrinho.desconto = descontot;
      carrinho.valormontagem = montagem;
      carrinho.frete = 0;
      carrinho.servico = "";
      carrinho.prazo = 0;
      // console.log (carrinho);
      this.props.salvaCarrinho(carrinho);
      this.removerCarrinho();
      if (carrinho.itens.length === 0) {
        localStorage.setItem("itenscarrinho", 0);
        this.setState({ submitted3: true });
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerCarrinho() {
    // Get the snackbar DIV
    var x = document.getElementById("removercarrinho");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  prosseguir(carrinho) {
    var ok = true;
    const user = this.props.cookies.get("user") || "";
    console.log(user);
    if (user === "") {
      ok = false;
      this.setState({ submitted2: true });
    }
    var erroi = false;
    console.log(carrinho.itens);
    for (var i = 0; i < carrinho.itens.length; i++) {
      var t = carrinho.itens[i];
      if (t.desconto > t.preco) {
        ok = false;
        erroi = true;
      }
    }

    if (erroi) {
      ok = false;
      alert("total do item invalido");
    }

    if (ok) {
      this.setState({ submitted: true });
    }
  }

  render() {
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=carrinho" />;
    }

    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/checkout" />;
    }

    const submitted3 = this.state.submitted3;
    if (submitted3 === true) {
      return <Redirect to="/" />;
    }
    const { loja } = this.props.loja;
    const { carrinho } = this.props.carrinho;
    return (
      <section className="shopping-cart spad">
        <div className="container">
          <div className="row">
            {carrinho.itens.length === 0 && (
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body cart">
                    <div className="col-sm-12 empty-cart-cls text-center">
                      {" "}
                      <img
                        src="img/emptycart2.png"
                        width="400"
                        height="300"
                        alt="carrinho vazio"
                        className="img-fluid mb-4 mr-3"
                      />
                      <h3>
                        <strong>Seu Carrinho Está Vazio!</strong>
                      </h3>
                      <h4>
                        Vá para página inicial ou procure por produtos que te
                        deixarão feliz! :)
                      </h4>{" "}
                      <a
                        href="/"
                        className="btn btn-danger cart-btn-transform m-3"
                        data-abc="true"
                      >
                        Continuar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {carrinho.itens.length > 0 && (
              <div className="col-lg-12">
                <div className="cart-table">
                  <table className="table table-bordered table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th>Imagem</th>
                        <th>Produto</th>
                        <th>Preço</th>

                        <th>Quantidade</th>
                        <th>Subtotal</th>
                        <th>Desconto</th>
                        <th>Total</th>
                        <th>
                          <i className="ti-close" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {carrinho.itens &&
                        carrinho.itens.map((item, index) => (
                          <tr key={index}>
                            <td className="cart-pic first-row">
                              {item.foto && (
                                <img
                                  src={
                                    "data:image/jpeg;base64," + item.foto.data
                                  }
                                  alt={item.foto.nome}
                                  className="img-thumbnail"
                                />
                              )}
                            </td>
                            <td className="cart-title first-row">
                              <h5>
                                {" "}
                                {item.nome}
                                {item.cor != undefined
                                  ? "(" + item.cor + ")"
                                  : ""}
                              </h5>
                            </td>
                            <td className="p-price first-row">
                              R$ {Number(item.preco).toFixed(2)}
                            </td>

                            <td className="qua-col first-row">
                              <div className="quantity">
                                <input
                                  style={{ width: "60px", color: "black" }}
                                  step="1"
                                  min="1"
                                  type="number"
                                  value={item.quantidade}
                                  name="quantidade"
                                  onChange={this.handleChange.bind(
                                    this,
                                    index,
                                    carrinho
                                  )}
                                />
                              </div>
                            </td>
                            <td className="total-price first-row">
                              R${" "}
                              {Number(item.preco * item.quantidade).toFixed(2)}
                            </td>
                            <td className="p-price first-row">
                              R${" "}
                              {Number(item.quantidade * item.desconto).toFixed(
                                2
                              )}
                            </td>
                            <td className="total-price first-row">
                              R${" "}
                              {Number(
                                item.preco * item.quantidade -
                                  item.desconto * item.quantidade
                              ).toFixed(2)}
                            </td>
                            <td className="close-td first-row">
                              <a
                                href="#"
                                onClick={() =>
                                  this.excluir(item.codigo, carrinho)
                                }
                                style={{ color: "red" }}
                              >
                                <i
                                  className="ti-close"
                                  style={{ color: "red" }}
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    {/*  <div className="cart-buttons">
                         <NavLink to="/loja" className="primary-btn continue-shop">
                      Comprar Mais
                    </NavLink> 
                      <NavLink to="/carrinho" className="primary-btn up-cart">
                        Atualizar{' '}
                      </NavLink>
                    </div> */}
                  </div>
                  <div className="col-lg-4 offset-lg-4">
                    <div className="proceed-checkout">
                      <ul>
                        <li className="subtotal">
                          Subtotal{" "}
                          <span>
                            R${" "}
                            {Number(
                              carrinho.subtotal !== undefined
                                ? carrinho.subtotal
                                : 0
                            ).toFixed(2)}
                          </span>
                        </li>
                        <li className="subtotal">
                          Frete{" "}
                          <span >
                            R${" "}
                            {Number(
                              carrinho.frete !== undefined
                                ? carrinho.frete
                                : 0
                            ).toFixed(2)}
                          </span>
                        </li>
                        <li className="subtotal">
                          Desconto{" "}
                          <span style={{ color: "red" }}>
                            R${" "}
                            {Number(
                              carrinho.desconto !== undefined
                                ? carrinho.desconto
                                : 0
                            ).toFixed(2)}
                          </span>
                        </li>
                        {loja.segmento == 11 && (
                          <li className="subtotal">
                            Montagem{" "}
                            <span>
                              R${" "}
                              {Number(
                                carrinho.valormontagem !== undefined
                                  ? carrinho.valormontagem
                                  : 0
                              ).toFixed(2)}
                            </span>
                          </li>
                        )}
                        <li className="cart-total">
                          Total{" "}
                          <span>
                            R${" "}
                            {Number(
                              carrinho.total !== undefined ? carrinho.total : 0
                            ).toFixed(2)}
                          </span>
                        </li>
                        <br />
                        <li>
                          {carrinho.itens.length > 0 && (
                            <button
                              onClick={() => this.prosseguir(carrinho)}
                              className="site-btn place-btn"
                              style={{
                                border: "none",
                                color: "white",
                                padding: "10px 27px",
                                textAlign: "center",
                                textDecoration: "none",
                                display: "inline-block",
                                fontSize: "16px",
                                borderRadius: "5px 5px 5px 5px",
                              }}
                            >
                              Continuar Para Checkout
                            </button>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  carrinho: state.carrinho,
  loja: state.loja,
});

const mapDispatchToProps = (dispatch) => ({
  salvaCarrinho: (carrinho) => dispatch(salvaCarrinho(carrinho)),
  //  getCarrinho: () => dispatch(getCarrinho()),
  getLoja: () => dispatch(getLoja()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(FormCarrinho));
