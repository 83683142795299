import React, {Component} from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import Bread from '../componentes/Bread';

class NotFound extends Component {
  render () {
    return (
      <div>
        <div id="preloder">
          <div className="loader" />
        </div>
        <Header />
        <Bread nome={'Recurso não encontrado'} />
        <section className="checkout-section spad">
          <div className="container">

            <div className="row">

              <div className="col-lg-12">
                <div className="contact-title">
                  <h4>Não encontramos essa página :/</h4>
                  <p>Confira se você digitou direitinho o endereço que queria. Se estiver tudo certo, pode ser que o conteúdo tenha sido removido.</p>
                </div>
                <br/>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <a href="/" className="site-btn2 register-btn" style={{borderRadius:"5px"}}>
                  Pagina Inicial
                </a>
                </div>
              </div>

            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default NotFound;
