import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {instanceOf} from 'prop-types';
import ApiService from '../service/ApiService';
import {withCookies, Cookies} from 'react-cookie';
import LoadingOverlay from 'react-loading-overlay';

class FormLogin extends Component {
  constructor () {
    super ();
    this.state = {
      usuario: '',
      senha: '',
      message: '',
      submitted: false,
      redirect: '',
      isActive: false,
      textoloading: '',
    };
    this.onChangeUsuario = this.onChangeUsuario.bind (this);
    this.onChangeSenha = this.onChangeSenha.bind (this);
    this.entrar = this.entrar.bind (this);
  }

  static propTypes = {
    cookies: instanceOf (Cookies).isRequired,
  };

  onChangeUsuario (e) {
    this.setState ({
      usuario: e.target.value,
    });
  }

  onChangeSenha (e) {
    this.setState ({
      senha: e.target.value,
    });
  }

  componentDidMount () {
    //window.scrollTo (0, 0);
    const search = window.location.search;
    const params = new URLSearchParams (search);
    var valorx = params.get ('redirect');
    if (valorx === null) {
      valorx = '';
    }
    if (valorx === undefined) {
      valorx = '';
    }
    this.setState ({redirect: valorx});
    ApiService.lojatitulo ()
    .then (response => {
      if (response.status === 200) {
        document.title = response.data + ' | Entrar';
      }
    })
    .catch (e => {
      ///  alert ('Erro ao recuperar idtawkto email');
      console.log (e);
    });
  }

  entrar () {
    this.setState ({isActive: true});
    this.setState ({textoloading: 'Processando...'});
    var ok = true;

    if (ok) {
      var md5 = require ('md5');

      ApiService.entrar (this.state.usuario, md5 (this.state.senha))
        .then (response => {
          if (response.status === 200) {
            const {cookies} = this.props;
            cookies.set ('user', response.data, {path: '/'});
            localStorage.setItem ('cliente', JSON.stringify (response.data));
            this.setState ({isActive: false});
            this.setState ({textoloading: ''});
            this.setState ({
              submitted: true,
            });
            console.log (response.data);
          } else {
            this.setState ({message: 'Usuário ou Senha Incorretos!'});
          }
        })
        .catch (e => {
          this.setState ({isActive: false});
          this.setState ({textoloading: ''});
          this.setState ({message: 'Usuário ou Senha Incorretos!'});
          console.log (e);
        });
    }
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to={'/' + this.state.redirect} />;
    }

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <div className="register-login-section spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                {this.state.message &&
                  <div className="alert alert-danger alert-dismissible">
                    {this.state.message}
                  </div>}
                <div className="login-form">
                  <h2>Login</h2>

                  <div className="group-input">
                    <label htmlFor="usuario" style={{fontWeight: 'bold'}}>
                      Email: *
                    </label>
                    <input
                      autoFocus
                      type="email"
                      maxLength="100"
                      className="form-control"
                      id="usuario"
                      placeholder="Insira o email"
                      value={this.state.usuario}
                      onChange={this.onChangeUsuario}
                      name="usuario"
                    />
                  </div>
                  <br />
                  <div className="group-input">
                    <label htmlFor="senha" style={{fontWeight: 'bold'}}>
                      Senha: *
                    </label>
                    <input
                      type="password"
                      maxLength="100"
                      className="form-control"
                      id="senha"
                      placeholder="Insira a senha"
                      value={this.state.senha}
                      onChange={this.onChangeSenha}
                      name="senha"
                    />
                  </div>
                  <br />
                  <div className="group-input gi-check">
                    <div className="gi-more">
                      <label htmlFor="save-pass" style={{fontWeight: 'bold'}}>
                        Lembrar-me
                        <input type="checkbox" id="save-pass" />
                        <span className="checkmark" />
                      </label>
                      <a
                        href="/"
                        className="forget-pass"
                        style={{fontWeight: 'bold'}}
                      >
                        Esqueceu sua senha?
                      </a>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      onClick={this.entrar}
                      className="site-btn2 login-btn"
                      style={{borderRadius: '5px'}}
                    >
                      Entrar
                    </button>
                  </div>
                  <div className="switch-login">
                    <NavLink to="./cadastrar" className="or-login">
                      Ou Registrar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withCookies (FormLogin);
