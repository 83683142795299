import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { salvaCarrinho } from "../store/actions/carrinhoAction";
import { withCookies } from "react-cookie";
import ApiService from "../service/ApiService";

class DadosLista extends Component {
  constructor() {
    super();
    this.state = {
      submitted2: false,
      submitted: false,
      submitted3: false,
      submitted4: false,
      itens: [],
      lista: {},
      message: "",
      imgsd: [],
    };

    this.criarcarrinho = this.criarcarrinho.bind(this);
  }

  async componentDidMount() {
    try {
      //    window.scrollTo (0, 0);
      ApiService.lojatitulo()
        .then((response) => {
          if (response.status === 200) {
            document.title = response.data + " | Minha Lista";
          }
        })
        .catch((e) => {
          ///  alert ('Erro ao recuperar idtawkto email');
          console.log(e);
        });
      var cli = this.props.cookies.get("user") || "";
      console.log("user ck" + cli);
      if (cli === "") {
        this.setState({ submitted2: true });
      }

      const search = window.location.search;
      const params = new URLSearchParams(search);
      var codigo = params.get("codigo");

      ApiService.lista(codigo)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              lista: response.data,
            });
            ApiService.itenslista(codigo)
              .then((response) => {
                if (response.status === 200) {
                  this.setState({
                    itens: response.data,
                  });
                  var total = this.state.itens.reduce(
                    (prev, next) =>
                      prev +
                      (next.produto.preco * next.quantidade -
                        next.produto.desconto * next.quantidade),
                    0
                  );
                  var subtotal = this.state.itens.reduce(
                    (prev, next) => prev + next.produto.preco * next.quantidade,
                    0
                  );
                  this.state.lista.subtotal = subtotal;
                  this.state.lista.total = total;
                }
              })
              .catch((e) => {
                this.setState({ message: "Erro ao recuperar itens da lista" });
                console.log(e);
              });
          }
        })
        .catch((e) => {
          this.setState({ message: "Erro ao recuperar lista" });
          console.log(e);
        });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  prosseguir(carrinho) {
    this.criarcarrinho(carrinho);
    var ok = true;
    const user = this.props.cookies.get("user") || "";
    console.log(user);
    if (user === "") {
      ok = false;
      this.setState({ submitted2: true });
    }
    var erroi = false;
    console.log(carrinho.itens);
    for (var i = 0; i < carrinho.itens.length; i++) {
      var t = carrinho.itens[i];
      if (t.desconto > t.preco) {
        ok = false;
        erroi = true;
      }
    }

    if (erroi) {
      ok = false;
      alert("total do item invalido");
    }

    if (ok) {
      this.setState({ submitted4: true });
    }
  }

  excluir(codigo) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async pegarimgd(produtosd, index2) {
    console.log("produtod... " + produtosd[index2].produto.nome);
    ApiService.fotoproduto(produtosd[index2].produto.id)
      .then((response) => {
        if (response.status === 200) {
          this.state.imgsd[index2] = response.data.data;
          // console.log ('pppp' + this.state.imgsd[index2]);
          this.setState({ imgsd: this.state.imgsd });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  excluiritem(codigo) {
    ApiService.excluiritemlista(codigo)
      .then((response) => {
        if (response.status === 200) {
          ApiService.itenslista(this.state.lista.id)
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  itens: response.data,
                });
                var total = this.state.itens.reduce(
                  (prev, next) =>
                    prev +
                    (next.produto.preco * next.quantidade -
                      next.produto.desconto * next.quantidade),
                  0
                );
                var subtotal = this.state.itens.reduce(
                  (prev, next) => prev + next.produto.preco * next.quantidade,
                  0
                );
                this.state.lista.subtotal = subtotal;
                this.state.lista.total = total;
              }
            })
            .catch((e) => {
              this.setState({ message: "Erro ao recuperar itens da lista" });
              console.log(e);
            });
        }
      })
      .catch((e) => {
        this.setState({ message: "Erro ao recuperar listas" });
        console.log(e);
      });
  }

  async criarcarrinho(carrinho) {
    carrinho = {};
    carrinho.itens = [];
    carrinho.total = 0;
    this.props.salvaCarrinho(carrinho);
    //  console.log(carrinho);
    for (var i = 0; i < this.state.itens.length; i++) {
      var pp = this.state.itens[i];
      this.adicionaritem2(
        pp.produto.preco,
        pp.produto.desconto,
        pp.produto.promocao,
        pp.produto.nome,
        pp.produto.id,
        i,
        carrinho
      );
    }
    if (carrinho.itens.length > 0) {
      this.props.salvaCarrinho(carrinho);
      this.setState({ submitted4: true });
    }
  }

  addCarrinho() {
    // Get the snackbar DIV
    var x = document.getElementById("addcarrinho");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  adicionaritem2(preco, desconto, promocao, nome, codigo, index, carrinho) {
    try {
      var item = {};
      item.quantidade = 1;
      item.preco = Number(preco);
      item.promocao = Number(promocao);
      item.desconto = Number(desconto);
      item.nome = nome;
      item.codigo = codigo;
      item.foto = {};
      item.foto.data = this.state.imgsd[index];
      item.valormontagem=0;

      if (carrinho.itens === undefined) {
        carrinho.itens = [];
        carrinho.total = 0;
      }
      var tem = false;
      var pos = 0;
      for (var i = 0; i < carrinho.itens.length; i++) {
        var it = carrinho.itens[i];
        if (it.codigo === codigo) {
          tem = true;
          break;
        }
        pos++;
      }
      if (tem === false) {
        carrinho.itens.push(item);
      } else {
        carrinho.itens[pos].quantidade += 1;
      }

      var total = 0;
      var subtotal = 0;
      var descontot = 0;
      var montagem=0;

      montagem = carrinho.itens.reduce(
        (prev, next) => prev + next.valormontagem,
        0
      );

      subtotal = carrinho.itens.reduce(
        (prev, next) => prev + next.preco * next.quantidade,
        0
      );
      total = carrinho.itens.reduce(
        (prev, next) =>
          prev +
          (next.preco * next.quantidade - next.desconto * next.quantidade),
        0
      );
      descontot = carrinho.itens.reduce(
        (prev, next) => prev + next.desconto * next.quantidade,
        0
      );
      carrinho.total = total+montagem;
      carrinho.subtotal = subtotal;
      carrinho.desconto = descontot;
      carrinho.valormontagem=montagem;
      //console.log (carrinho);
      this.props.salvaCarrinho(carrinho);
      //window.scrollTo (0, 0);
      // this.addCarrinho();
      //   console.log(carrinho);
      localStorage.setItem("itenscarrinho", 1);
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { carrinho } = this.props.carrinho;
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=listas" />;
    }

    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/checkout" />;
    }

    const submitted4 = this.state.submitted4;
    if (submitted4 === true) {
      return <Redirect to="/carrinho" />;
    }

    const submitted3 = this.state.submitted3;
    if (submitted3 === true) {
      return <Redirect to="/" />;
    }

    return (
      <section className="shopping-cart spad">
        <div className="container">
          <div className="row">
            {this.state.message && (
              <div className="col-12">
                <div className="alert alert-danger alert-dismissible">
                  {this.state.message}
                </div>
              </div>
            )}

            {this.state.itens.length === 0 && (
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body cart">
                    <div className="col-sm-12 empty-cart-cls text-center">
                      {" "}
                      <img
                        src="img/emptycart2.png"
                        width="400"
                        height="300"
                        alt="carrinho vazio"
                        className="img-fluid mb-4 mr-3"
                      />
                      <h3>
                        <strong>Sua Lista de Compras Está Vazia!</strong>
                      </h3>
                      <h4>
                        Procure e adicione produtos que te deixarão feliz! :)
                      </h4>{" "}
                      <a
                        href="/"
                        className="btn btn-danger cart-btn-transform m-3"
                        data-abc="true"
                      >
                        Continuar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.itens.length > 0 && (
              <div className="col-lg-12">
                <h4>Lista {this.state.lista.nome}</h4>
                <br />
                <div className="cart-table">
                  <table className="table table-bordered table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th>Imagem</th>
                        <th>Produto</th>
                        <th>Preço</th>
                        <th>Quantidade</th>
                        <th>Subtotal</th>
                        <th>Desconto</th>
                        <th>Total</th>
                        <th>
                          <i className="ti-close" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.itens &&
                        this.state.itens.map((item, index) => (
                          <tr key={index}>
                            <td className="cart-pic first-row">
                              <img
                                src={
                                  this.state.imgsd[index] != null
                                    ? "data:image/jpeg;base64," +
                                      this.state.imgsd[index]
                                    : "/img/lo.gif"
                                }
                                alt={
                                  item.produto.foto != null
                                    ? item.produto.foto.nome
                                    : "img"
                                }
                                onLoad={() =>
                                  this.pegarimgd(this.state.itens, index)
                                }
                                className="border"
                                height="200px"
                                widht="auto"
                              />
                            </td>
                            <td className="cart-title first-row">
                              <h5> {item.produto.nome}</h5>
                            </td>
                            <td className="p-price first-row">
                              R$ {Number(item.produto.preco).toFixed(2)}
                            </td>

                            <td className="qua-col first-row">1</td>
                            <td className="total-price first-row">
                              R${" "}
                              {Number(
                                item.produto.preco * item.quantidade
                              ).toFixed(2)}
                            </td>
                            <td className="p-price first-row">
                              R${" "}
                              {Number(
                                item.quantidade * item.produto.desconto
                              ).toFixed(2)}
                            </td>
                            <td className="total-price first-row">
                              R${" "}
                              {Number(
                                item.produto.preco * item.quantidade -
                                  item.produto.desconto * item.quantidade
                              ).toFixed(2)}
                            </td>
                            <td className="close-td first-row">
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  if (
                                    window.confirm("Excluir a item da lista?")
                                  ) {
                                    this.excluiritem(item.id);
                                  }
                                }}
                              >
                                X
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    {/*  <div className="cart-buttons">
                         <NavLink to="/loja" className="primary-btn continue-shop">
                      Comprar Mais
                    </NavLink> 
                      <NavLink to="/carrinho" className="primary-btn up-cart">
                        Atualizar{' '}
                      </NavLink>
                    </div> */}
                  </div>
                  <div className="col-lg-4 offset-lg-4">
                    <div className="proceed-checkout">
                      <ul>
                        <li className="subtotal">
                          Subtotal{" "}
                          <span>
                            R${" "}
                            {Number(
                              this.state.lista.subtotal !== undefined
                                ? this.state.lista.subtotal
                                : 0
                            ).toFixed(2)}
                          </span>
                        </li>
                        <li className="cart-total">
                          Total{" "}
                          <span>
                            R${" "}
                            {Number(
                              this.state.lista.total !== undefined
                                ? this.state.lista.total
                                : 0
                            ).toFixed(2)}
                          </span>
                        </li>
                        <br />
                        <li>
                          {this.state.itens.length > 0 && (
                            <button
                              onClick={() => this.criarcarrinho(carrinho)}
                              className="site-btn place-btn"
                              style={{
                                border: "none",
                                color: "white",
                                padding: "10px 27px",
                                textAlign: "center",
                                textDecoration: "none",
                                display: "inline-block",
                                fontSize: "16px",
                                borderRadius: "5px 5px 5px 5px",
                              }}
                            >
                              Comprar Lista
                            </button>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  carrinho: state.carrinho,
});

const mapDispatchToProps = (dispatch) => ({
  salvaCarrinho: (carrinho) => dispatch(salvaCarrinho(carrinho)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(DadosLista));
