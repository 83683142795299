import {GET_DESTAQUES, DESTAQUES_ERROR} from '../types';
import ApiService from '../../service/ApiService';

export const getDestaques = () => async dispatch => {
  try {
    const res = await ApiService.destaquesp ();
  //  console.log (res);
    dispatch ({
      type: GET_DESTAQUES,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: DESTAQUES_ERROR,
      payload: console.log (e),
    });
    console.error (e);
    //throw new Error (e);
  }
};
