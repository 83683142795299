import React, { Component } from "react";
import ApiService from "../service/ApiService";
import Moment from "react-moment";
import { withCookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import QRCode from "react-qr-code";
import printJS from "print-js";

class FormPedido extends Component {
  constructor() {
    super();
    this.state = {
      pedido: {},
      submitted2: false,
      submitted: false,
      eventos: [],
      isActive: false,
      textoloading: "",
      message: "",
      countdown: 0,
      segmento: 0,
    };
    this.processarpedido = this.processarpedido.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.downloadnfe = this.downloadnfe.bind(this);
    this.downloadnfepdf = this.downloadnfepdf.bind(this);
  }

  downloadnfe() {
    ApiService.downloadnfe(this.state.pedido.id, "xml")
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "nfe-" + this.state.pedido.numeropedido + ".xml"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        this.setState({ message: "Erro ao fazer download do xml" });
        console.log(e);
      });
  }

  downloadnfepdf() {
    ApiService.downloadnfe(this.state.pedido.id, "pdf")
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        /*  const link = document.createElement ('a');
        link.href = url;
        link.setAttribute (
          'download',
          'nfe-' + this.state.pedido.numeropedido + '.pdf'
        ); //or any other extension
        document.body.appendChild (link);
        link.click ();*/
        printJS(url);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao fazer download do pdf" });
        console.log(e);
      });
  }

  cancelar(pedido) {
    this.setState({ message2: "" });
    var ok = true;
    this.setState({ isActive: true });
    this.setState({ textoloading: "Processando seu pedido..." });
    if (ok) {
      pedido.canceladopor = "pelo cliente";
      ApiService.cancelarpedido(pedido)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ pedido: response.data });
            this.setState({ message2: "Pedido Cancelado Com Sucesso!" });
            window.scrollTo(0, 0);
            this.setState({ isActive: false });
            this.setState({ textoloading: "" });
          }
        })
        .catch((e) => {
          this.setState({ isActive: false });
          this.setState({ textoloading: "" });
          this.setState({ message2: "Erro ao cancelar pedido" });
          window.scrollTo(0, 0);
          console.log(e);
        });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.countdown);
  }

  componentDidMount() {
    ApiService.lojatitulo()
      .then((response) => {
        if (response.status === 200) {
          document.title = response.data + " | Pedido";
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
    ApiService.segmento()
      .then((response) => {
        if (response.status === 200) {
          this.setState({ segmento: response.data });
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
    //  window.scrollTo (0, 0);
    var user = this.props.cookies.get("user") || "";

    if (user === "") {
      this.setState({ submitted2: true });
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    var numero = params.get("numero");

    ApiService.pedido(numero, user.usuario)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            pedido: response.data,
          });
          if (response.data.status == "Pix") {
            var countdown = setInterval(
              () => this.processarpedido(numero, user.usuario),
              3000
            );
            this.setState({ countdown: countdown });
          }
          console.log(response.data);
        } else {
          this.setState({ message: "Erro ao recuperar pedido" });
        }
      })
      .catch((e) => {
        this.setState({ message: "Erro ao recuperar pedido" });
        console.log(e);
      });

    /* ApiService.eventosp (numero)
      .then (response => {
        this.setState ({
          eventos: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar eventos'});
        console.log (e);
      });*/
  }

  processarpedido(numero, usuario) {
    console.log("processando pedido " + numero + " " + usuario);
    ApiService.pedidopago(numero, usuario)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            pedidon: response.data.numeropedido,
          });
          if (response.data.status == "Pago") {
            this.setState({
              submitted: true,
            });
          }
          console.log(response.data);
        } else {
          //this.setState ({message: 'Erro ao recuperar pedido'});
        }
      })
      .catch((e) => {
        // this.setState ({message: 'Erro ao recuperar pedido'});
        console.log(e);
      });
  }

  render() {
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=pedido" />;
    }
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to={"/confirmacao?numero=" + this.state.pedidon} />;
    }
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="content">
          <div className="container">
            <div className="row">
              {this.state.message2 && (
                <div className="col-12">
                  <div className="alert alert-danger alert-dismissible">
                    {this.state.message2}
                  </div>
                </div>
              )}

              {this.state.pedido.id === undefined && (
                <div className="col-12">
                  <div className="alert alert-danger alert-dismissible">
                    {this.state.message}
                  </div>
                </div>
              )}

              {this.state.pedido.id !== undefined && (
                <div className="col-12">
                  <div className="invoice p-3 mb-3">
                    <div className="row">
                      <div className="col-12">
                        {this.state.pedido.status === "Pix" && (
                          <div
                            className="col-12"
                            style={{ textAlign: "center" }}
                          >
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Para a conclusão da compra, escaneie o QR Code
                              abaixo com seu aplicativo Pix.
                            </span>
                            <br />
                            <br />

                            <div style={{ textAlign: "center" }}>
                              <QRCode value={this.state.pedido.qrcode} />
                            </div>
                            <hr />
                          </div>
                        )}

                        {this.state.pedido.status === "Processando" && (
                          <div className="alert alert-danger alert-dismissible">
                            Seu pagamento não foi aprovado,voce pode alterar a
                            forma de pagamento{" "}
                            <a
                              href={
                                "/pagar?numero=" +
                                this.state.pedido.numeropedido
                              }
                            >
                              clicando aqui.
                            </a>
                          </div>
                        )}

                        <h4>
                          <div className="btn-group" />
                          <small className="float-right">
                            <b>
                              Data e Hora:{" "}
                              <Moment format="DD/MM/YYYY HH:mm:ss">
                                {this.state.pedido.datapedido}
                              </Moment>{" "}
                            </b>
                          </small>
                        </h4>
                      </div>
                    </div>

                    <div className="row invoice-info">
                      <div className="col-sm-4 invoice-col">
                        <address>
                          <br />
                          <strong>
                            <h5>
                              <b>Dados de Entrega:</b>
                            </h5>
                          </strong>
                          {this.state.pedido.logradouro},{" "}
                          {this.state.pedido.numero}
                          <br />
                          {this.state.pedido.bairro}
                          <br /> {this.state.pedido.cep}{" "}
                          {this.state.pedido.complemento}
                          <br />
                          {this.state.pedido.cidade}, {this.state.pedido.uf}
                          <br />
                          Servico Correios: {this.state.pedido.servico}
                          <br />
                          Prazo Correios:{this.state.pedido.prazo}
                          <br />
                        </address>
                        <div>
                          <h6 style={{ fontWeight: "bold" }}>
                            PONTOS FIDELIDADE:{" "}
                            {Number(this.state.pedido.pontos).toFixed(2)}{" "}
                          </h6>

                          <h6 style={{ fontWeight: "bold" }}>
                            CASHBACK:{" "}
                            {Number(this.state.pedido.cashback).toFixed(2)}{" "}
                          </h6>
                        </div>
                        &nbsp;
                      </div>

                      <div className="col-sm-4 invoice-col">
                        <br />
                        <strong>
                          <h5>
                            <b>Dados do Pedido:</b>
                          </h5>
                        </strong>
                        Nº Pedido: {this.state.pedido.numeropedido}
                        <br />
                        Status:{" "}
                        {this.state.pedido.status === "Processando"
                          ? "Pendente"
                          : this.state.pedido.status}
                        <br />
                        Data Pagamento:{" "}
                        {this.state.pedido.datapagamento && (
                          <Moment format="DD/MM/YYYY HH:mm:ss">
                            {this.state.pedido.datapagamento}
                          </Moment>
                        )}
                        <br />
                        Tipo Pagamento: {this.state.pedido.tipopagamento}{" "}
                        {this.state.pedido.parcela}
                        <br />
                        {this.state.pedido.tipopagamento == "cartaocredito" && (
                          <div>
                            Bandeira: {this.state.pedido.bandeira}
                            <br />
                            Cartao:{" "}
                            {this.state.pedido.finalcartao != undefined
                              ? this.state.pedido.finalcartao
                              : ""}
                            <br />
                          </div>
                        )}
                        {this.state.pedido.datanfe != undefined && (
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={this.downloadnfe}
                            >
                              XML NFE
                            </button>
                            &nbsp;
                            <button
                              className="btn btn-warning"
                              onClick={this.downloadnfepdf}
                            >
                              &nbsp;&nbsp;DANFE&nbsp;&nbsp;
                            </button>
                          </div>
                        )}
                        {this.state.pedido.dataenvio && <b>Data Envio:</b>}
                        {this.state.pedido.dataenvio && (
                          <Moment format="DD/MM/YYYY HH:mm:ss">
                            {this.state.pedido.dataenvio}
                          </Moment>
                        )}
                        <br />
                        {this.state.pedido.dataentrega && <b>Data Entrega:</b>}
                        {this.state.pedido.dataentrega && (
                          <Moment format="DD/MM/YYYY HH:mm:ss">
                            {this.state.pedido.dataentrega}
                          </Moment>
                        )}
                        <br />
                      </div>

                      <div className="col-sm-4 invoice-col">
                        <address>
                          <br />
                          <strong>
                            <h5>
                              <b>Dados do Cliente:</b>
                            </h5>
                          </strong>
                          Nome: {this.state.pedido.nome}
                          <br />
                          CPF: {this.state.pedido.cpfcnpj}
                          <br />
                          Telefone: {this.state.pedido.telefone}
                          <br />
                          Email: {this.state.pedido.email}
                        </address>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-bordered table-striped">
                          <thead className="thead-light">
                            <tr>
                              <th>Produto</th>
                              <th>Quantidade</th>
                              <th>Valor.Unit</th>
                              <th>SubTotal</th>
                              <th>Despesa</th>
                              <th>Frete</th>
                              <th>Desconto</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.pedido.itens &&
                              this.state.pedido.itens.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    {item.nome}
                                    {item.cor != undefined
                                      ? "(" + item.cor + ")"
                                      : ""}
                                  </td>
                                  <td>{item.quantidade}</td>
                                  <td>R$ {Number(item.valor).toFixed(2)}</td>
                                  <td>
                                    R${" "}
                                    {Number(
                                      item.quantidade * item.valor
                                    ).toFixed(2)}
                                  </td>
                                  <td>R$ {Number(item.despesa).toFixed(2)}</td>
                                  <td>R$ {Number(item.frete).toFixed(2)}</td>
                                  <td>
                                    R${" "}
                                    {Number(
                                      item.desconto * item.quantidade
                                    ).toFixed(2)}
                                  </td>
                                  <td>R$ {Number(item.total).toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table">
                            <tr>
                              <th style={{ width: "50%" }}>Subtotal:</th>
                              <td>
                                R${" "}
                                {Number(this.state.pedido.subtotal).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "50%" }}>Despesas:</th>
                              <td>
                                R${" "}
                                {Number(this.state.pedido.despesa).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th>Frete:</th>
                              <td>
                                R$ {Number(this.state.pedido.frete).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ color: "red" }}>Desconto:</th>
                              <td style={{ color: "red" }}>
                                R${" "}
                                {Number(this.state.pedido.desconto).toFixed(2)}
                              </td>
                            </tr>
                            {this.state.segmento == "11" && (
                              <tr>
                                <th>Montagem:</th>
                                <td>
                                  R${" "}
                                  {Number(
                                    this.state.pedido.valormontagem
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>Total:</th>
                              <td>
                                R$ {Number(this.state.pedido.total).toFixed(2)}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                      <br />

                      <div className="col-md-12">
                        <ul className="timeline" id="timeline">
                          <li className="li complete">
                            <div className="timestamp">
                              <span
                                className="date"
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                  {this.state.pedido.datapedido}
                                </Moment>
                              </span>
                            </div>

                            <div className="status">
                              <p style={{ marginTop: "10px" }}>
                                {" "}
                                Pedido Confirmado{" "}
                              </p>
                            </div>
                          </li>
                          <li
                            className={
                              this.state.pedido.datapagamento !== null
                                ? "li complete"
                                : "li"
                            }
                          >
                            <div className="timestamp">
                              <span
                                className="date"
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                {this.state.pedido.datapagamento && (
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {this.state.pedido.datapagamento}
                                  </Moment>
                                )}
                                {this.state.pedido.datapagamento === null && (
                                  <span style={{ visibility: "hidden" }}>
                                    {" "}
                                    00/00/0000{" "}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="status">
                              <p style={{ marginTop: "10px" }}>
                                {" "}
                                Pedido Aprovado{" "}
                              </p>
                            </div>
                          </li>
                          <li
                            className={
                              this.state.pedido.datanfe !== null
                                ? "li complete"
                                : "li"
                            }
                          >
                            <div className="timestamp">
                              <span
                                className="date"
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                {this.state.pedido.datanfe && (
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {this.state.pedido.datanfe}
                                  </Moment>
                                )}
                                {this.state.pedido.datanfe === null && (
                                  <span style={{ visibility: "hidden" }}>
                                    {" "}
                                    00/00/0000{" "}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="status">
                              <p style={{ marginTop: "10px" }}>
                                {" "}
                                Nota Fiscal Gerada
                              </p>
                            </div>
                          </li>
                          <li
                            className={
                              this.state.pedido.dataenvio !== null
                                ? "li complete"
                                : "li"
                            }
                          >
                            <div className="timestamp">
                              <span
                                className="date"
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                {this.state.pedido.dataenvio && (
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {this.state.pedido.dataenvio}
                                  </Moment>
                                )}
                                {this.state.pedido.dataenvio === null && (
                                  <span style={{ visibility: "hidden" }}>
                                    {" "}
                                    00/00/0000{" "}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="status">
                              <p style={{ marginTop: "10px" }}>
                                {" "}
                                Pedido Enviado{" "}
                              </p>
                            </div>
                          </li>
                          <li
                            className={
                              this.state.pedido.dataentrega !== null
                                ? "li complete"
                                : "li"
                            }
                          >
                            <div className="timestamp">
                              <span
                                className="date"
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                {this.state.pedido.dataentrega && (
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {this.state.pedido.dataentrega}
                                  </Moment>
                                )}
                                {this.state.pedido.dataentrega === null && (
                                  <span style={{ visibility: "hidden" }}>
                                    {" "}
                                    00/00/0000{" "}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="status">
                              <p style={{ marginTop: "10px" }}>
                                {" "}
                                Pedido Entregue{" "}
                              </p>
                            </div>
                          </li>

                          {this.state.pedido.datacancelamento && (
                            <li
                              className={
                                this.state.pedido.datacancelamento !== null
                                  ? "li complete"
                                  : "li"
                              }
                            >
                              <div className="timestamp">
                                <span
                                  className="date"
                                  style={{
                                    fontSize: "12.5px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {this.state.pedido.datacancelamento && (
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {this.state.pedido.datacancelamento}
                                    </Moment>
                                  )}
                                  {this.state.pedido.datacancelamento ===
                                    null && (
                                    <span style={{ visibility: "hidden" }}>
                                      {" "}
                                      00/00/0000{" "}
                                    </span>
                                  )}
                                </span>
                              </div>
                              <div className="statusc">
                                <p style={{ marginTop: "10px" }}>
                                  {" "}
                                  Pedido Cancelado{" "}
                                </p>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>

                    {(this.state.pedido.status === "Processando" ||
                      this.state.pedido.status === "Pago") && (
                      <button
                        className="btn btn-danger"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        Cancelar Pedido
                      </button>
                    )}
                  </div>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Cancelamento de Pedido
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          Deseja Cancelar Esse Pedido?
                        </div>
                        <div className="modal-footer">
                          <button
                            onClick={() => this.cancelar(this.state.pedido)}
                            className="btn btn-primary"
                            data-dismiss="modal"
                          >
                            Sim
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Nao
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

export default withCookies(FormPedido);
