import React, {Component} from 'react';
import { Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import InputMask from 'react-input-mask';
import {withCookies} from 'react-cookie';
import cep from 'cep-promise';
import ApiService from '../service/ApiService';
import LoadingOverlay from 'react-loading-overlay';



class FormEditaCadastro extends Component {
  constructor () {
    super ();
    this.state = {
      isActive: false,
      textoloading: '',
      id: null,
      status: '',
      datanascimento: null,
      observacao: '',
      senha: '',
      nome: '',
      cpfcnpj: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      email: '',
      telefone: '',
      message: '',
      erros: [],
      submitted: false,
      submitted2: false,
      ufs: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MG',
        'MS',
        'MT',
        'PA',
        'PB',
        'PE',
        'PI',
        'PR',
        'RJ',
        'RN',
        'RO',
        'RR',
        'RS',
        'SC',
        'SE',
        'SP',
        'TO',
      ],
    };
    this.prosseguir = this.prosseguir.bind (this);
    this.salvar = this.salvar.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeCpf = this.onChangeCpf.bind (this);
    this.onChangeCep = this.onChangeCep.bind (this);
    this.onChangeEndereco = this.onChangeEndereco.bind (this);
    this.onChangeNumero = this.onChangeNumero.bind (this);
    this.onChangeComplemento = this.onChangeComplemento.bind (this);
    this.onChangeBairro = this.onChangeBairro.bind (this);
    this.onChangeCidade = this.onChangeCidade.bind (this);
    this.onChangeUf = this.onChangeUf.bind (this);
    this.onChangeEmail = this.onChangeEmail.bind (this);
    this.onChangeTelefone = this.onChangeTelefone.bind (this);
  }

  prosseguir () {
    var ok = true;


    var erros = [];

    if (this.state.cep.replace ('-', '').replace ('_', '').length !== 8) {
      ok = false;
      erros.push (' CEP Inválido!');
    }

    if (this.state.logradouro.length === 0) {
      ok = false;
      erros.push (' Endereço Inválido!');
    }

    if (this.state.numero.length === 0) {
      ok = false;
      erros.push (' Nº Inválido!');
    }

    if (this.state.bairro.length === 0) {
      ok = false;
      erros.push (' Bairro Inválido!');
    }

    if (this.state.cidade.length === 0) {
      ok = false;
      erros.push (' Cidade Inválida!');
    }

    if (this.state.uf.length === 0) {
      ok = false;
      erros.push (' UF Inválido!');
    }

    if (
      this.state.telefone
        .replace ('_', '')
        .replace ('-', '')
        .replace ('(', '')
        .replace (')', '').length === 0
    ) {
      ok = false;
      erros.push (' Telefone Inválido!');
    }

    this.setState ({erros: erros});

    if (ok) {
      this.setState ({submitted: true});
    }
  }

  onChangeComplemento (e) {
    this.setState ({
      complemento: e.target.value,
    });
  }

  onChangeNome (e) {
    this.setState ({
      nome: e.target.value,
    });
  }

  onChangeCpf (e) {
    this.setState ({
      cpf: e.target.value,
    });
  }

  onChangeCep (e) {
    this.setState ({
      cep: e.target.value,
    });
    //  console.log(e.target.value.replace ('-','').replace ('_','').length);
    if (e.target.value.replace ('-', '').replace ('_', '').length === 8) {
      // console.log('asfdsdf');
      cep (e.target.value.replace ('-', '').replace ('_', ''))
        .then (response => {
          console.log (response);
          this.setState ({uf: response.state});
          this.setState ({cidade: response.city});
          this.setState ({bairro: response.neighborhood});
          this.setState ({logradouro: response.street});
        })
        .catch (console.log);
    }
  }

  onChangeEndereco (e) {
    this.setState ({
      logradouro: e.target.value,
    });
  }

  onChangeNumero (e) {
    this.setState ({
      numero: e.target.value,
    });
  }

  onChangeBairro (e) {
    this.setState ({
      bairro: e.target.value,
    });
  }

  onChangeCidade (e) {
    this.setState ({
      cidade: e.target.value,
    });
  }

  onChangeUf (e) {
    this.setState ({
      uf: e.target.value,
    });
  }

  onChangeEmail (e) {
    this.setState ({
      email: e.target.value,
    });
  }

  onChangeTelefone (e) {
    this.setState ({
      telefone: e.target.value,
    });
  }

  componentDidMount () {
    //  window.scrollTo (0, 0);
    ApiService.lojatitulo ()
    .then (response => {
      if (response.status === 200) {
        document.title = response.data + ' | Editar Cadastro';
      }
    })
    .catch (e => {
      ///  alert ('Erro ao recuperar idtawkto email');
      console.log (e);
    });
    var cli = this.props.cookies.get ('user') || '';
    console.log ('user ck' + cli);
    if (cli === '') {
      this.setState ({submitted2: true});
    }

    if (cli === undefined) {
      cli = '';
    }
    if (cli !== '') {
      var cli2 = cli; //JSON.parse (cli);
      this.setState ({nome: cli2.nome});
      this.setState ({email: cli2.email});
      this.setState ({cpfcnpj: cli2.cpfcnpj});
      this.setState ({cep: cli2.cep});
      this.setState ({logradouro: cli2.logradouro});
      this.setState ({numero: cli2.numero});
      this.setState ({complemento: cli2.complemento});
      this.setState ({uf: cli2.uf});
      this.setState ({cidade: cli2.cidade});
      this.setState ({bairro: cli2.bairro});
      this.setState ({endereco: cli2.endereco});
      this.setState ({telefone: cli2.telefone});
      this.setState ({status: cli2.status});
      this.setState ({observacao: cli2.observacao});
      this.setState ({datanascimento: cli2.datanascimento});
      this.setState ({senha: cli2.senha});
      this.setState ({id: cli2.id});
    }
  }

  salvar () {
    this.setState ({isActive: true});
    this.setState ({textoloading: 'Processando...'});

    var data = {
      id: this.state.id,
      usuario: this.state.email,
      email: this.state.email,
      status: this.state.status,
      nome: this.state.nome,
      cpfcnpj: this.state.cpfcnpj,
      senha: this.state.senha,
      logradouro: this.state.logradouro,
      numero: this.state.numero,
      bairro: this.state.bairro,
      cidade: this.state.cidade,
      uf: this.state.uf,
      cep: this.state.cep.replace ('-', '').replace ('_', ''),
      telefone: this.state.telefone
        .replace ('_', '')
        .replace ('-', '')
        .replace ('(', '')
        .replace (')', ''),
      complemento: this.state.complemento,
      datanascimento: this.state.datanascimento,
      observacao: this.state.observacao,
    };

    ApiService.atualizarusuario (data)
      .then (response => {
        if (response.status === 200) {
          const {cookies} = this.props;
          cookies.set ('user', response.data, {path: '/'});
          this.setState ({isActive: false});
          this.setState ({textoloading: ''});
          this.setState ({
            submitted: true,
          });
          // console.log (response.data);
        }
      })
      .catch (e => {
        this.setState ({isActive: false});
        this.setState ({textoloading: ''});
        this.setState ({message: 'Erro ao atualizar usuario'});
        console.log (e);
      });
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/conta" />;
    }

    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=checkout" />;
    }

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="checkout-section spad">
          <div className="container">
            <div className="checkout-form" style={{backgroundColor: '#fff'}}>
              {this.state.message.length > 0 &&
                <div className="alert alert-danger alert-dismissible">
                  {this.state.message}
                </div>}
              {this.state.erros !== undefined &&
                this.state.erros.map ((erro, index) => (
                  <div key={index} className="alert alert-danger alert-dismissible">
                    {erro}
                  </div>
                ))}
              <div className="row">

                <div className="col-lg-12">

                  <h4>Editar Dados</h4>
                  <div className="row">

                    <div className="col-lg-12">
                      <label
                        style={{fontWeight: 'bold', width: '65px'}}
                        htmlFor="fir"
                      >
                        NOME:&nbsp;<span />
                      </label>
                      <label style={{textTransform: 'uppercase'}} id="fir">
                        {this.state.nome}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{fontWeight: 'bold', width: '65px'}}
                        htmlFor="cun-name"
                      >
                        EMAIL:&nbsp;
                      </label>
                      <label >
                        {this.state.email}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{fontWeight: 'bold', width: '65px'}}
                        htmlFor="cun-name"
                      >
                        CPF:&nbsp;
                      </label>
                      <label style={{textTransform: 'uppercase'}}>
                        {this.state.cpfcnpj}
                      </label>
                    </div>

                    <div className="col-lg-2">
                      <label style={{fontWeight: 'bold'}} htmlFor="zip">
                        CEP<span>*</span>:
                      </label>
                      <InputMask
                        placeholder="Insira o CEP"
                        mask="99999-999"
                        value={this.state.cep}
                        onChange={this.onChangeCep}
                        style={{
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        className="form-control"
                      />
                    </div>

                    <div className="col-lg-5">
                      <label style={{fontWeight: 'bold'}} htmlFor="street">
                        ENDEREÇO<span>*</span>:
                      </label>
                      <input
                        placeholder="Insira o Endereço"
                        type="text"
                        id="street"
                        style={{
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        className="street-first form-control"
                        maxLength={100}
                        value={this.state.logradouro}
                        onChange={this.onChangeEndereco}
                      />

                    </div>

                    <div className="col-lg-3">
                      <label style={{fontWeight: 'bold'}} htmlFor="town">
                        BAIRRO<span>*</span>:
                      </label>
                      <input
                        placeholder="Insira o Bairro"
                        type="text"
                        id="bairro"
                        maxLength={50}
                        style={{
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        value={this.state.bairro}
                        onChange={this.onChangeBairro}
                        className="form-control"
                      />
                    </div>

                    <div className="col-lg-2">
                      <label style={{fontWeight: 'bold'}} htmlFor="town">
                        NÚMERO<span>*</span>:
                      </label>
                      <input
                        placeholder="Número"
                        type="text"
                        id="numero"
                        maxLength={20}
                        style={{
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        value={this.state.numero}
                        onChange={this.onChangeNumero}
                        className="form-control"
                      />
                    </div>
                    
                    <div className="col-lg-3">
                      <label style={{fontWeight: 'bold'}} htmlFor="town">
                        CIDADE<span>*</span>:
                      </label>
                      <input
                        placeholder="Insira a Cidade"
                        type="text"
                        id="town"
                        maxLength={100}
                        style={{
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        value={this.state.cidade}
                        onChange={this.onChangeCidade}
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label style={{fontWeight: 'bold'}} htmlFor="town">
                        UF<span>*</span>:
                      </label>
                  
                      <select
                        required
                        className="form-control select2"
                        style={{
                          height:"45px",
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        value={this.state.uf}
                        onChange={this.onChangeUf}
                        name="uf"
                  
                      >
                        <option defaultValue value="">selecione</option>
                        {this.state.ufs &&
                          this.state.ufs.map (uf => (
                            <option value={uf}>
                              {uf}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-lg-4">
                      <label style={{fontWeight: 'bold'}} htmlFor="comp">
                        COMPLEMENTO<span />:
                      </label>
                      <input
                        placeholder="Insira o Complemento"
                        type="text"
                        id="comp"
                        style={{
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        className="street-first form-control"
                        maxLength={50}
                        value={this.state.complemento}
                        onChange={this.onChangeComplemento}
                      />

                    </div>

                    <div className="col-lg-3">
                      <label style={{fontWeight: 'bold'}} htmlFor="phone">
                        TELEFONE<span>*</span>:
                      </label>
                      <InputMask
                        placeholder="Insira o Telefone"
                        mask="(99)99999-9999"
                        value={this.state.telefone}
                        onChange={this.onChangeTelefone}
                        style={{
                          textTransform: 'uppercase',
                          borderWidth: '1px',
                          borderRadius: '5px 5px 5px 5px',
                        }}
                        className="form-control"
                      />
                    </div>

                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      className="site-btn register-btn"
                      onClick={this.salvar}
                      style={{
                        border: 'none',
                        color: 'white',
                        textAlign: 'center',
                        textDecoration: 'none',
                        display: 'inline-block',
                        fontSize: '16px',
                        borderRadius: '5px 5px 5px 5px',
                      }}
                    >
                      Atualizar Dados
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect (mapStateToProps, mapDispatchToProps) (
  withCookies (FormEditaCadastro)
);
