import React, {Component} from 'react';
import ApiService from '../service/ApiService';
import Moment from 'react-moment';
import {withCookies} from 'react-cookie';
import {Redirect} from 'react-router-dom';

class FormPedidook extends Component {
  constructor () {
    super ();
    this.state = {
      pedido: {},
      submitted2: false,
      eventos: [],
      segmento:0
    };
  }

  componentDidMount () {
    ApiService.lojatitulo ()
    .then (response => {
      if (response.status === 200) {
        document.title = response.data + ' | Confirmação Pedido';
      }
    })
    .catch (e => {
      ///  alert ('Erro ao recuperar idtawkto email');
      console.log (e);
    });
    ApiService.segmento ()
    .then (response => {
      if (response.status === 200) {
       this.setState({segmento:response.data})
      }
    })
    .catch (e => {
      ///  alert ('Erro ao recuperar idtawkto email');
      console.log (e);
    });
    //  window.scrollTo (0, 0);
    var user = this.props.cookies.get ('user') || '';

    if (user === '') {
      this.setState ({submitted2: true});
    }

    const search = window.location.search;
    const params = new URLSearchParams (search);
    var numero = params.get ('numero');

    ApiService.pedido (numero, user.usuario)
      .then (response => {
        if (response.status === 200) {
          this.setState ({
            pedido: response.data,
          });
          console.log (response.data);
        }
      })
      .catch (e => {
        this.setState ({message: 'Erro ao recuperar pedido'});
        console.log (e);
      });
  }

  render () {
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=pedido" />;
    }
    return (
      <section className="content">
        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                  marginTop: '20px',
                }}
              >
                <span style={{fontSize: '25px'}}>
                  Obrigado! Seu Pedido Foi Recebido!
                </span>
              </div>
              {this.state.pedido.status === 'Pago' &&
                <div className="alert alert-info alert-dismissible">
                  Seu pagamento foi aprovado!
                </div>}
              {this.state.pedido.status === 'Processando' &&
                <div className="alert alert-danger alert-dismissible">
                  Seu pagamento não foi aprovado,voce pode alterar a forma de pagamento
                  {' '}
                  <a href={'/pagar?numero=' + this.state.pedido.numeropedido}>
                    clicando aqui.
                  </a>
                </div>}
            </div>
            <div className="col-lg-6 col-lx-4">
              <div
                style={{
                  backgroundColor: '#f7f7f7',
                  padding: '35px 40px',
                  marginBottom: '15px',
                }}
              >
                <h4
                  style={{
                    fontSize: '18px',
                    fontWeight: '500',
                    color: 'Poppins',
                    textTransform: 'capitalize',
                    marginBottom: '16px',
                  }}
                >
                  Informação da Compra
                </h4>
                <ul
                  style={{
                    position: 'relative',
                    textTransform: 'capitalize',
                    paddingLeft: '40%',
                    marginBottom: '3px',
                    listStyleType: 'none',
                  }}
                >
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Pedido
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : {this.state.pedido.numeropedido}
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '10',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Data
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : <Moment format="DD/MM/YYYY HH:mm:ss">
                        {this.state.pedido.datapedido}
                      </Moment>
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '20',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      SubTotal
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : R$ {Number (this.state.pedido.subtotal).toFixed (2)}
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '20',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Despesa
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : R$ {Number (this.state.pedido.despesa).toFixed (2)}
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '30',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Frete
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : R$ {Number (this.state.pedido.frete).toFixed (2)}
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '40',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Desconto
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : R$ {Number (this.state.pedido.desconto).toFixed (2)}
                    </span>
                  </li>
                  {this.state.segmento=='11'&&
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '40',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Montagem
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : R$ {Number (this.state.pedido.valormontagem).toFixed (2)}
                    </span>
                  </li>}
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '50',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Total
                    </p>
                    <span style={{color: 'Poppins'}}>
                      : R$ {Number (this.state.pedido.total).toFixed (2)}
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '60',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Pagamento
                    </p>
                    <span>
                      :
                      {' '}
                      {this.state.pedido.tipopagamento}
                      {' '}
                      {this.state.pedido.parcela}
                    </span>
                    {this.state.pedido.tipopagamento == 'cartaocredito' &&
                      <div>
                        Bandeira: {this.state.pedido.bandeira}<br />
                        Cartao:
                        {' '}
                        {this.state.pedido.finalcartao != undefined
                          ? this.state.pedido.finalcartao
                          : ''}
                        <br />
                      </div>}
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '60',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Status
                    </p>
                    <span>
                      :
                      {' '}
                      {this.state.pedido.status === 'Processando'
                        ? 'Pendente'
                        : this.state.pedido.status}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-lx-4">
              <div
                style={{
                  backgroundColor: '#f7f7f7',
                  padding: '35px 40px',
                  marginBottom: '15px',
                }}
              >
                <h4
                  style={{
                    fontSize: '18px',
                    fontWeight: '500',
                    color: 'Poppins',
                    textTransform: 'capitalize',
                    marginBottom: '16px',
                  }}
                >
                  Endereço de Entrega
                </h4>
                <ul
                  style={{
                    position: 'relative',
                    textTransform: 'capitalize',
                    paddingLeft: '40%',
                    marginBottom: '3px',
                    listStyleType: 'none',
                  }}
                >
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Nome
                    </p>
                    <span>: {this.state.pedido.nome}</span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '10',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Rua
                    </p>
                    <span>
                      :
                      {' '}
                      {this.state.pedido.logradouro}
                      ,
                      {' '}
                      {this.state.pedido.numero}
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '20',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Bairro
                    </p>
                    <span>: {this.state.pedido.bairro}</span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '30',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      CEP
                    </p>
                    <span>: {this.state.pedido.cep}</span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '40',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Cidade e UF
                    </p>
                    <span>
                      : {this.state.pedido.cidade}, {this.state.pedido.uf}
                    </span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '50',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Complemento
                    </p>
                    <span>: {this.state.pedido.complemento}</span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '50',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Servico Correios:
                    </p>
                    <span>: {this.state.pedido.servico}</span>
                  </li>
                  <li>
                    <p
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '50',
                        lineHeight: '21px',
                        color: '#777777',
                      }}
                    >
                      Prazo Correios:
                    </p>
                    <span>: {this.state.pedido.prazo}</span>
                  </li>
                  <br />
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                style={{
                  marginTop: '20px',
                  backgroundColor: '#f7f7f7',
                  padding: '30px 30px 15px',
                  textTransform: 'capitalize',
                }}
              >
                <h3
                  style={{
                    fontSize: '18px',
                    fontWeight: '500',
                    color: 'Poppins',
                    textTransform: 'capitalize',
                    marginBottom: '16px',
                  }}
                >
                  Detalhes da Compra
                </h3>
                <table className="table table-borderless">
                  <thead
                    style={{
                      borderTop: '1px solid #dddddd',
                      borderBottom: '1px solid #dddddd',
                    }}
                  >
                    <tr>
                      <th scope="col">Produto</th>
                      <th scope="col">Quantidade</th>
                      <th scope="col">Valor.Unit</th>
                      <th scope="col">SubTotal</th>
                      <th scope="col">Despesa</th>
                      <th scope="col">Frete</th>
                      <th scope="col">Desconto</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>

                    {this.state.pedido.itens &&
                      this.state.pedido.itens.map ((item, index) => (
                        <tr key={index}>

                        <td>{item.nome}{item.cor!=undefined?'('+item.cor+')':''}</td>
                          <td>{item.quantidade}</td>
                          <td> R$ {Number (item.valor).toFixed (2)}</td>
                          <td>
                            R$
                            {' '}
                            {Number (item.quantidade * item.valor).toFixed (2)}
                          </td>
                          <td>
                            R$
                            {' '}
                            {Number (item.despesa).toFixed (2)}
                          </td>
                          <td>
                            R$
                            {' '}
                            {Number (item.frete).toFixed (2)}
                          </td>
                          <td>
                            R$
                            {' '}
                            {Number (item.desconto * item.quantidade).toFixed (
                              2
                            )}
                          </td>
                          <td>R$ {Number (item.total).toFixed (2)}</td>
                        </tr>
                      ))}

                  </tbody>
                  <tfoot style={{borderTop: '1px solid #dddddd'}}>
                    <tr>
                      <th scope="col" colspan="5">TOTAL</th>
                      <th scope="col">
                        R$ {Number (this.state.pedido.frete).toFixed (2)}
                      </th>
                      <th scope="col" />
                      <th scope="col">
                        R$ {Number (this.state.pedido.total).toFixed (2)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <br />

        </div>
      </section>
    );
  }
}

export default withCookies (FormPedidook);
