import {GET_CATEGORIAS, CATEGORIAS_ERROR} from '../types';
import ApiService from '../../service/ApiService';

export const getCategorias = () => async dispatch => {
  try {
    const res = await ApiService.categoriasp ();
    //console.log (res);
    dispatch ({
      type: GET_CATEGORIAS,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: CATEGORIAS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
    //throw new Error (e);
  }
};
