import React, { Component } from "react";
import { connect } from "react-redux";
import { getCarrinho } from "../store/actions/carrinhoAction";
import { getLoja } from "../store/actions/lojaAction";
import InputMask from "react-input-mask";
import { withCookies } from "react-cookie";
import cep from "cep-promise";
import LoadingOverlay from "react-loading-overlay";
import { Redirect } from "react-router-dom";
import ApiService from "../service/ApiService";
import { salvaCarrinho } from "../store/actions/carrinhoAction";

class FormCheckout extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      textoloading: "",
      nome: "",
      cpfcnpj: "",
      cep: "",
      cep2: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      email: "",
      telefone: "",
      message: "",
      message3: "",
      erros: [],
      submitted: false,
      submitted2: false,
      fretes: [],
      submitted5:false,
      frete: "",
      ufs: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
    };
    this.prosseguir = this.prosseguir.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeCpf = this.onChangeCpf.bind(this);
    //   this.onChangeCep = this.onChangeCep.bind(this);
    // this.onChangeCep2 = this.onChangeCep2.bind(this);
    this.onChangeEndereco = this.onChangeEndereco.bind(this);
    this.onChangeNumero = this.onChangeNumero.bind(this);
    this.onChangeComplemento = this.onChangeComplemento.bind(this);
    this.onChangeBairro = this.onChangeBairro.bind(this);
    this.onChangeCidade = this.onChangeCidade.bind(this);
    this.onChangeUf = this.onChangeUf.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeTelefone = this.onChangeTelefone.bind(this);
    this.calcularPrazoFrete = this.calcularPrazoFrete.bind(this);
  }

  prosseguir(loja, carrinho) {
    var ok = true;

    var erros = [];

    if (this.state.cep.replace("-", "").replace("_", "").length !== 8) {
      ok = false;
      erros.push(" CEP Inválido!");
    }

    if (this.state.logradouro.length === 0) {
      ok = false;
      erros.push(" Endereço Inválido!");
    }

    if (this.state.numero.length === 0) {
      ok = false;
      erros.push(" Nº Inválido!");
    }

    if (this.state.bairro.length === 0) {
      ok = false;
      erros.push(" Bairro Inválido!");
    }

    if (this.state.cidade.length === 0) {
      ok = false;
      erros.push(" Cidade Inválida!");
    }

    if (this.state.uf.length === 0) {
      ok = false;
      erros.push(" UF Inválido!");
    }

    if (
      this.state.telefone
        .replace("_", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "").length === 0
    ) {
      ok = false;
      erros.push(" Telefone Inválido!");
    }

    this.setState({ erros: erros });

    if (ok) {
      var cliente = {};
      cliente.nome = this.state.nome;
      cliente.cpfcnpj = this.state.cpfcnpj;
      cliente.cep = this.state.cep;
      cliente.logradouro = this.state.logradouro;
      cliente.numero = this.state.numero;
      cliente.complemento = this.state.complemento;
      cliente.bairro = this.state.bairro;
      cliente.cidade = this.state.cidade;
      cliente.uf = this.state.uf;
      cliente.telefone = this.state.telefone;

      localStorage.setItem("cliente", JSON.stringify(cliente));
      localStorage.setItem("totalcarrinho", carrinho.total);
      localStorage.setItem("maxparcelas", loja.maxparcelas);
      localStorage.setItem("maxparcelas2", loja.maxparcelas2);
      localStorage.setItem("taxa", loja.taxa);
      localStorage.setItem("valorminimo", loja.valorminimo);
      this.setState({ submitted: true });
    }
  }

  onChangeComplemento(e) {
    this.setState({
      complemento: e.target.value,
    });
  }

  onChangeNome(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onChangeCpf(e) {
    this.setState({
      cpf: e.target.value,
    });
  }

  calcularPrazoFrete(carrinho) {
    var cep = this.state.cep.replace("-", "").replace("_", "");
    if (cep.length === 8) {
      this.setState({ isActive: true });
      this.setState({ textoloading: "Calculando frete/prazo..." });
      this.setState({
        message3: "",
      });
      ApiService.calcularPrazoFrete(cep)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ fretes: response.data });
            this.setState({ isActive: false });
            this.setState({ textoloading: "" });

            carrinho.frete = 0;
            carrinho.prazo = 0;
            carrinho.servico = "";
            carrinho.total =
              Number(carrinho.subtotal) +
              Number(carrinho.valormontagem) +
              Number(carrinho.frete) -
              Number(carrinho.desconto);
            this.props.salvaCarrinho(carrinho);
          }
        })
        .catch((e) => {
          this.setState({
            message3: "erro ao calcular frete/prazo",
          });
          this.setState({ isActive: false });
          this.setState({ textoloading: "" });
          console.log(e);
        });
    }
  }

  onChangeFrete(carrinho, e) {
    console.log(e.target.value);
    this.setState({
      frete: e.target.value,
    });
    carrinho.frete = 0;
    carrinho.prazo = 0;
    carrinho.servico = "";
    if (e.target.value != "") {
      var f = e.target.value.split("-");
      console.log(f);
      carrinho.frete = parseFloat(f[2]).toFixed(2);
      carrinho.prazo = f[1];
      carrinho.servico = f[0];
    }
    carrinho.total =
      Number(carrinho.subtotal) +
      Number(carrinho.valormontagem) +
      Number(carrinho.frete) -
      Number(carrinho.desconto);
    this.props.salvaCarrinho(carrinho);
  }

  onChangeCep2(e) {
    this.setState({
      cep2: e.target.value,
    });
  }

  onChangeCep(carrinho, e) {
    this.setState({
      cep: e.target.value,
    });
    //  console.log(e.target.value.replace ('-','').replace ('_','').length);
    if (e.target.value.replace("-", "").replace("_", "").length === 8) {
      this.setState({ isActive: true });
      this.setState({ textoloading: "Pesquisando cep..." });
      // console.log('asfdsdf');
      cep(e.target.value.replace("-", "").replace("_", ""))
        .then((response) => {
          console.log(response);
          this.setState({ numero: "" });
          this.setState({ complemento: "" });
          this.setState({ uf: response.state });
          this.setState({ cidade: response.city });
          this.setState({ bairro: response.neighborhood });
          this.setState({ logradouro: response.street });
          this.setState({ isActive: false });
          this.setState({ textoloading: "" });
          this.setState({ fretes: "" });
          this.setState({ frete: "" });
          carrinho.frete = 0;
          carrinho.prazo = 0;
          carrinho.servico = "";
          carrinho.total =
            Number(carrinho.subtotal) +
            Number(carrinho.valormontagem) +
            Number(carrinho.frete) -
            Number(carrinho.desconto);
          this.props.salvaCarrinho(carrinho);
        })
        .catch((e) => {
          this.setState({ isActive: false });
          this.setState({ textoloading: "" });
          console.log(e);
        });
    }
  }

  onChangeEndereco(e) {
    this.setState({
      logradouro: e.target.value,
    });
  }

  onChangeNumero(e) {
    this.setState({
      numero: e.target.value,
    });
  }

  onChangeBairro(e) {
    this.setState({
      bairro: e.target.value,
    });
  }

  onChangeCidade(e) {
    this.setState({
      cidade: e.target.value,
    });
  }

  onChangeUf(e) {
    this.setState({
      uf: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangeTelefone(e) {
    this.setState({
      telefone: e.target.value,
    });
  }

  componentDidMount() {
    //  window.scrollTo (0, 0);

    var cli = this.props.cookies.get("user") || "";
    console.log("user ck" + cli);
    if (cli === "") {
      this.setState({ submitted2: true });
    }

    if (cli === undefined) {
      cli = "";
    }
    if (cli !== "") {
      var cli2 = cli;
      this.setState({ nome: cli2.nome });
      this.setState({ cpfcnpj: cli2.cpfcnpj });
      this.setState({ cep: cli2.cep });
      this.setState({ cep2: cli2.cep });
      this.setState({ logradouro: cli2.logradouro });
      this.setState({ numero: cli2.numero });
      this.setState({ complemento: cli2.complemento });
      this.setState({ uf: cli2.uf });
      this.setState({ cidade: cli2.cidade });
      this.setState({ bairro: cli2.bairro });
      this.setState({ endereco: cli2.endereco });
      this.setState({ telefone: cli2.telefone });
    }
    ApiService.lojatitulo()
      .then((response) => {
        if (response.status === 200) {
          document.title = response.data + " | Checkout";
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });

      var ic = localStorage.getItem("itenscarrinho");
      if (ic == '0') {
        this.setState({
          submitted5: true,
        });
      }
  }

  render() {

    const submitted5 = this.state.submitted5;
    if (submitted5 === true) {
      return <Redirect to={"/carrinho"} />;
    }

    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/pagamento" />;
    }

    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=checkout" />;
    }

    const { carrinho } = this.props.carrinho;
    const { loja } = this.props.loja;
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="checkout-section spad">
          <div className="container">
            <div className="checkout-form">
              {this.state.message.length > 0 && (
                <div className="alert alert-danger alert-dismissible">
                  {this.state.message}
                </div>
              )}
              {this.state.erros !== undefined &&
                this.state.erros.map((erro, index) => (
                  <div
                    key={index}
                    className="alert alert-danger alert-dismissible"
                  >
                    {erro}
                  </div>
                ))}
              <div className="row">
                <div className="col-lg-6">
                  <h4>Detalhes de Entrega</h4>
                  <div className="row">
                    <div className="col-lg-3">
                      <label style={{ fontWeight: "bold" }} htmlFor="zip">
                        CEP:<span>*</span>
                      </label>
                      <InputMask
                        mask="99999-999"
                        value={this.state.cep}
                        onChange={this.onChangeCep.bind(this, carrinho)}
                        style={{ textTransform: "uppercase" }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-9">
                      <label style={{ fontWeight: "bold" }} htmlFor="street">
                        Endereço<span>*</span>:
                      </label>
                      <input
                        type="text"
                        id="street"
                        style={{ textTransform: "uppercase" }}
                        maxLength={100}
                        value={this.state.logradouro}
                        onChange={this.onChangeEndereco}
                        className="form-control street-first"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label style={{ fontWeight: "bold" }} htmlFor="town">
                        Numero<span>*</span>:
                      </label>
                      <input
                        type="text"
                        id="numero"
                        maxLength={20}
                        style={{ textTransform: "uppercase" }}
                        value={this.state.numero}
                        onChange={this.onChangeNumero}
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-9">
                      <label style={{ fontWeight: "bold" }} htmlFor="town">
                        Bairro<span>*</span>:
                      </label>
                      <input
                        type="text"
                        id="bairro"
                        maxLength={50}
                        style={{ textTransform: "uppercase" }}
                        value={this.state.bairro}
                        onChange={this.onChangeBairro}
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-9">
                      <label style={{ fontWeight: "bold" }} htmlFor="comp">
                        Complemento
                        <span />:
                      </label>
                      <input
                        type="text"
                        id="comp"
                        style={{ textTransform: "uppercase" }}
                        maxLength={50}
                        value={this.state.complemento}
                        onChange={this.onChangeComplemento}
                        className="form-control street-first"
                      />
                    </div>

                    <div className="col-lg-3">
                      <label style={{ fontWeight: "bold" }} htmlFor="town">
                        UF<span>*</span>:
                      </label>
                      <select
                        required
                        className="form-control select2"
                        value={this.state.uf}
                        onChange={this.onChangeUf}
                        name="uf"
                        style={{ height: "45px", borderWidth: "2px" }}
                      >
                        <option defaultValue value="">
                          selecione
                        </option>
                        {this.state.ufs &&
                          this.state.ufs.map((uf) => (
                            <option value={uf}>{uf}</option>
                          ))}
                      </select>
                    </div>

                    <div className="col-lg-8">
                      <label style={{ fontWeight: "bold" }} htmlFor="town">
                        Cidade<span>*</span>:
                      </label>
                      <input
                        type="text"
                        id="town"
                        maxLength={100}
                        style={{ textTransform: "uppercase" }}
                        value={this.state.cidade}
                        onChange={this.onChangeCidade}
                        className="form-control"
                      />
                    </div>

                    <div className="col-lg-4">
                      <label style={{ fontWeight: "bold" }} htmlFor="phone">
                        Telefone<span>*</span>:
                      </label>
                      <InputMask
                        mask="(99)99999-9999"
                        value={this.state.telefone}
                        onChange={this.onChangeTelefone}
                        style={{ textTransform: "uppercase" }}
                        className="form-control"
                      />
                    </div>

                    <div className="col-lg-12">
                      <div className="place-order">
                        {this.state.message3.length > 0 && (
                          <div className="alert alert-danger alert-dismissible">
                            {this.state.message3}
                          </div>
                        )}
                        <h4>Calcular Frete</h4>
                        <div className="order-total" style={{}}>
                          <div className="place-order">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <button
                                onClick={() =>
                                  this.calcularPrazoFrete(carrinho)
                                }
                                className="site-btn place-btn"
                                style={{
                                  border: "none",
                                  color: "white",
                                  padding: "10px 17px",
                                  textAlign: "center",
                                  textDecoration: "none",
                                  display: "inline-block",
                                  fontSize: "15px",
                                  borderRadius: "5px 5px 5px 5px",
                                }}
                              >
                                Calcular Frete
                              </button>
                            </div>

                            {this.state.fretes.length > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <select
                                  required
                                  className="form-control select2"
                                  value={this.state.frete}
                                  onChange={this.onChangeFrete.bind(
                                    this,
                                    carrinho
                                  )}
                                  name="uf"
                                  style={{
                                    height: "45px",
                                    borderWidth: "2px",
                                  }}
                                >
                                  <option defaultValue value="">
                                    selecione
                                  </option>
                                  {this.state.fretes &&
                                    this.state.fretes.map((uf) => (
                                      <option
                                        value={
                                          uf.servicox +
                                          "-" +
                                          uf.prazoEntrega +
                                          "-" +
                                          uf.valor
                                        }
                                      >
                                        {uf.servicox} - {uf.prazoEntrega}dias -
                                        R$
                                        {uf.valor.toFixed(2)}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="place-order">
                    <h4>Seu Pedido</h4>
                    <div className="order-total" style={{}}>
                      <ul className="order-table">
                        <li>
                          Produtos <span>Total</span>
                        </li>
                        {carrinho.itens &&
                          carrinho.itens.map((item, index) => (
                            <li key={index} className="fw-normal">
                              {item.nome}
                              {item.cor != undefined
                                ? "(" + item.cor + ")"
                                : ""}{" "}
                              <span>
                                R${" "}
                                {Number(item.preco * item.quantidade).toFixed(
                                  2
                                )}
                              </span>
                            </li>
                          ))}

                        <br />
                        <li
                          className="fw-normal"
                          style={{ fontWeight: "bold" }}
                        >
                          SUBTOTAL{" "}
                          <span>R$ {Number(carrinho.subtotal).toFixed(2)}</span>
                        </li>
                        <li
                          className="fw-normal"
                          style={{ fontWeight: "bold" }}
                        >
                          FRETE{" "}
                          <span>R$ {Number(carrinho.frete).toFixed(2)}</span>
                        </li>
                        <li
                          className="fw-normal"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          DESCONTO{" "}
                          <span>R$ {Number(carrinho.desconto).toFixed(2)}</span>
                        </li>

                        {loja.segmento == 11 && (
                          <li
                            className="fw-normal"
                            style={{ fontWeight: "bold" }}
                          >
                            MONTAGEM{" "}
                            <span>
                              R$ {Number(carrinho.valormontagem).toFixed(2)}
                            </span>
                          </li>
                        )}
                        <li className="total-price">
                          Total{" "}
                          <span>R$ {Number(carrinho.total).toFixed(2)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="place-order">
                    <h4>Continuar Para Pagamento</h4>
                    <div className="order-total" style={{ padding: "40px" }}>
                      <div className="order-btn">
                        <button
                          onClick={() => this.prosseguir(loja, carrinho)}
                          className="site-btn place-btn"
                          style={{
                            backgroundColor: "green" /* Green */,
                            border: "none",
                            color: "white",
                            padding: "12px 27px",
                            textAlign: "center",
                            textDecoration: "none",
                            display: "inline-block",
                            fontSize: "16px",
                            borderRadius: "5px 5px 5px 5px",
                            marginTop: "3%",
                            marginBottom: "3%",
                          }}
                        >
                          Pagamento
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  loja: state.loja,
  carrinho: state.carrinho,
});

const mapDispatchToProps = (dispatch) => ({
  salvaCarrinho: (carrinho) => dispatch(salvaCarrinho(carrinho)),
  getLoja: () => dispatch(getLoja()),
  getCarrinho: () => dispatch(getCarrinho()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(FormCheckout));
