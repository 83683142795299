import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';
import rootReducer from './reduces';
import {saveState, loadState} from './localStorage';

const middleware = [thunk];
const sentryReduxEnhancer = Sentry.createReduxEnhancer (
  {
    // Optionally pass options
  }
);

const store = createStore (
  rootReducer,
  loadState (),
  composeWithDevTools (applyMiddleware (...middleware), sentryReduxEnhancer)
);

store.subscribe (() => {
  saveState ({
    carrinho: store.getState ().carrinho,
  });
});

export default store;
