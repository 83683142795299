import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';

class Bread extends Component {
  render () {
    return (
      <div className="breacrumb-section">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="breadcrumb-text product-more">
							<NavLink to="/"><i className="fa fa-home"></i> Início</NavLink>
							<NavLink to="/loja">Loja</NavLink>
							<span>{this.props.nome}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
  }
}

export default Bread;
