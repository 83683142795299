import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getDestaques} from '../store/actions/destaqueAction';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ApiService from '../service/ApiService';

class Banner extends Component {
  constructor () {
    super ();
    this.state = {imgs: []};
  }

  componentWillMount () {
    try {
      this.props.getDestaques ();
    } catch (e) {
      console.error (e);
      throw new Error (e);
    }
  }

  async componentDidMount () {
    try {
      this.props.getDestaques ();
      window.scrollTo (0, 0);
    } catch (e) {
      console.error (e);
      throw new Error (e);
    }
  }

  async pegarimg (destaques, index) {
    
    ApiService.fotosdestaque (destaques[index].id)
      .then (response => {
        if (response.status === 200) {
          this.state.imgs[index] = response.data.img;

          this.setState ({imgs: this.state.imgs});
          console.log(this.state.imgs );
        }
      })
      .catch (e => {
        console.log (e);
      });
  }

  render () {
    const {destaques} = this.props.destaques;
    //  console.log (destaques);
    return (
      <section className="hero-section">

        <OwlCarousel items={1} margin={8} autoplay={true} loop>
          {destaques &&
            destaques.map ((destaque, index) => (
              <div key={index}>
                {/*  */}
                <a
                  href={destaque.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="img-fluid single-hero-items set-bg"
                    src={'data:image/jpeg;base64,' + destaque.img}
             
                    alt={destaque.foto}
                  />
                </a>
              </div>
            ))}

        </OwlCarousel>

      </section>
    );
  }
}

const mapStateToProps = state => ({
  destaques: state.destaques,
});

const mapDispatchToProps = dispatch => ({
  getDestaques: () => dispatch (getDestaques ()),
});

export default connect (mapStateToProps, mapDispatchToProps) (Banner);
