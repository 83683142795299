import {GET_LOJA, LOJA_ERROR} from '../types';
import ApiService from '../../service/ApiService';

export const getLoja = () => async dispatch => {
  try {
    const res = await ApiService.loja ();
    //console.log (res);
    dispatch ({
      type: GET_LOJA,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: LOJA_ERROR,
      payload: console.log (e),
    });
    console.error (e);
    //throw new Error (e);
  }
};
