import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Home from "./pages/Home";
import Produto from "./pages/Produto";
import Login from "./pages/Login";
import Cadastro from "./pages/Cadastro";
import Carrinho from "./pages/Carrinho";
import Checkout from "./pages/Checkout";
import Conta from "./pages/Conta";
import Contato from "./pages/Contato";
import Sobre from "./pages/Sobre";
import Loja from "./pages/Loja";
import Pesquisa from "./pages/Pesquisa";
import Pagamento from "./pages/Pagamento";
import Pedido from "./pages/Pedido";
import Sair from "./componentes/Sair";
import Pedidook from "./pages/Pedidook";
import EditarCadastro from "./pages/EditarCadastro";
import DescontoDia from "./pages/DescontoDia";
import Pedidos from "./pages/Pedidos";
import NotFound from "./pages/NotFound";
import Pagar from "./pages/Pagar";
import Pix from "./pages/Pix";
import Manutencao from "./pages/Manutencao";
import Lista from "./pages/Lista";
import CriarLista from "./pages/CriarLista";
import MinhaLista from "./pages/MinhaLista";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        {/**/}
        <Route path="/listas" component={Lista} />
        <Route path="/minha-lista" component={MinhaLista} />
        <Route path="/criar-lista" component={CriarLista} />
        {/**/}
        <Route path="/produto" component={Produto} />
        {/**/}
        <Route path="/login" component={Login} />
        <Route path="/sair" component={Sair} />
        {/**/}
        <Route path="/cadastrar" component={Cadastro} />
        <Route path="/carrinho" component={Carrinho} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/conta" component={Conta} />
        <Route path="/editar-cadastro" component={EditarCadastro} />
        <Route path="/contato" component={Contato} />
        <Route path="/sobre" component={Sobre} />
        <Route path="/loja" component={Loja} />
        <Route path="/desconto-do-dia" component={DescontoDia} />
        <Route path="/pesquisa" component={Pesquisa} />
        {/**/}
        <Route path="/pagamento" component={Pagamento} />
        <Route path="/pagar" component={Pagar} />
        <Route path="/pix" component={Pix} />
        {/**/}
        <Route path="/pedido" component={Pedido} />
        <Route path="/pedidos" component={Pedidos} />
        {/**/}
        <Route path="/confirmacao" component={Pedidook} />
        <Route path="/manutencao" component={Manutencao} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
