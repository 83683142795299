import {GET_CARRINHO, SALVA_CARRINHO} from '../types';

const initialState = {
  carrinho: {},
  loading: true,
};

export default function (state = initialState, action) {  
  switch (action.type) {
    case GET_CARRINHO:
      return {
        ...state,
        carinho: action.payload,
        loading: false,
      };
    case SALVA_CARRINHO:
      return {
        ...state,
        carinho: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
