import {combineReducers} from 'redux';
import carrinhoReducer from './carrinhoReducer';
import categoriaReducer from './categoriaReducer';
import produtoReducer from './produtoReducer';
import destaqueReducer from './destaqueReducer';
import lojaReducer from './lojaReducer';

export default combineReducers ({
  loja:lojaReducer,
  categorias: categoriaReducer,
  produtos: produtoReducer,
  cprodutos: produtoReducer,
  produtosd: produtoReducer,
  produtosc: produtoReducer,
  pesquisa:produtoReducer,
  produto: produtoReducer,
  fotos: produtoReducer,
  foto:produtoReducer,
  carrinho:carrinhoReducer,
  destaques:destaqueReducer
});
