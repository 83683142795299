import {GET_PRODUTOS, GET_CPRODUTOS,GET_PRODUTO, GET_FOTOS,GET_FOTO,GET_PESQUISA,GET_PRODUTOSD,GET_PRODUTOSC} from '../types';

const initialState = {
  produtos: [],
  cprodutos: [],
  produtosd: [],
  produtosc: [],
  pesquisa:[],
  produto: {},
  fotos: [],
  foto:{},
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUTOS:
      return {
        ...state,
        produtos: action.payload,
        loading: false,
      };
      case GET_CPRODUTOS:
        return {
          ...state,
          cprodutos: action.payload,
          loading: false,
        };
      case GET_PRODUTOSD:
      return {
        ...state,
        produtosd: action.payload,
        loading: false,
      };
      case GET_PRODUTOSC:
        return {
          ...state,
          produtosc: action.payload,
          loading: false,
        };
      case GET_PESQUISA:
      return {
        ...state,
        pesquisa: action.payload,
        loading: false,
      };
    case GET_PRODUTO:
      return {
        ...state,
        produto: action.payload,
        loading: false,
      };
    case GET_FOTOS:
      return {
        ...state,
        fotos: action.payload,
        loading: false,
      };
      case GET_FOTO:
      return {
        ...state,
        foto: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
