export const GET_CATEGORIAS = 'GET_CATEGORIAS';
export const CATEGORIAS_ERROR = 'CATEGORIAS_ERROR';
//
export const GET_CPRODUTOS = 'GET_CPRODUTOS';
export const GET_PRODUTOS = 'GET_PRODUTOS';
export const GET_PRODUTOSD = 'GET_PRODUTOSD';
export const GET_PRODUTOSC= 'GET_PRODUTOSC';
export const GET_PESQUISA = 'GET_PESQUISA';
export const PRODUTOS_ERROR = 'PRODUTOS_ERROR';
export const GET_PRODUTO= 'GET_PRODUTO';
export const GET_FOTOS= 'GET_FOTOS';
export const GET_FOTO= 'GET_FOTO';
//
export const GET_CARRINHO= 'GET_CARRINHO';
export const SALVA_CARRINHO= 'SALVA_CARRINHO';
export const CARRINHO_ERROR='CARRINHO_ERROR';
//
export const GET_DESTAQUES = 'GET_DESTAQUES';
export const DESTAQUES_ERROR = 'DESTAQUES_ERROR';
//
export const GET_LOJA = 'GET_LOJA';
export const LOJA_ERROR = 'LOJA_ERROR';
