import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import ApiService from "../service/ApiService";
import Moment from "react-moment";

class Lista extends Component {
  constructor() {
    super();
    this.state = {
      submitted2: false,
      submitted: false,
      listas: [],
      email: "",
    };
  }

  componentDidMount() {
    ApiService.lojatitulo()
      .then((response) => {
        if (response.status === 200) {
          document.title = response.data + " | Listas";
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
    var cli = this.props.cookies.get("user") || "";
    console.log("user ck" + cli);
    if (cli === "") {
      this.setState({ submitted2: true });
    }
    this.setState({ email: cli.email });
    ApiService.listas(cli.email)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            listas: response.data,
          });
          // console.log (response.data);
        }
      })
      .catch((e) => {
        this.setState({ message: "Erro ao recuperar listas" });
        console.log(e);
      });
  }

  excluir(lista) {
    ApiService.excluirlista(lista.id)
      .then((response) => {
        if (response.status === 200) {
          ApiService.listas(this.state.email)
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  listas: response.data,
                });
                // console.log (response.data);
              }
            })
            .catch((e) => {
              this.setState({ message: "Erro ao recuperar listas" });
              console.log(e);
            });
          // console.log (response.data);
        }
      })
      .catch((e) => {
        this.setState({ message: "Erro ao recuperar listas" });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/conta" />;
    }

    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=listas" />;
    }

    return (
      <section className="checkout-section spad">
        <div className="container">
          <form action="#" className="checkout-form">
            <div className="row">
              <div className="col-lg-12">
                <div className="place-order">
                  <h4>Minhas Listas de Compras</h4>

                  <div className="col-12 table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Descrição</th>
                          <th>Produtos</th>
                          <th>.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listas &&
                          this.state.listas.map((lista, index) => (
                            <tr key={index} style={{ fontSize: "13px" }}>
                              <td>
                                {" "}
                                <a href={"/minha-lista?codigo=" + lista.id}>
                                  {lista.nome}
                                </a>
                              </td>
                              <td>{lista.descricao}</td>
                              <td>{lista.qtdprodutos}</td>
                              <td style={{ color: "red" }}>
                                {lista.nome != "Favoritos" ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                      if (window.confirm("Excluir a lista?")) {
                                        this.excluir(lista);
                                      }
                                    }}
                                  >
                                    X
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="card-footer clearfix">
                      <a
                        href="/criar-lista"
                        style={{ color: "green", fontWeight: "bold" }}
                      >
                        CRIAR NOVA LISTA
                      </a>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default withCookies(Lista);
