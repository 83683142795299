import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getProduto,
  getFoto,
  getFotos,
  getProdutosc,
} from "../store/actions/produtoAction";
import { salvaCarrinho } from "../store/actions/carrinhoAction";
import { getLoja } from "../store/actions/lojaAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Redirect } from "react-router-dom";
import ApiService from "../service/ApiService";
import { withCookies } from "react-cookie";
const checkboxes = [
  {
    name: "branco",
    key: "branco",
    label: "Branco",
    cor: "#EEF2F8",
  },
  {
    name: "perola",
    key: "perola",
    label: "Pérola",
    cor: "#E5D2B3",
  },
  {
    name: "taupe",
    key: "taupe",
    label: "Taupe",
    cor: "#D2B179",
  },
  {
    name: "caramelo",
    key: "caramelo",
    label: "Caramelo",
    cor: "#FFB74D",
  },

  {
    name: "whisky",
    key: "whisky",
    label: "Whisky",
    cor: "#8b4513",
  },
  {
    name: "cafe-fosco",
    key: "cafe-fosco",
    label: "Cafe fosco",
    cor: "#4d2719",
  },
  {
    name: "cafe-brilho",
    key: "cafe-brilho",
    label: "Cafe brilho",
    cor: "#86442d",
  },
  {
    name: "chumbo",
    key: "chumbo",
    label: "Chumbo",
    cor: "#607d8b",
  },
  {
    name: "preto-brilho",
    key: "preto-brilho",
    label: "Preto brilho",
    cor: "#4d4d4d",
  },
  {
    name: "preto-fosco",
    key: "preto-fosco",
    label: "Preto fosco",
    cor: "#333333",
  },
];

class DadosProdutoSofa extends Component {
  constructor() {
    super();
    this.state = {
      quantidade: 1,
      imgsd: [],
      submitted3: false,
      submitted4: false,
      cores: [],
      cor: "",
      user: "",
      montagem: "nao",
    };
    this.onChangeAdicionar = this.onChangeAdicionar.bind(this);
    this.onChangecor = this.onChangecor.bind(this);
    this.adicionaritem = this.adicionaritem.bind(this);
  }

  handleOptionChangem(evt) {
    this.setState({
      montagem: evt.target.value,
    });
  }

  onChangecor(event) {
    this.setState({
      cor: event.target.value,
    });
  }

  async pegarimgd(produtosd, index2) {
    console.log("produtod... " + produtosd[index2].nome);
    ApiService.fotoproduto(produtosd[index2].id)
      .then((response) => {
        if (response.status === 200) {
          this.state.imgsd[index2] = response.data.data;
          // console.log ('pppp' + this.state.imgsd[index2]);
          this.setState({ imgsd: this.state.imgsd });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onChangeAdicionar(e) {
    this.setState({
      quantidade: e.target.value,
    });
  }

  componentWillMount() {
    try {
      // window.scrollTo (0, 0);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const id = params.get("i");

      this.props.getFotos(id);
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  componentDidMount() {
    try {
      // window.scrollTo (0, 0);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const id = params.get("i");
      const n = params.get("n");
      if (id == null) {
        this.setState({ submitted3: true });
      }
      this.props.getProduto(id);
      this.props.getFoto(id);
      this.props.getFotos(id);
      this.props.getProdutosc(id);
      this.props.getLoja();
      if (n != null) {
        ApiService.lojatitulo()
          .then((response) => {
            if (response.status === 200) {
              document.title = response.data + " | " + n;
            }
          })
          .catch((e) => {
            ///  alert ('Erro ao recuperar idtawkto email');
            console.log(e);
          });
      }
      var user = this.props.cookies.get("user") || "";
      this.setState({ user: user });
    } catch (e) {
      console.error(e);
      this.setState({ submitted3: true });
      throw new Error(e);
    }
  }

  pegacores(cores) {
    console.log("cores");
    console.log(cores);
    console.log(cores.length);
    var cors = [];
    if (cores != undefined) {
      if (cores.length > 0) {
        var fg = cores.split(",");
        for (let i = 0; i < fg.length; i++) {
          if (fg[i].length > 1) {
            console.log(fg[i]);

            var cd = checkboxes.filter((c) => c.key == fg[i]);
            if (cd.length == 1) {
              var g = {};
              g.name = cd[0].name;
              g.cor = cd[0].cor;
              cors.push(g);
            }
          }
        }
      }
    }
    return cors;
  }

  adicionaritem(
    preco,
    desconto,
    promocao,
    nome,
    codigo,
    foto,
    carrinho,
    cores,
    valormontagem
  ) {
    var ok = true;

    if (cores != undefined) {
      if (cores.length > 0) {
        if (cores.split(",").length > 0 && this.state.cor == "") {
          ok = false;
          alert("selecione a cor");
        }
      }
    }
    if (ok) {
      var item = {};
      item.quantidade = Number(this.state.quantidade);
      item.preco = Number(preco);
      item.promocao = Number(promocao);
      item.desconto = Number(desconto);
      item.nome = nome;
      item.codigo = codigo;
      item.foto = foto;
      item.cor = this.state.cor;
      if (this.state.montagem == "sim") {
        if (valormontagem != undefined) {
          item.valormontagem = valormontagem;
        } else {
          item.valormontagem = 0;
        }
      }
      if (carrinho.itens === undefined) {
        carrinho.itens = [];
        carrinho.total = 0;
      }
      var tem = false;
      var pos = 0;
      for (var i = 0; i < carrinho.itens.length; i++) {
        var it = carrinho.itens[i];
        if (it.codigo === codigo) {
          tem = true;
          break;
        }
        pos++;
      }
      if (tem === false) {
        carrinho.itens.push(item);
      } else {
        carrinho.itens[pos].quantidade += Number(this.state.quantidade);
      }

      var total = 0;
      var subtotal = 0;
      var descontot = 0;
      var montagem=0;

      montagem = carrinho.itens.reduce(
        (prev, next) => prev + next.valormontagem,
        0
      );

      subtotal = carrinho.itens.reduce(
        (prev, next) => prev + next.preco * next.quantidade,
        0
      );
      total = carrinho.itens.reduce(
        (prev, next) =>
          prev +
          (next.preco * next.quantidade - next.desconto * next.quantidade),
        0
      );
      descontot = carrinho.itens.reduce(
        (prev, next) => prev + next.desconto * next.quantidade,
        0
      );
      carrinho.total = total+montagem;
      carrinho.subtotal = subtotal;
      carrinho.desconto = descontot;
      carrinho.valormontagem=montagem;
      //console.log (carrinho);
      this.props.salvaCarrinho(carrinho);
      localStorage.setItem("itenscarrinho", 1);
      this.setState({ submitted3: true });
    }
  }

  adicionarfavorito(pp) {
    console.log("favorito ");
    console.log(pp);
    console.log(this.state.user);
    // this.props.salvafavorito(pp);
    //window.scrollTo (0, 0);
    if (this.state.user !== "") {
      var data = {};
      data.email = this.state.user.usuario;
      var prod = {};
      prod.id = pp.id;
      data.produto = prod;

      console.log(data.email);
      console.log(data.produto);

      ApiService.salvaritemlista(data)
        .then((response) => {
          if (response.status === 200) {
            this.addfavorito();
          }
        })
        .catch((e) => {
          //this.setState ({message: 'Erro ao recuperar listas'});
          console.log(e);
        });
    }
  }

  addfavorito() {
    // Get the snackbar DIV
    var x = document.getElementById("addfavorito");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  createitem() {}

  render() {
    const submitted3 = this.state.submitted3;
    if (submitted3 === true) {
      return <Redirect to="/" />;
    }

    const { categorias } = this.props.categorias;
    const { produto } = this.props.produto;

    const { foto } = this.props.foto;
    const { fotos } = this.props.fotos;
    const { carrinho } = this.props.carrinho;
    const { produtosc } = this.props.produtosc;
    const { loja } = this.props.loja;

    //console.log (carrinho);

    return (
      <section className="product-shop spad page-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-pic-zoom">
                <img
                  className="product-big-img border"
                  src={"data:image/jpeg;base64," + foto.data}
                  alt={foto.nome}
                  height="400px"
                />
                <div className="zoom-icon">
                  <i className="fa fa-search-plus" />
                </div>
              </div>

              <div className="product-thumbs">
                <OwlCarousel
                  items={3}
                  margin={8}
                  className="product-thumbs-track"
                >
                  {fotos &&
                    fotos.map((ff, index) => (
                      <div
                        key={index}
                        className={index === 0 ? "pt active" : "pt"}
                      >
                        <img
                          src={"data:image/jpeg;base64," + ff.data}
                          alt={ff.nome}
                          className="img-thumbnail"
                        />
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-details">
                    <div className="pd-title">
                      {/* <span>{produto.categoriax}</span> */}
                      <h3>{produto.nome}</h3>
                      <div class="icon">
                        <i
                          onClick={() => this.adicionarfavorito(produto)}
                          class="icon_heart_alt"
                        ></i>
                      </div>
                      <div class="form-group mb-2">
                        <div className="pd-rating">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-o" />
                        </div>

                        <div class="form-group mb-2">
                          <label>0 Comentario(s)</label>
                        </div>
                      </div>

                      <p>{produto.introducao}</p>

                      <hr />
                      {produto.prazoentrega != undefined &&
                        loja.segmento == 11 && (
                          <div class="form-group">
                            <label
                              style={{ fontWeight: "bold", color: "#878787" }}
                            >
                              Prazo:
                            </label>
                            <span
                              class="badge badge-success"
                              style={{ color: "#fff" }}
                            >
                              {produto.prazoentrega}
                            </span>
                          </div>
                        )}

                      {produto.cores != undefined && loja.segmento == 11 && (
                        <div>
                          <label
                            style={{ fontWeight: "bold", color: "#878787" }}
                          >
                            Escolha a Cor:
                          </label>
                          <br />

                          {this.pegacores(produto.cores).map((item) => (
                            <div class="form-check form-check-inline">
                              <label key={item.key} class="form-check-label">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  value={item.name}
                                  name="cor"
                                  onChange={this.onChangecor}
                                />
                                <div
                                  style={{
                                    height: "70px",
                                    width: "70px",
                                    backgroundColor: item.cor,
                                  }}
                                />

                                {item.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="pd-desc">
                      <label style={{ fontWeight: "bold", color: "#878787" }}>
                        Preço:
                      </label>
                      <h4>
                        R${" "}
                        {produto.desconto > 0
                          ? Number(produto.preco - produto.desconto).toFixed(2)
                          : Number(produto.preco).toFixed(2)}
                        {produto.desconto > 0 && (
                          <span>R$ {Number(produto.preco).toFixed(2)}</span>
                        )}
                      </h4>
                    </div>
                    {loja.segmento == 11 && (
                      <div>
                        {/*
                        <div>
                          <h6 style={{ fontWeight: "bold", color: "#878787" }}>
                            LUGARES
                          </h6>
                          <input type="radio" name="p" value="p" /> Poltrona
                          1,05C x 0,85A x 0,85P -R$900,00 <br />
                          <input type="radio" name="2" value="2" /> 2 Lugares
                          1,60C x 0,85A x 0,85P -R$300,00 <br />
                          <input type="radio" name="3" value="3" /> 3 Lugares
                          2,10C x 0,85A x 0,85P
                          <br />
                          <input type="radio" name="4" value="4" /> 4 Lugares
                          2,50C x 0,85A x 0,85P +R$959,00 <br />
                          <input type="radio" name="5" value="5" /> 5 Lugares
                          3,25C x 0,85A x 0,85P +R$1.600,00
                        </div>
                        <br />
                        <div>
                          <h6 style={{ fontWeight: "bold", color: "#878787" }}>
                            COMPRAR TAMBÉM
                          </h6>
                          <input type="checkbox" name="pc" value="p" /> Poltrona
                          1,05C x 0,85A x 0,85P +R$1.690,00
                          <br />
                          <input type="checkbox" name="2c" value="2" /> 2
                          Lugares 1,60C x 0,85A x 0,85P +R$2.290,00
                          <br />
                          <input type="checkbox" name="3c" value="3" /> 3
                          Lugares 2,10C x 0,85A x 0,85P +R$2.590,00
                          <br />
                          <input type="checkbox" name="4c" value="4" /> 4
                          Lugares 2,50C x 0,85A x 0,85P +R$3.549,00
                          <br />
                          <input type="checkbox" name="5c" value="5" /> 5
                          Lugares 3,25C x 0,85A x 0,85P +R$4.190,00
                        </div>*/}
                        <br />
                        {produto.valormontagem != undefined && (
                          <div>
                            <h6
                              style={{ fontWeight: "bold", color: "#878787" }}
                            >
                              MONTAGEM DE FÁBRICA
                            </h6>
                            <input
                              type="radio"
                              name="montagem"
                              value="sim"
                              checked={this.state.montagem === "sim"}
                              onChange={(evt) => this.handleOptionChangem(evt)}
                            />{" "}
                            Sim +R$ {produto.valormontagem.toFixed(2)}
                            <br />
                            <input
                              type="radio"
                              name="montagem"
                              value="nao"
                              checked={this.state.montagem === "nao"}
                              onChange={(evt) => this.handleOptionChangem(evt)}
                            />{" "}
                            Não
                            <br />
                          </div>
                        )}
                      </div>
                    )}
                    <br />

                    {produto.estoque > 0 && (
                      <div className="quantity">
                        <input
                          style={{ width: "70px", color: "black" }}
                          step="1"
                          min="1"
                          type="number"
                          value={this.state.quantidade}
                          onChange={this.onChangeAdicionar}
                          name="quantidade"
                        />

                        <a
                          href="#"
                          onClick={() =>
                            this.adicionaritem(
                              produto.preco,
                              produto.desconto,
                              produto.promocao,
                              produto.nome,
                              produto.id,
                              foto,
                              carrinho,
                              produto.cores,
                              produto.valormontagem
                            )
                          }
                          className="primary-btn pd-cart"
                        >
                          Comprar
                        </a>
                      </div>
                    )}
                    {produto.estoque === 0 && (
                      <div
                        style={{ padding: "20px", backgroundColor: "#f1f1f1" }}
                      >
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h4>Não Disponível</h4>
                          <p style={{ textAlign: "center" }}>
                            Avise-me quando estiver disponível ou{" "}
                            <a href="#">Ver produtos similares</a>.
                          </p>
                        </div>

                        <div className="container" style={{ color: "white" }}>
                          <input
                            type="text"
                            placeholder="Nome"
                            name="name"
                            required
                            style={{
                              width: "100%",
                              padding: "8px",
                              border: "1px solid #ccc",
                              display: "inline-block",
                              boxSizing: "border-box",
                            }}
                          />
                        </div>
                        <br />
                        <div className="container" style={{ color: "white" }}>
                          <input
                            type="text"
                            placeholder="Email"
                            name="mail"
                            required
                            style={{
                              width: "100%",
                              padding: "8px",
                              display: "inline-block",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="ofertas"
                            checked="checked"
                          />
                          <label htmlFor="ofertas" style={{ marginTop: "4px" }}>
                            &nbsp;Quero receber ofertas e conteúdos por email
                          </label>
                        </div>

                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <a
                            href="#"
                            className="primary-btn"
                            style={{ backgroundColor: "green" }}
                          >
                            Avise-me
                          </a>
                        </div>
                      </div>
                    )}

                    <ul className="pd-tags">
                      <li>
                        <span>CATEGORIA</span>: {produto.categoriax}
                      </li>
                      <li>
                        <span>SUB-CATEGORIA</span>: {produto.subcategoriax}
                      </li>
                    </ul>
                    <div className="pd-share">
                      <div className="p-code">Cód: {produto.id}</div>
                      <br />
                      <div className="p-code">EAN: {produto.ean}</div>
                      <br />
                      <div className="p-code">
                        Prazo Entrega:{" "}
                        {produto.prazoentrega != ""
                          ? produto.prazoentrega
                          : loja.prazoentrega}
                      </div>
                      <div className="pd-social">
                        <a
                          rel="noopener noreferrer"
                          href={
                            "https://www.facebook.com/sharer/sharer.php?u=" +
                            window.location.href
                          }
                          target="_blank"
                        >
                          <i className="ti-facebook" />
                        </a>
                        <a
                          rel="noopener noreferrer"
                          href={
                            "https://twitter.com/intent/tweet?text=" +
                            produto.nome +
                            "&url=" +
                            window.location.href
                          }
                          target="_blank"
                        >
                          <i className="ti-twitter-alt" />
                        </a>
                        <a
                          rel="noopener noreferrer"
                          href={
                            "https://www.linkedin.com/shareArticle?mini=true&url=" +
                            window.location.href +
                            "&title=" +
                            produto.nome +
                            "&summary=" +
                            produto.nome +
                            "&source=" +
                            produto.nome +
                            ""
                          }
                          target="_blank"
                        >
                          <i className="ti-linkedin" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="product-tab">
                <div className="tab-item">
                  <ul className="nav" role="tablist">
                    <li>
                      <a
                        class="active"
                        data-toggle="tab"
                        href="#tab-1"
                        role="tab"
                      >
                        Descrição
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab-2" role="tab">
                        Informações
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab-3" role="tab">
                        Avaliações
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-item-content">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade-in active"
                      id="tab-1"
                      role="tabpanel"
                    >
                      <div className="product-content">
                        <div className="row">
                          <div className="col-lg-7">
                            <h5>Introdução</h5>
                            <p>{produto.introducao}</p>
                            <h5>Características</h5>
                            <p>{produto.caracteristica}</p>
                          </div>
                          {/*   <div className="col-lg-5">
                            <img
                              src={'data:image/jpeg;base64,' + foto.data}
                              alt={foto.nome}
                            /> 
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="tab-2" role="tabpanel">
                      <div className="product-content">
                        <div className="row">
                          <div className="col-lg-7">
                            <h5>Fabricante</h5>
                            <p>{produto.laboratorio}</p>

                            <h5>Preço</h5>
                            <p>R${Number(produto.preco).toFixed(2)}</p>

                            {(loja.segmento == 3 || loja.segmento == 4) && (
                              <div>
                                <h5>Quantidade</h5>
                                <p>{produto.quantidade}</p>
                              </div>
                            )}

                            {(loja.segmento == 3 || loja.segmento == 4) && (
                              <div>
                                <h5>Dosagem</h5>
                                <p>{produto.dosagem}</p>
                              </div>
                            )}
                          </div>
                          {/*   <div className="col-lg-5">
                            <img
                              src={'data:image/jpeg;base64,' + foto.data}
                              alt={foto.nome}
                            /> 
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="tab-3" role="tabpanel">
                      <div class="customer-review-option col-lg-12 row">
                        {/* <div class="comment-option col-lg-6">
                          <h4>2 Comentários</h4>
                          <div class="co-item">
                            <div class="avatar-pic">
                              <img
                                src="img/product-single/avatar-1.png"
                                alt=""
                              />
                            </div>
                            <div class="avatar-text">
                              <div class="at-rating">
                                <i class="fa fa-star" />
                                <i class="fa fa-star" />
                                <i class="fa fa-star" />
                                <i class="fa fa-star" />
                                <i class="fa fa-star" />
                              </div>
                              <h5>
                                Maria Rosa <span>07 abril 2021</span>
                              </h5>
                              <div class="at-reply">
                                Muito satisfeita com o produto!
                              </div>
                            </div>
                          </div>
                          <div class="co-item">
                            <div class="avatar-pic">
                              <img
                                src="img/product-single/avatar-2.png"
                                alt=""
                              />
                            </div>
                            <div class="avatar-text">
                              <div class="at-rating">
                                <i class="fa fa-star" />
                                <i class="fa fa-star" />
                                <i class="fa fa-star" />
                                <i class="fa fa-star" />
                                <i class="fa fa-star-o" />
                              </div>
                              <h5>
                                Maria Souza<span>07 abril 2021</span>
                              </h5>
                              <div class="at-reply">
                                Produto de otima qualidade.
                              </div>
                            </div>
                          </div>
                        </div>*/}

                        <div class="leave-comment col-lg-6">
                          <h4>Deixe um comentário:</h4>
                          {/* <div class="personal-rating">
                            <h6>Sua Avaliação</h6>
                            <div class="pd-rating">
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star-o"></i>
                            </div>
                          </div> */}
                          <form action="#" class="comment-form">
                            <div class="row">
                              <div class="col-lg-6">
                                <input type="text" placeholder="Nome" />
                              </div>
                              <div class="col-lg-6">
                                <input type="text" placeholder="Email" />
                              </div>
                              <div class="col-lg-12">
                                <textarea placeholder="Mensagem" />
                                <button type="submit" class="site-btn">
                                  Enviar Mensagem
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        &nbsp;
        <hr />
        &nbsp;
        <div className="related-products spad">
          <div className="container">
            {produtosc.length > 0 && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title">
                    <h2>Produtos Relacionados</h2>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {produtosc &&
                produtosc.map((produtoc, index2) => (
                  <div className="col-lg-3 col-sm-6" key={index2}>
                    <div className="product-item">
                      <div className="pi-pic">
                        <a
                          href={`/produto?i=${
                            produtoc.id
                          }&n=${produtoc.nome.replaceAll(" ", "-")}`}
                        >
                          <img
                            src={
                              this.state.imgsd[index2] != null
                                ? "data:image/jpeg;base64," +
                                  this.state.imgsd[index2]
                                : "/img/lo.gif"
                            }
                            alt={
                              produtoc.foto != null ? produtoc.foto.nome : "img"
                            }
                            onLoad={() => this.pegarimgd(produtosc, index2)}
                            className="border"
                            widht="80px"
                            height="200px"
                          />
                        </a>
                      </div>
                      <div className="pi-text">
                        <div className="catagory-name">
                          {produtoc.categoriax}
                        </div>
                        <h5 style={{ fontWeight: "700", fontSize: "1vw" }}>
                          {produtoc.nome}
                        </h5>
                        <h6
                          style={{
                            fontWeight: "600",
                            marginTop: "5px",
                            color: "#00b0ef",
                          }}
                        >
                          {produtoc.laboratorio}
                        </h6>
                        <div
                          className="product-price"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {produtoc.desconto > 0 && (
                            <span>
                              R${produtoc.preco.toFixed(2)}
                              <br />{" "}
                            </span>
                          )}{" "}
                          R${" "}
                          {produtoc.desconto > 0
                            ? (produtoc.preco - produtoc.desconto).toFixed(2)
                            : produtoc.preco.toFixed(2)}
                          <h1 style={{ fontSize: "12px" }}>cada</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  categorias: state.categorias,
  produto: state.produto,
  foto: state.foto,
  fotos: state.fotos,
  carrinho: state.carrinho,
  produtosc: state.produtosc,
  loja: state.loja,
});

const mapDispatchToProps = (dispatch) => ({
  getProduto: (id) => dispatch(getProduto(id)),
  getFoto: (idf) => dispatch(getFoto(idf)),
  getFotos: (idf) => dispatch(getFotos(idf)),
  salvaCarrinho: (carrinho) => dispatch(salvaCarrinho(carrinho)),
  getProdutosc: (idf) => dispatch(getProdutosc(idf)),
  getLoja: () => dispatch(getLoja()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(DadosProdutoSofa));
