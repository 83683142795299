import React, {Component} from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import FormPesquisa from '../componentes/FormPesquisa';
import Bread from '../componentes/Bread';

class Pesquisa extends Component {
  render () {
    return (
      <div>
       <div id="preloder" >
          <div className="loader" />
        </div>
        <Header />
        <Bread nome={'Pesquisa'}/>
        <FormPesquisa />
        <Footer />
      </div>
    );
  }
}

export default Pesquisa;
