import React, {Component} from 'react';
import ApiService from '../service/ApiService';

class FormSobre extends Component {
  componentDidMount () {
    window.scrollTo (0, 0);
    ApiService.lojatitulo ()
      .then (response => {
        if (response.status === 200) {
          document.title = response.data + ' | Sobre Nós';
        }
      })
      .catch (e => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log (e);
      });
  }

  render () {
    return (
      <section className="blog-details spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-inner">
                <div className="blog-detail-title">
                  <h2>Sobre Nós</h2>
                </div>
                <br />
                <div className="blog-large-pic">
                  <img src="img/blog/blog-detail.jpg" alt="" />
                </div>
                <div className="blog-detail-desc">
                  <p />
                </div>
                <div className="blog-quote">
                  <p> <span>  </span></p>
                </div>
                <div className="blog-more">
                  <div className="row">
                    <div className="col-sm-4">
                      <img src="img/blog/blog-detail-1.jpg" alt="" />
                    </div>
                    <div className="col-sm-4">
                      <img src="img/blog/blog-detail-2.jpg" alt="" />
                    </div>
                    <div className="col-sm-4">
                      <img src="img/blog/blog-detail-3.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <p>.</p>

              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FormSobre;
