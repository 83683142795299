import React, {Component} from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import Bread from '../componentes/Bread';

class Manutencao extends Component {
  render () {
    return (
      <div>
        <div id="preloder">
          <div className="loader" />
        </div>

        <section className="checkout-section spad">
          <div className="container">

            <div className="row">

              <div className="col-lg-12">
                <div className="contact-title">
                  <h4>Estamos em manutenção :/</h4>
                  <p>Volte mais tarde.</p>{' '}
                </div>
                <br/>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <a href="/" className="site-btn2 register-btn" style={{borderRadius:"5px"}}>
                  Pagina Inicial
                </a>
                </div>

              </div>

            </div>
          </div>
        </section>

      </div>
    );
  }
}

export default Manutencao;
