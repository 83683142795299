import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {instanceOf} from 'prop-types';
import {withCookies, Cookies} from 'react-cookie';
import ApiService from '../service/ApiService';
import {cpf} from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';
import EmailValidator from 'email-validator';
import LoadingOverlay from 'react-loading-overlay';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';

class FormCadastro extends Component {
  constructor () {
    super ();
    this.state = {
      nome: '',
      cpf: '',
      usuario: '',
      senha: '',
      csenha: '',
      message: '',
      submitted: false,
      erros: [],
      isActive: false,
      textoloading: '',
    };
    this.onChangeUsuario = this.onChangeUsuario.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeCpf = this.onChangeCpf.bind (this);
    this.onChangeSenha = this.onChangeSenha.bind (this);
    this.onChangeCsenha = this.onChangeCsenha.bind (this);
    this.salvar = this.salvar.bind (this);
  }

  static propTypes = {
    cookies: instanceOf (Cookies).isRequired,
  };

  componentDidMount () {
    loadCaptchaEnginge (6);
    // window.scrollTo (0, 0);

    ApiService.lojatitulo ()
      .then (response => {
        if (response.status === 200) {
          document.title = response.data + ' | Cadastrar';
        }
      })
      .catch (e => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log (e);
      });
  }

  onChangeNome (e) {
    this.setState ({
      nome: e.target.value,
    });
  }

  onChangeCpf (e) {
    this.setState ({
      cpf: e.target.value,
    });
  }

  onChangeUsuario (e) {
    this.setState ({
      usuario: e.target.value,
    });
  }

  onChangeSenha (e) {
    this.setState ({
      senha: e.target.value,
    });
  }

  onChangeCsenha (e) {
    this.setState ({
      csenha: e.target.value,
    });
  }

  salvar () {
    this.setState ({isActive: true});
    this.setState ({textoloading: 'Processando...'});
    var ok = true;
    var erros = [];

    if (
      this.state.cpf.replace ('-', '').split ('.').join ('').replace ('_', '')
        .length === 11
    ) {
      if (
        !cpf.isValid (
          this.state.cpf
            .replace ('-', '')
            .split ('.')
            .join ('')
            .replace ('_', '')
        )
      ) {
        ok = false;
        erros.push ('CPF Inválido!');
      }
    }

    if (this.state.nome.length === 0) {
      ok = false;
      erros.push ('Nome Inválido!');
    }

    if (this.state.usuario.length === 0) {
      ok = false;
      erros.push ('Email Inválido!');
    }

    if (this.state.senha.length === 0) {
      ok = false;
      erros.push ('Senha Inválida!');
    }

    if (
      this.state.cpf.replace ('-', '').replace ('_', '').split ('.').join ('')
        .length !== 11
    ) {
      ok = false;
      erros.push ('CPF Inválido!');
    }

    if (this.state.senha !== this.state.csenha) {
      ok = false;
      erros.push ('Senhas Diferentes! Verifique e Tente Novamente.');
    }

    if (!EmailValidator.validate (this.state.usuario)) {
      ok = false;
      erros.push ('Email Inválido!');
    }

    let user_captcha_value = document.getElementById ('user_captcha_input')
      .value;

    if (validateCaptcha (user_captcha_value) == true) {
      //alert ('Captcha Matched');
    } else {
      ok = false;
      erros.push ('Captcha invalido');
    }

    this.setState ({erros: erros});

    if (ok === false) {
      this.setState ({isActive: false});
      this.setState ({textoloading: ''});
    }

    if (ok) {
      var md5 = require ('md5');
      var data = {
        usuario: this.state.usuario,
        email: this.state.usuario,
        status: 'Ativo',
        nome: this.state.nome,
        cpfcnpj: this.state.cpf
          .replace ('-', '')
          .split ('.')
          .join ('')
          .replace ('_', ''),
        senha: md5 (this.state.senha),
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        telefone: '',
      };
      //console.log (data);

      ApiService.salvarusuario (data)
        .then (response => {
          if (response.status === 200) {
            const {cookies} = this.props;
            cookies.set ('user', response.data, {path: '/'});
            this.setState ({isActive: false});
            this.setState ({textoloading: ''});
            this.setState ({
              submitted: true,
            });
            // console.log (response.data);
          }
        })
        .catch (e => {
          this.setState ({isActive: false});
          this.setState ({textoloading: ''});
          this.setState ({message: 'Erro ao Cadastrar Usuário!'});
          console.log (e);
        });
    }
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/conta" />;
    }

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <div className="register-login-section spad">
          <div className="container">
            <div className="row">

              <div className="col-lg-6 offset-lg-3">
                {this.state.message &&
                  <div className="alert alert-danger alert-dismissible">
                    {this.state.message}
                  </div>}
                {this.state.erros !== undefined &&
                  this.state.erros.map ((erro, index) => (
                    <div
                      key={index}
                      className="alert alert-danger alert-dismissible"
                    >
                      {erro}
                    </div>
                  ))}
                <div className="register-form">
                  <h2>Criar Conta</h2>

                  <div className="group-input">
                    <b> <label htmlFor="nome">Nome: *</label></b>
                    <input
                      autoFocus
                      type="text"
                      maxLength="100"
                      className="form-control"
                      id="nome"
                      placeholder="Insira o Nome Completo"
                      value={this.state.nome}
                      onChange={this.onChangeNome}
                      name="nome"
                    />
                  </div>
                  <br />
                  <div className="group-input">
                    <b> <label htmlFor="cpf">CPF: *</label></b>
                    <InputMask
                      id="cpf"
                      placeholder="Digite o CPF"
                      className="form-control"
                      mask="999.999.999-99"
                      value={this.state.cpf}
                      onChange={this.onChangeCpf}
                    />
                  </div>
                  <br />
                  <div className="group-input">
                    <b> <label htmlFor="usuario">Email: *</label></b>
                    <input
                      type="email"
                      maxLength="100"
                      className="form-control"
                      id="usuario"
                      placeholder="Insira Um Email Válido"
                      value={this.state.usuario}
                      onChange={this.onChangeUsuario}
                      name="usuario"
                    />
                  </div>
                  <br />
                  <div className="group-input">
                    <b><label htmlFor="senha">Senha: *</label></b>
                    <input
                      type="password"
                      maxLength="100"
                      className="form-control"
                      id="senha"
                      placeholder="Insira a senha"
                      value={this.state.senha}
                      onChange={this.onChangeSenha}
                      name="senha"
                    />
                  </div>
                  <br />
                  <div className="group-input">
                    <b><label htmlFor="csenha">Confirmar Senha: *</label></b>
                    <input
                      type="password"
                      maxLength="100"
                      className="form-control"
                      id="csenha"
                      placeholder="Confirme a senha"
                      value={this.state.csenha}
                      onChange={this.onChangeCsenha}
                      name="csenha"
                    />
                  </div>
                  <br />
                  <div className="group-input">
                    <LoadCanvasTemplate />
                    <input
                      type="text"
                      id="user_captcha_input"
                      maxLength="6"
                      placeholder="Digite o codigo"
                      className="form-control"
                      style={{borderColor: 'lightgray'}}
                    />

                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      className="site-btn2 register-btn"
                      onClick={this.salvar}
                      style={{borderRadius: '5px'}}
                    >
                      Cadastrar
                    </button>
                  </div>

                  <div className="switch-login">
                    <NavLink to="/login" className="or-login">
                      Ou Fazer Login
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withCookies (FormCadastro);
