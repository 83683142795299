import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {getLoja} from '../store/actions/lojaAction';
import {getCategorias} from '../store/actions/categoriaAction';
import {getCarrinho, salvaCarrinho} from '../store/actions/carrinhoAction';
import {withCookies} from 'react-cookie';
import ApiService from '../service/ApiService';
import H from './H.js';

class Header extends Component {
  constructor () {
    super ();
    this.state = {
      user: '',
      topo: '',
      lista: [],
    };
    this.handleKeyDown = this.handleKeyDown.bind (this);
  }

  async componentDidMount () {
    try {
      window.scrollTo (0, 0);
      var user = this.props.cookies.get ('user') || '';
      this.setState ({user: user});
      ApiService.lojatopo ()
        .then (response => {
          if (response.status === 200) {
            this.setState ({topo: response.data});
          }
        })
        .catch (e => {
          ///  alert ('Erro ao recuperar idtawkto email');
          console.log (e);
        });

    

     

      this.props.getCategorias ();
      this.props.getCarrinho ();
      this.props.getLoja ();
      this.autocomplete (document.getElementById ('valor'));
    } catch (e) {
      console.error (e);
      throw new Error (e);
    }
  }

  handleKeyPress = event => {
    console.log (document.getElementById ('valor').value);
    if (document.getElementById ('valor').value.length > 0) {
      ApiService.produtopesquisa5 (document.getElementById ('valor').value)
        .then (response => {
          if (response.status === 200) {
            this.setState ({lista: response.data});
          }
          console.log (response.data);
        })
        .catch (e => {
          ///  alert ('Erro ao recuperar idtawkto email');
          console.log (e);
        });
      this.autocomplete (document.getElementById ('valor'));
    }
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      // console.log ('Enter key pressed');
      if (document.getElementById ('valor').value.length > 0) {
        window.location.href =
          '/pesquisa?nome=' + document.getElementById ('valor').value;
      }
    }
  };

  excluir (codigo, carrinho) {
    carrinho.itens = carrinho.itens.filter (it => it.codigo !== codigo);

    var total = 0;
    var subtotal = 0;
    var descontot = 0;

    subtotal = carrinho.itens.reduce (
      (prev, next) => prev + next.preco * next.quantidade,
      0
    );
    total = carrinho.itens.reduce (
      (prev, next) =>
        prev + (next.preco * next.quantidade - next.desconto * next.quantidade),
      0
    );
    descontot = carrinho.itens.reduce (
      (prev, next) => prev + next.desconto * next.quantidade,
      0
    );
    carrinho.total = total;
    carrinho.subtotal = subtotal;
    carrinho.desconto = descontot;
    //console.log (carrinho);
    this.removerCarrinho ();
    this.props.salvaCarrinho (carrinho);
  }

  removerCarrinho () {
    // Get the snackbar DIV
    var x = document.getElementById ('removercarrinho');

    // Add the "show" class to DIV
    x.className = 'show';

    // After 3 seconds, remove the show class from DIV
    setTimeout (function () {
      x.className = x.className.replace ('show', '');
    }, 3000);
  }

  autocomplete (inp) {
    console.log ('autooo');
    var arr = this.state.lista;
    console.log (arr);
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener ('input', function (e) {
      var a, b, i, val = this.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists ();
      if (!val) {
        return false;
      }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement ('DIV');
      a.setAttribute ('id', this.id + 'autocomplete-list');
      a.setAttribute ('class', 'autocomplete-items');
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild (a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (
          arr[i].substr (0, val.length).toUpperCase () === val.toUpperCase ()
        ) {
          /*create a DIV element for each matching element:*/
          b = document.createElement ('DIV');
          /*make the matching letters bold:*/
          b.innerHTML =
            '<strong>' + arr[i].substr (0, val.length) + '</strong>';
          b.innerHTML += arr[i].substr (val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener ('click', function (e) {
            /*insert the value for the autocomplete text field:*/
            inp.value = this.getElementsByTagName ('input')[0].value;
            /*close the list of autocompleted values,
                (or any other open lists of autocompleted values:*/
            closeAllLists ();
          });
          a.appendChild (b);
        }
      }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener ('keydown', function (e) {
      var x = document.getElementById (this.id + 'autocomplete-list');
      if (x) x = x.getElementsByTagName ('div');
      if (e.keyCode === 40) {
        /*If the arrow DOWN key is pressed,
          increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive (x);
      } else if (e.keyCode === 38) {
        //up
        /*If the arrow UP key is pressed,
          decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive (x);
      } else if (e.keyCode === 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault ();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click ();
        }
      }
    });
    function addActive (x) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive (x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add ('autocomplete-active');
    }
    function removeActive (x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove ('autocomplete-active');
      }
    }
    function closeAllLists (elmnt) {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName ('autocomplete-items');
      for (var i = 0; i < x.length; i++) {
        if (elmnt !== x[i] && elmnt !== inp) {
          x[i].parentNode.removeChild (x[i]);
        }
      }
      // inp.focus();
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener ('click', function (e) {
      closeAllLists (e.target);
    });
  }

  render () {
    const {loja} = this.props.loja;
    const {categorias} = this.props.categorias;

    //console.log (categorias);
    const {carrinho} = this.props.carrinho;
    if (carrinho.itens === undefined) {
      carrinho.itens = [];
    }

    if (loja.manutencao == true) {
      return <Redirect to="/manutencao" />;
    }

    return (
      <header className="header-section">
        <H />
        <div className="header-top">
          <div className="container">
            <div className="ht-left">
              <div className="mail-service">
                <i className="fa fa-envelope" />
                <a
                  rel="noopener noreferrer"
                  href={'mailto:' + loja.email}
                  style={{color: 'white'}}
                  target="_blank"
                >
                  {loja.email}
                </a>
              </div>
              <div className="phone-service">
                <i className="fa fa-phone" />
                <a
                  rel="noopener noreferrer"
                  href={'tel:' + loja.telefone}
                  style={{color: 'white'}}
                  target="_blank"
                >
                  {loja.telefone}
                </a>
              </div>
            </div>
            <div className="ht-right">

              {this.state.user !== '' &&
                <NavLink to="/conta" className="login-panel">
                  <i className="fa fa-user" />
                  Ola,
                  {' '}
                  {this.state.user.usuario}
                  {' '}
                  |
                  {' '}
                  <NavLink to="/sair" style={{color: 'white'}}>Sair</NavLink>
                </NavLink>}

              {this.state.user !== '' &&
                <NavLink to="/conta" className="login-panel">
                  <i className="fa fa-user" />
                  Minha Conta
                </NavLink>}
              {this.state.user === '' &&
                <NavLink to="/cadastrar" className="login-panel">
                  <i className="fa fa-user" />Cadastrar{' '}
                </NavLink>}
              {this.state.user === '' &&
                <NavLink to="/login" className="login-panel">
                  <i className="fa fa-user" />Entrar{' '}
                </NavLink>}

            </div>

          </div>
        </div>

        <div className="container">
          <div className="inner-header">
            <div className="row">
              <div className="col-lg-2 col-md-2">
                <div className="logo">
                  <a href="/">
                    <img
                      src={
                        this.state.topo !== ''
                          ? 'data:image/jpeg;base64,' + this.state.topo
                          : 'img/logo.png'
                      }
                      alt={'topo.png'}
                    />

                  </a>
                </div>
              </div>
              <div className="col-lg-7 col-md-7">

                <div className="advanced-search">
                  <div className="input-group autocomplete">
                    <input
                      onKeyDown={this.handleKeyDown}
                      onKeyPress={this.handleKeyPress}                     k
                      type="text"
                      id="valor"
                      required
                      placeholder="Seja bem vindo, o que você procura?"
                      style={{
                        border: '0.5px solid #00b0ef',
                        borderRadius: '4px',
                      }}
                    />
                    <button
                      type="button"
                      style={{borderRadius: '5px 5px 5px 5px'}}
                      onClick={event => {
                        if (
                          document.getElementById ('valor').value.length > 0
                        ) {
                          window.location.href =
                            '/pesquisa?nome=' +
                            document.getElementById ('valor').value;
                        }
                      }}
                    >
                      <i className="ti-search" />
                    </button>

                  </div>
                </div>
              </div>
              <div className="col-lg-3 text-right col-md-3">
              
                <ul className="nav-right">
                  <li className="cart-icon">
                  <NavLink to="/listas">
                      <i className="ti-heart" />                    
                  </NavLink>
                  </li>
                  <li className="cart-icon">
                    <NavLink to="/carrinho"><h6>Carrinho</h6> </NavLink>{' '}
                    <NavLink to="/carrinho">
                      <i className="icon_cart_alt" />
                      <span>
                        {carrinho.itens !== undefined
                          ? carrinho.itens.length
                          : 0}
                      </span>
                    </NavLink>
                    <div className="cart-hover">
                      <div className="select-items">
                        <table>
                          <tbody>
                            {carrinho.itens &&
                              carrinho.itens.map ((item, index) => (
                                <tr key={index}>
                                  <td className="si-pic">
                                    <img
                                      src={
                                        item.foto != null
                                          ? 'data:image/jpeg;base64,' +
                                              item.foto.data
                                          : 'img/lo.gif'
                                      }
                                      alt={
                                        item.foto != null
                                          ? item.foto.nome
                                          : 'img'
                                      }
                                      height="80px"
                                      width="80px"
                                    />
                                  </td>
                                  <td className="si-text">
                                    <div className="product-selected">
                                      <p>
                                        R$
                                        {' '}
                                        {item.preco.toFixed (2)}
                                        {' '}
                                        x
                                        {' '}
                                        {item.quantidade}
                                      </p>
                                      <h6>
                                        {' '}{' '}
                                        {item.nome}
                                        {item.cor != undefined
                                          ? '(' + item.cor + ')'
                                          : ''}
                                      </h6>
                                    </div>
                                  </td>
                                  <td className="si-close">
                                    <a
                                      href="#"
                                      onClick={() =>
                                        this.excluir (item.codigo, carrinho)}
                                      style={{color: 'red'}}
                                    >
                                      <i
                                        className="ti-close"
                                        style={{color: 'red'}}
                                      />
                                    </a>

                                  </td>
                                </tr>
                              ))}

                          </tbody>
                        </table>
                      </div>
                      <div className="select-total">
                        <span style={{fontWeight: 'bold'}}>total:</span>
                        <h5 style={{fontWeight: 'bold'}}>
                          R$
                          {' '}
                          {Number (
                            carrinho.total !== undefined ? carrinho.total : 0
                          ).toFixed (2)}
                        </h5>
                      </div>
                      <div className="select-button">
                        <NavLink
                          to="/carrinho"
                          className="primary-btn view-card"
                        >
                          VER CARRINHO
                        </NavLink>

                      </div>
                    </div>
                  </li>
                  <li className="cart-price">

                    R$
                    {' '}
                    {Number (
                      carrinho.total !== undefined ? carrinho.total : 0
                    ).toFixed (2)}
                  </li>
                </ul>               
              </div>
            </div>
          </div>
        </div>

        <div className="nav-item">
          <div className="container">
            <div className="nav-depart">
              <div className="depart-btn">
                <i className="ti-menu" />
                <span>Categorias</span>
                <ul className="depart-hover">
                  {categorias &&
                    categorias.map ((categoria, index) => (
                      <li className="active" key={index}>
                        <a href={'/pesquisa?categoria=' + categoria.nome}>
                          {categoria.nome}
                        </a>
                      </li>
                    ))}

                </ul>
              </div>
            </div>
            <nav className="nav-menu mobile-menu">
              <ul>
                {categorias &&
                  categorias.slice (0, 5).map ((categoria, index) => (
                    <li key={index}>
                      <a href={'/pesquisa?categoria=' + categoria.nome}>
                        {categoria.nome.substring (0, 13)}
                      </a>
                      <ul className="dropdown">
                        {categoria.sub &&
                          categoria.sub.map ((subcategoria, index) => (
                            <li key={index}>
                              <a
                                href={
                                  '/pesquisa?subcategoria=' + subcategoria.nome
                                }
                              >
                                {subcategoria.nome}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}

              </ul>
            </nav>
            <div id="mobile-menu-wrap" />
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  loja: state.loja,
  categorias: state.categorias,
  carrinho: state.carrinho,
});

const mapDispatchToProps = dispatch => ({
  getLoja: () => dispatch (getLoja ()),
  getCategorias: () => dispatch (getCategorias ()),
  getCarrinho: () => dispatch (getCarrinho ()),
  salvaCarrinho: carrinho => dispatch (salvaCarrinho (carrinho)),
});

export default connect (mapStateToProps, mapDispatchToProps) (
  withCookies (Header)
);
