import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import EmailValidator from "email-validator";
import ApiService from "../service/ApiService";
import { getLoja } from "../store/actions/lojaAction";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

const tawkTo = require("tawkto-react");

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      cores: null,
      rodape: "",
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.cadastrarnews = this.cadastrarnews.bind(this);
  }

  componentDidMount() {
    this.createscript("js/jquery-3.3.1.min.js");
    this.createscript("js/bootstrap.min.js");
    this.createscript("js/jquery-ui.min.js");
    this.createscript("js/jquery.countdown.min.js");
    this.createscript("js/jquery.nice-select.min.js");
    this.createscript("js/jquery.zoom.min.js");
    this.createscript("js/jquery.dd.min.js");
    this.createscript("js/jquery.slicknav.js");
    this.createscript("js/owl.carousel.min.js");
    this.createscript("js/main.js");

    ApiService.idtawkto()
      .then((response) => {
        if (response.status === 200) {
          tawkTo(response.data);
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });

    ApiService.lojarodape()
      .then((response) => {
        if (response.status === 200) {
          this.setState({ rodape: response.data });
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });

    ApiService.cores()
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          //
          document.querySelector(
            ".inner-header .nav-right li.cart-icon a span"
          ).style.background = response.data.corsecundaria;
          //
          document.querySelector(
            ".newslatter-item .subscribe-form button"
          ).style.background = response.data.corsecundaria;
          //
          document.querySelector(
            ".inner-header .advanced-search .input-group button"
          ).style.background = response.data.corsecundaria;
          //
          if (document.querySelector(".filter-btn") !== null) {
            document.querySelector(".filter-btn").style.background =
              response.data.corsecundaria;
          }
          //
          if (document.querySelector(".site-btn") !== null) {
            document.querySelector(".site-btn").style.background =
              response.data.corsecundaria;
          }

          document.querySelectorAll(".btn-comprar").forEach((el) => {
            el.style.background = response.data.corsecundaria;
          });
          //
          if (document.querySelector(".site-btn2") !== null) {
            document.querySelector(".site-btn2").style.background =
              response.data.corsecundaria;
          }
          //
          if (document.querySelector(".pd-cart") !== null) {
            document.querySelector(".pd-cart").style.background =
              response.data.corsecundaria;
          }
          //
          document.querySelector(".header-top").style.backgroundColor =
            response.data.corprincipal;
          //
          document.querySelector(".nav-item").style.backgroundColor =
            response.data.corprincipal;
          //
          document.querySelector(".footer-section").style.backgroundColor =
            response.data.corprincipal;
          //
          document.querySelector(
            ".nav-item .nav-depart .depart-btn"
          ).style.backgroundColor = response.data.corprincipal;
          document.querySelectorAll(".fw-title").forEach((el) => {
            el.style.color = response.data.corprincipal;
          });
          //
          document
            .querySelectorAll(
              ".nav-item .nav-depart .depart-btn .depart-hover li a"
            )
            .forEach((el) => {
              el.style.color = response.data.corprincipal;
            });
          //
          document
            .querySelectorAll(".nav-item .nav-menu li .dropdown li a")
            .forEach((el) => {
              el.style.color = response.data.corprincipal;
            });
          //

          document
            .querySelectorAll(".footer-left .footer-social a")
            .forEach((el) => {
              el.style.color = response.data.corprincipal;
            });
        }
        setTimeout(() => {
          document.querySelector("#preloder").style.visibility = "hidden";
        }, 300);
      })
      .catch((e) => {
        setTimeout(() => {
          document.querySelector("#preloder").style.visibility = "hidden";
        }, 300);
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  cadastrarnews() {
    var ok = true;

    if (!EmailValidator.validate(this.state.email)) {
      ok = false;
      alert("Email invalido");
    }

    if (ok) {
      var data = {
        email: this.state.email,
        status: "Ativo",
      };
      ApiService.newsletter(data)
        .then((response) => {
          if (response.status === 200) {
            alert("Email cadastrado com sucesso");
            // console.log (response.data);
            this.setState({ email: "" });
            window.scrollTo(0, 0);
          }
        })
        .catch((e) => {
          alert("Erro ao cadastrar email");
          console.log(e);
        });
    }
  }

  createscript(link) {
    var script = document.createElement("script");
    script.src = link;
    script.async = true;
    document.body.appendChild(script);
  }
  render() {
    const { loja } = this.props.loja;

    return (
      <footer className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-left">
                <div className="footer-logo">
                  <a href="/">
                    <img
                      src={
                        this.state.rodape !== ""
                          ? "data:image/jpeg;base64," + this.state.rodape
                          : "img/footer-logo.png"
                      }
                      alt={"rodape.png"}
                      height="70"
                      width="250"
                    />
                  </a>
                </div>
                <ul>
                  <li style={{ fontWeight: "600" }}>
                    Endereço: {loja.logradouro}, {loja.numero}, {loja.bairro}
                  </li>
                  <li style={{ fontWeight: "600" }}>
                    Cidade: {loja.cidade}/{loja.uf}
                  </li>
                  <li style={{ fontWeight: "600" }}>
                    Telefone:{" "}
                    <a
                      rel="noopener noreferrer"
                      href={"tel:" + loja.telefone}
                      style={{ color: "white" }}
                      target="_blank"
                    >
                      {loja.telefone}
                    </a>{" "}
                  </li>
                  <li style={{ fontWeight: "600" }}>
                    Email:{" "}
                    <a
                      rel="noopener noreferrer"
                      href={"mailto:" + loja.email}
                      style={{ color: "white" }}
                      target="_blank"
                    >
                      {loja.email}
                    </a>{" "}
                  </li>
                </ul>
                <div className="footer-social">
                  <a
                    href={loja.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a
                    href={loja.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                  <a
                    href={loja.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                  <a
                    href={loja.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-1">
              <div className="footer-widget">
                <h5>Informações da Loja</h5>
                <ul>
                  <li>
                    <NavLink to="/sobre">Sobre Nós</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contato">Contato</NavLink>
                  </li>
                  <li>
                    <NavLink to="/conta">Minha Conta</NavLink>
                  </li>
                  <li>
                    <NavLink to="/carrinho">Carrinho</NavLink>
                  </li>
                  <li>
                    <NavLink to="/loja">Loja</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="newslatter-item">
                <h5>Assine Nossa Newsletter</h5>
                <p>
                  Inscreva-se e enviaremos por email todas as promoções e
                  novidades!
                </p>
                <div className="subscribe-form">
                  <input
                    type="email"
                    placeholder="Digite Seu Email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    style={{ borderRadius: "5px" }}
                  />
                  <button
                    type="button"
                    onClick={this.cadastrarnews}
                    style={{ borderRadius: " 0px 5px 5px 0px" }}
                  >
                    Inscrever
                  </button>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
        {/*   <div className="copyright-reserved">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text" />
                  <div className="payment-pic">
                  <img src="img/payment-method.png" alt="" />
                </div> 
              </div>
            </div>
          </div>
        </div>*/}
        <div id="addfavorito">Adicionado aos Favoritos com Sucesso!</div>
        <div id="addcarrinho">Adicionado ao Carrinho com Sucesso!</div>
        <div id="removercarrinho">Removido do Carrinho com Sucesso!</div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  loja: state.loja,
});

const mapDispatchToProps = (dispatch) => ({
  getLoja: () => dispatch(getLoja()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Footer));
