import {Component} from 'react';
import {instanceOf} from 'prop-types';
import {withCookies, Cookies} from 'react-cookie';

class H extends Component {
  static propTypes = {
    cookies: instanceOf (Cookies).isRequired,
  };

  render () {
    return '';
  }
}

export default withCookies (H);
