import React, {Component} from 'react';
import {getLoja} from '../store/actions/lojaAction';
import {withCookies} from 'react-cookie';
import {connect} from 'react-redux';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';
import EmailValidator from 'email-validator';
import LoadingOverlay from 'react-loading-overlay';
import ApiService from '../service/ApiService';

class FormContato extends Component {
  constructor () {
    super ();
    this.state = {
      nome: '',
      email: '',
      mensagem: '',
      message2: '',
      message: '',
      isActive: false,
      textoloading: '',
    };

    this.enviar = this.enviar.bind (this);
    this.onChangeEmail = this.onChangeEmail.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeMensagem = this.onChangeMensagem.bind (this);
  }

  onChangeEmail (e) {
    this.setState ({
      email: e.target.value,
    });
  }

  onChangeNome (e) {
    this.setState ({
      nome: e.target.value,
    });
  }

  onChangeMensagem (e) {
    this.setState ({
      mensagem: e.target.value,
    });
  }

  enviar () {
    this.setState ({isActive: true});
    this.setState ({textoloading: 'Processando...'});
    var ok = true;
    if (!EmailValidator.validate (this.state.email)) {
      ok = false;
      alert ('Email Inválido!');
    }

    let user_captcha_value = document.getElementById ('user_captcha_input')
      .value;

    if (validateCaptcha (user_captcha_value) == true) {
      //alert ('Captcha Matched');
    } else {
      ok = false;
      alert ('Captcha invalido');
    }

    if (ok === false) {
      this.setState ({isActive: false});
      this.setState ({textoloading: ''});
    }

    if (ok) {
      var data = {
        nome: this.state.nome,
        email: this.state.email,
        mensagem: this.state.mensagem,
      };
      ApiService.salvarcontato (data)
        .then (response => {
          if (response.status === 200) {
            this.setState ({message: 'Mensagem enviada com sucesso!'});
            this.setState ({nome: ''});
            this.setState ({email: ''});
            this.setState ({mensagem: ''});
          }
          this.setState ({isActive: false});
		  this.setState ({textoloading: ''});
		  window.scrollTo (0, 0);
        })
        .catch (e => {
          this.setState ({isActive: false});
          this.setState ({textoloading: ''});
          this.setState ({message2: 'Erro ao enviar mensagem!'});
		  console.log (e);
		  window.scrollTo (0, 0);
        });
    }
  }

  componentDidMount () {
    loadCaptchaEnginge (6);
    window.scrollTo (0, 0);
    ApiService.lojatitulo ()
      .then (response => {
        if (response.status === 200) {
          document.title = response.data + ' | Contato';
        }
      })
      .catch (e => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log (e);
      });
  }

  render () {
    const {loja} = this.props.loja;
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="contact-section spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-title">
                  <h4>Entre em Contato</h4>
                  <p>Ficaremos felizes em falar com você!</p>
                </div>
                <div className="contact-widget">
                  <div className="cw-item">
                    <div className="ci-icon">
                      <i
                        className="ti-location-pin"
                        style={{color: '#00b0ef'}}
                      />
                    </div>
                    <div className="ci-text">
                      <span style={{fontWeight: 'bold', color: '#00b0ef'}}>
                        Endereço:
                      </span>
                      <p>
                        {loja.logradouro}
                        ,
                        {loja.numero}
                        -
                        {loja.bairro}
                        -
                        {loja.cidade}
                        /
                        {loja.uf}
                      </p>
                    </div>
                  </div>
                  <div className="cw-item">
                    <div className="ci-icon">
                      <i className="ti-mobile" style={{color: '#00b0ef'}} />
                    </div>
                    <div className="ci-text">
                      <span style={{fontWeight: 'bold', color: '#00b0ef'}}>
                        Telefone:
                      </span>
                      <p>{loja.telefone}</p>
                    </div>
                  </div>
                  <div className="cw-item">
                    <div className="ci-icon">
                      <i className="ti-email" style={{color: '#00b0ef'}} />
                    </div>
                    <div className="ci-text">
                      <span style={{fontWeight: 'bold', color: '#00b0ef'}}>
                        Email:
                      </span>
                      <p>{loja.email}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {this.state.message2 &&
                  <div className="alert alert-danger alert-dismissible">
                    {this.state.message2}
                  </div>}
                {this.state.message &&
                  <div className="alert alert-info alert-dismissible">
                    {this.state.message}
                  </div>}
                <div className="contact-form">
                  <div className="leave-comment">
                    <h4>Envie-nos uma Mensagem</h4>
                    <p>Entraremos em contato no email informado.</p>
                    <div className="comment-form">
                      <div className="row">
                        <div className="col-lg-6">
                          <input
                            maxLength="200"
                            type="text"
                            placeholder="Nome"
                            value={this.state.nome}
                            onChange={this.onChangeNome}
                            className="form-control"
                            style={{borderColor: 'lightgray'}}
                          />
                        </div>
                        <div className="col-lg-6">
                          <input
                            maxLength="200"
                            type="text"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            placeholder="Email"
                            className="form-control"
                            style={{borderColor: 'lightgray'}}
                          />
                        </div>

                        <div className="col-lg-12">
                          <textarea
                            maxLength="500"
                            value={this.state.mensagem}
                            onChange={this.onChangeMensagem}
                            placeholder="Sua Mensagem"
                            className="form-control"
                            style={{borderColor: 'lightgray'}}
                          />

                          <div className="col-lg-12">
                            <LoadCanvasTemplate />
                            <input
                              type="text"
                              id="user_captcha_input"
                              maxLength="6"
                              placeholder="Digite o codigo"
                              className="form-control"
                              style={{borderColor: 'lightgray'}}
                            />

                          </div>

                          <button
                            onClick={this.enviar}
                            className="site-btn"
                            style={{borderRadius: '5px'}}
                          >
                            Enviar Mensagem
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  loja: state.loja,
});

const mapDispatchToProps = dispatch => ({
  getLoja: () => dispatch (getLoja ()),
});

export default connect (mapStateToProps, mapDispatchToProps) (
  withCookies (FormContato)
);
