import React, {Component} from 'react';
import Header from '../componentes/Header';
import Banner from '../componentes/Banner';
import Benefits from '../componentes/Benefits';
import Content from '../componentes/Content';
import Footer from '../componentes/Footer';
import ListaCompras from '../componentes/ListaCompras';
import Bread from '../componentes/Bread';
import FormLista from '../componentes/FormLista';
import DadosLista from '../componentes/DadosLista';

class MinhaLista extends Component {


  componentDidMount () {
    window.scrollTo (0, 0);
    
  }

  render () {
    return (
      <div>
        <div id="preloder" >
          <div className="loader" />
        </div>        
        <Header />
        <Bread nome={'Minha Lista'}/>
        <DadosLista />

        <Footer />
      </div>
    );
  }
}

export default MinhaLista;
