import React, {Component} from 'react';
import Header from '../componentes/Header';
import Banner from '../componentes/Banner';
import Benefits from '../componentes/Benefits';
import Content from '../componentes/Content';
import Footer from '../componentes/Footer';

class Home extends Component {


  componentDidMount () {
    window.scrollTo (0, 0);
    
  }

  render () {
    return (
      <div>
        <div id="preloder" >
          <div className="loader" />
        </div>
        <Header />
        <Banner />
        <Benefits />
        <Content />

        <Footer />
      </div>
    );
  }
}

export default Home;
