import React, {Component} from 'react';
import { getLoja } from '../store/actions/lojaAction';
import {withCookies} from 'react-cookie';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';

class Benefits extends Component {
  render () {
    const{loja}=this.props.loja;
    return (
      <div className="benefit-items">
        <div className="row" style={{justifySelf:"center"}}>
          <div className="col-lg-3 col-md-3 col-sm-6 ">
            <div className="single-benefit">
              <div className="sb-icon">
                <img src="img/frete.png" alt="" />
              </div>
              <div className="sb-text">
                <h6>Frete Grátis</h6>
                <p>consulte condições</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="single-benefit">
              <div className="sb-icon">
                <img src="img/parcelamento.png" alt="" />
              </div>
              <div className="sb-text">
                <h6>Parcelamento</h6>
                <p>em até {loja.maxparcelas===undefined?1:loja.maxparcelas}x sem juros</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="single-benefit">
              <div className="sb-icon">
                <img src="img/entrega.png" alt="" />
              </div>
              <div className="sb-text">
                <h6>Entrega</h6>
                <p>em todo o Brasil</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
          <NavLink to="/desconto-do-dia">
            <div className="single-benefit">
              <div className="sb-icon">
                <img src="img/descontos.png" alt="" />
              </div>
              <div className="sb-text">
                <h6>Descontos</h6>
                <p>descontos todo dia</p>
              </div>
            </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loja: state.loja,
});

const mapDispatchToProps = dispatch => ({
  getLoja: () => dispatch (getLoja ()),
});

export default connect (mapStateToProps, mapDispatchToProps) (
  withCookies (Benefits)
);

