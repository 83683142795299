import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {salvaCarrinho} from '../store/actions/carrinhoAction';
import ApiService from '../service/ApiService';
import Pagination from 'react-js-pagination';
import { withCookies } from "react-cookie";

class FormLoja extends Component {
  constructor () {
    super ();
    this.state = {
      imgsd: [],
      imgs: [],
      produtos: [],   
      qtd:10,
      ordem:'menor',    
      activePage: 1,
      totalPages: null,
      itemsCountPerPage: null,
      totalItemsCount: null,
      user:''
    };
    this.handlePageChange = this.handlePageChange.bind (this);
    this.onChangeOrdem = this.onChangeOrdem.bind (this);
    this.onChangeQtd = this.onChangeQtd.bind (this);
    this.fetchURL = this.fetchURL.bind (this);
  }

  fetchURL (page,qtd,ordem) {
    ApiService.produtospage (page,qtd,ordem)
      .then (response => {
        if (response.status === 200) {
          const totalPages = response.data.totalPages;
          const itemsCountPerPage = response.data.size;
          const totalItemsCount = response.data.totalElements;




          this.setState ({totalPages: totalPages});
          this.setState ({totalItemsCount: totalItemsCount});
          this.setState ({itemsCountPerPage: itemsCountPerPage});
          this.setState ({produtos: response.data.content});

          console.log(response.data);
        }
      })
      .catch (e => {
        console.log (e);
      });
  }

  handlePageChange (pageNumber) {
    console.log (`active page is ${pageNumber}`);
    this.setState ({activePage: pageNumber});
    this.fetchURL (pageNumber-1,this.state.qtd,this.state.ordem);
    window.scrollTo (0, 0);
  }

  async pegarimgd (produtosd, index2) {
    console.log ('produtod... ' + produtosd[index2].nome);
    ApiService.fotoproduto (produtosd[index2].id)
      .then (response => {
        if (response.status === 200) {
          this.state.imgsd[index2] = response.data.data;
          // console.log ('pppp' + this.state.imgsd[index2]);
          this.setState ({imgsd: this.state.imgsd});
        }
      })
      .catch (e => {
        console.log (e);
      });
  }

  componentDidMount () {
    try {
      //  window.scrollTo (0, 0);
      ApiService.lojatitulo ()
      .then (response => {
        if (response.status === 200) {
          document.title = response.data;
        }
      })
      .catch (e => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log (e);
      });
      this.fetchURL (this.state.activePage-1,this.state.qtd,this.state.ordem);
      var user = this.props.cookies.get("user") || "";
      this.setState({ user: user });
    } catch (e) {
      console.error (e);
      throw new Error (e);
    }
  }

  adicionaritem (preco, desconto, promocao, nome, codigo, index, carrinho) {
    try {
      var item = {};
      item.quantidade = 1;
      item.preco = Number (preco);
      item.promocao = Number (promocao);
      item.desconto = Number (desconto);
      item.nome = nome;
      item.codigo = codigo;
      item.foto = {};
      item.foto.data = this.state.imgsd[index];
      item.valormontagem=0;

      if (carrinho.itens === undefined) {
        carrinho.itens = [];
        carrinho.total = 0;
      }
      var tem = false;
      var pos = 0;
      for (var i = 0; i < carrinho.itens.length; i++) {
        var it = carrinho.itens[i];
        if (it.codigo === codigo) {
          tem = true;
          break;
        }
        pos++;
      }
      if (tem === false) {
        carrinho.itens.push (item);
      } else {
        carrinho.itens[pos].quantidade += 1;
      }

      var total = 0;
      var subtotal = 0;
      var descontot = 0;
      var montagem=0;

      montagem = carrinho.itens.reduce(
        (prev, next) => prev + next.valormontagem,
        0
      );

      subtotal = carrinho.itens.reduce (
        (prev, next) => prev + next.preco * next.quantidade,
        0
      );
      total = carrinho.itens.reduce (
        (prev, next) =>
          prev +
          (next.preco * next.quantidade - next.desconto * next.quantidade),
        0
      );
      descontot = carrinho.itens.reduce (
        (prev, next) => prev + next.desconto * next.quantidade,
        0
      );
      carrinho.total = total+montagem;
      carrinho.subtotal = subtotal;
      carrinho.desconto = descontot;
      carrinho.valormontagem=montagem;
      //console.log (carrinho);
      this.props.salvaCarrinho (carrinho);
      //  window.scrollTo (0, 0);
      this.addCarrinho ();
      localStorage.setItem("itenscarrinho", 1);
    } catch (error) {
      console.log (error);
    }
  }

  addCarrinho () {
    // Get the snackbar DIV
    var x = document.getElementById ('addcarrinho');

    // Add the "show" class to DIV
    x.className = 'show';

    // After 3 seconds, remove the show class from DIV
    setTimeout (function () {
      x.className = x.className.replace ('show', '');
    }, 3000);
  }

  onChangeOrdem (e) {
    this.setState ({
      ordem: e.target.value,
    });
    this.fetchURL (this.state.activePage-1,this.state.qtd,e.target.value);
  }

  onChangeQtd (e) {
    this.setState ({
      qtd: e.target.value,
    });
    console.log(e.target.value);
    this.fetchURL (this.state.activePage-1,e.target.value,this.state.ordem);
  }

  adicionarfavorito(pp) {
    console.log("favorito ");
    console.log(pp);
    console.log(this.state.user);
    // this.props.salvafavorito(pp);
    //window.scrollTo (0, 0);
    if (this.state.user !== "") {
      var data = {};
      data.email = this.state.user.usuario;
      var prod = {};
      prod.id = pp.id;
      data.produto = prod;

      console.log(data.email);
      console.log(data.produto);

      ApiService.salvaritemlista(data)
        .then((response) => {
          if (response.status === 200) {
            this.addfavorito();
          }
        })
        .catch((e) => {
          //this.setState ({message: 'Erro ao recuperar listas'});
          console.log(e);
        });
    }
  }

  addfavorito() {
    // Get the snackbar DIV
    var x = document.getElementById("addfavorito");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  render () {
    const {categorias} = this.props.categorias;

    // const {produtos} = this.props.produtos;
    const {carrinho} = this.props.carrinho;
    return (
      <section className="product-shop spad">

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-8 order-2 order-lg-1 produts-sidebar-filter">
              <div className="filter-widget">
                <h4 className="fw-title">Categorias</h4>
                <ul className="filter-catagories">

                  {categorias &&
                    categorias.map ((categoria, index) => (
                      <li key={index}>
                        {' '}<a href={'/pesquisa?categoria=' + categoria.nome}>
                          {categoria.nome}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="filter-widget">
                <h4 className="fw-title">Filtrar Por Preço:</h4>
                <div className="filter-range-wrap">
                  <div className="range-slider">
                    <div className="price-input">
                      <input type="text" id="minamount" />
                      <input type="text" id="maxamount" />
                    </div>
                  </div>
                  <div
                    className="price-range ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                    data-min="10"
                    data-max="99"
                  >
                    <div className="ui-slider-range ui-corner-all ui-widget-header" />
                    <span
                      tabIndex="0"
                      className="ui-slider-handle ui-corner-all ui-state-default"
                      style={{left: '0%'}}
                    />
                    <span
                      tabIndex="0"
                      className="ui-slider-handle ui-corner-all ui-state-default"
                      style={{left: '100%'}}
                    />
                    <div
                      className="ui-slider-range ui-corner-all ui-widget-header"
                      style={{left: '0%', width: '100%'}}
                    />
                  </div>
                </div>
                <a href="#" className="filter-btn">Filtrar</a>
              </div>
            </div>
            <div className="col-lg-9 order-1 order-lg-2">
              <div className="product-show-option">
                <div className="row">
                  <div className="col-lg-7 col-md-7">

                    <label style={{fontWeight:'bold'}}>Ordenar por: </label>&nbsp;
                    <select  value={this.state.ordem}
                        onChange={this.onChangeOrdem}>
                      <option value="menor">Menor Preço</option>
                      <option value="maior">Maior Preço</option>
                      <option value="ab">Nome Crescente</option>
                      <option value="ba">Nome Decrescente</option>
                    </select>
                    &nbsp;&nbsp;&nbsp;
                    <label style={{fontWeight:'bold'}}>Total:</label>&nbsp;
                    <select    value={this.state.qtd}
                        onChange={this.onChangeQtd}>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>

                  </div>
                  <div className="col-lg-5 col-md-5 text-right">
                    <p>Mostrando {this.state.itemsCountPerPage} de {this.state.totalItemsCount} Produtos</p>
                  </div>
                </div>
              </div>
              <div className="product-list">
                <div className="row">

                  {this.state.produtos &&
                    this.state.produtos.map ((produto, index2) => (
                      <div key={index2} className="col-lg-4 col-sm-6">
                        <div className="product-item">
                          <div className="pi-pic">
                              <div className="ht2">                                  
                                <i className="icon_heart" style={{color:"#ff5252"}}
                                 onClick={() =>
                                  this.adicionarfavorito(produto)
                                }></i>                                    
                              </div>
                            <NavLink
                              to={`/produto?i=${produto.id}&n=${produto.nome.replaceAll (' ', '-')}`}
                            >
                              <img
                                src={
                                  this.state.imgsd[index2] != null
                                    ? 'data:image/jpeg;base64,' +
                                        this.state.imgsd[index2]
                                    : '/img/lo.gif'
                                }
                                alt={
                                  produto.foto != null
                                    ? produto.foto.nome
                                    : 'img'
                                }
                                onLoad={() =>
                                  this.pegarimgd (this.state.produtos, index2)}
                                class="border"
                                widht="80px"
                                height="200px"
                              />
                            </NavLink>
                            {produto.desconto > 0 &&
                              <div className="sale2">
                                {produto.descontop}% OFF
                              </div>
                              }

                          </div>
                          <div className="pi-text">
                            <div className="catagory-name">
                              {produto.subcategoria.categoria.nome}
                            </div>
                            <a href="#">
                              <h5
                                style={{
                                  fontWeight: '700',
                                  fontSize: '12px',
                                  height: '40px',
                                  textTransform: 'uppercase',
                                }}
                              >
                                {produto.nome}
                              </h5>
                            </a>
                            <h6
                              style={{
                                fontWeight: '600',
                                marginTop: '5px',
                                color: '#00b0ef',
                              }}
                            >
                              {produto.laboratorio}
                            </h6>
                            <div
                              className="product-price"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {produto.desconto > 0 &&
                                <span style={{marginRight: '4px'}}>
                                  {' '}
                                  R$
                                  {produto.preco.toFixed (2)}
                                </span>}
                              R$
                              {produto.desconto > 0
                                ? (produto.preco - produto.desconto).toFixed (2)
                                : produto.preco.toFixed (2)}
                              <h1
                                style={{fontSize: '10px', marginLeft: '2.5px'}}
                              >
                                {' '}cada
                              </h1>
                            </div>
                            {produto.estoque === 0 &&
                              <a
                              href={`/produto?i=${produto.id}&n=${produto.nome.replaceAll (' ', '-')}`}
                            >
                              <button
                                style={{
                                  backgroundColor: 'gray' /* Green */,
                                  border: 'none',
                                  color: 'white',
                                  padding: '10px 27px',
                                  textAlign: 'center',
                                  textDecoration: 'none',
                                  display: 'inline-block',
                                  fontSize: '16px',
                                  borderRadius: '10px 10px 10px 10px',
                                }}
                              >
                                <i className="icon_bag_alt" />{' '}AVISE-ME
                              </button></a>}

                            {produto.estoque > 0 &&
                              <button   className='btn-comprar'
                                onClick={() =>
                                  this.adicionaritem (
                                    produto.preco,
                                    produto.desconto,
                                    produto.promocao,
                                    produto.nome,
                                    produto.id,
                                    index2,
                                    carrinho
                                  )}
                                style={{
                                  backgroundColor: '#4CAF50' /* Green */,
                                  border: 'none',
                                  color: 'white',
                                  padding: '10px 27px',
                                  textAlign: 'center',
                                  textDecoration: 'none',
                                  display: 'inline-block',
                                  fontSize: '16px',
                                  borderRadius: '10px 10px 10px 10px',
                                }}
                              >
                                <i className="icon_bag_alt" />{' '}COMPRAR
                              </button>}
                          </div>
                        </div>
                      </div>
                    ))}

                </div>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  hideNavigation
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  pageRangeDisplayed={10}
                  itemClass="page-item"
                  linkClass="btn btn-light"
                  onChange={this.handlePageChange}
                />
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  categorias: state.categorias,
  carrinho: state.carrinho,
});
const mapDispatchToProps = dispatch => ({
  salvaCarrinho: carrinho => dispatch (salvaCarrinho (carrinho)),
});

export default connect (mapStateToProps, mapDispatchToProps) (withCookies(FormLoja));
