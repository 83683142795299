import {GET_PRODUTOS,GET_CPRODUTOS, PRODUTOS_ERROR, GET_PRODUTO, GET_FOTOS,GET_FOTO,GET_PESQUISA,GET_PRODUTOSD,GET_PRODUTOSC} from '../types';
import ApiService from '../../service/ApiService';

export const getCProdutos = () => async dispatch => {
  try {
    const res = await ApiService.cprodutosp ();

    dispatch ({
      type: GET_CPRODUTOS,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
   // throw new Error (e);
  }
};

export const getProdutos = () => async dispatch => {
  try {
    const res = await ApiService.produtosp ();

    dispatch ({
      type: GET_PRODUTOS,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
   // throw new Error (e);
  }
};

export const getProdutosd = () => async dispatch => {
  try {
    const res = await ApiService.produtospd ();

    dispatch ({
      type: GET_PRODUTOSD,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
   // throw new Error (e);
  }
};

export const getProdutosc  = idf => async dispatch => {
  try {
    const res = await ApiService.produtospc (idf);

    dispatch ({
      type: GET_PRODUTOSC,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
   // throw new Error (e);
  }
};

export const getProduto = id => async dispatch => {
  try {
    const resx = await ApiService.produto (id);
    dispatch ({
      type: GET_PRODUTO,
      payload: resx.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
  //  throw new Error (e);
  }
};

export const getFotos = idf => async dispatch => {
  try {
    const resf = await ApiService.fotosproduto (idf);

    dispatch ({
      type: GET_FOTOS,
      payload: resf.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
 //   throw new Error (e);
  }
};

export const getFoto= idf => async dispatch => {
  try {
    const resf = await ApiService.fotoproduto (idf);

    dispatch ({
      type: GET_FOTO,
      payload: resf.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
    //throw new Error (e);
  }
};


export const getPesquisa = (data) => async dispatch => {
  try {
    const res = await ApiService.produtopesquisa (data);

    dispatch ({
      type: GET_PESQUISA,
      payload: res.data,
    });
  } catch (e) {
    dispatch ({
      type: PRODUTOS_ERROR,
      payload: console.log (e),
    });
    console.error (e);
   // throw new Error (e);
  }
};

