import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getCarrinho, salvaCarrinho } from "../store/actions/carrinhoAction";
import { withCookies } from "react-cookie";
import ApiService from "../service/ApiService";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import LoadingOverlay from "react-loading-overlay";
import { getLoja } from "../store/actions/lojaAction";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./util";

class FormPagamento extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      textoloading: "",
      nome: "",
      cpf: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      email: "",
      telefone: "",
      message: "",
      erros: [],
      submitted: false,
      selectedOption: "cartaocredito",
      submitted2: false,
      submitted4: false,
      submitted5: false,
      pedidon: "",
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",
      issuer: "",
      focused: "",
      formData: null,
      parcelamento: [],
      taxa: 0,
      parcela: "",
      visa: "",
      mastercard: "",
      american: "",
      hiper: "",
      cabal: "",
      diners: "",
      elo: "",
      pix: "",
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeParcela = this.onChangeParcela.bind(this);
  }

  onChangeParcela(e) {
    this.setState({
      parcela: e.target.value,
    });
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  componentDidMount() {
    this.props.getLoja();
    ApiService.lojatitulo()
      .then((response) => {
        if (response.status === 200) {
          document.title = response.data + " | Pagamento";
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
    var user = this.props.cookies.get("user") || "";
    if (user === "") {
      this.setState({ submitted2: true });
    }

    var cli = localStorage.getItem("cliente");
    if (cli === null) {
      cli = undefined;
    }
    if (cli !== undefined) {
      var cli2 = JSON.parse(cli);
      console.log(cli2);
      this.setState({ nome: cli2.nome });
      this.setState({ cpf: cli2.cpfcnpj });
      this.setState({ cep: cli2.cep });
      this.setState({ logradouro: cli2.logradouro });
      this.setState({ numero: cli2.numero });
      this.setState({ complemento: cli2.complemento });
      this.setState({ uf: cli2.uf });
      this.setState({ cidade: cli2.cidade });
      this.setState({ bairro: cli2.bairro });
      this.setState({ logradouro: cli2.logradouro });
      this.setState({ telefone: cli2.telefone });
      this.setState({ email: user.email });
    }

    var ic = localStorage.getItem("itenscarrinho");
    console.log('itens carrinho '+ic)
    if (ic == '0') {
      this.setState({
        submitted5: true,
      });
    }
    console.log('itens carrinho '+this.state.submitted5);
    
    var total = localStorage.getItem("totalcarrinho");
    console.log("xxxx" + total);
    var maxparcelas = localStorage.getItem("maxparcelas");
    console.log(maxparcelas);
    var maxparcelas2 = localStorage.getItem("maxparcelas2");
    console.log(maxparcelas2);
    var taxa = localStorage.getItem("taxa");
    console.log(taxa);
    var valorminimo = localStorage.getItem("valorminimo");
    console.log(valorminimo);
    if (total !== undefined) {
      total = Number(total);
    }
    if (maxparcelas !== undefined) {
      maxparcelas = Number(maxparcelas);
    }
    if (valorminimo !== undefined) {
      valorminimo = Number(valorminimo);
    }
    if (maxparcelas2 !== undefined) {
      maxparcelas2 = Number(maxparcelas2);
    }
    if (taxa !== undefined) {
      taxa = Number(taxa);
    }

    this.state.parcelamento = [];
    var p = {};
    p.parcela = 1;
    p.valor = total;
    p.tipo = "sem juros";
    this.state.parcela =
      p.parcela + "x" + Number(p.valor).toFixed(2) + " sem juros";
    this.state.parcelamento.push(p);

    if (total < valorminimo) {
      this.state.parcelamento = [];
      p = {};
      p.parcela = 1;
      p.valor = total;
      p.tipo = "sem juros";
      this.state.parcela =
        p.parcela + "x" + Number(p.valor).toFixed(2) + " sem juros";
      this.state.parcelamento.push(p);
    }
    if (total > valorminimo) {
      this.state.parcelamento = [];
      for (var i = 1; i <= maxparcelas; i++) {
        p = {};
        p.parcela = i;
        p.valor = Number(total / i).toFixed(2);
        p.tipo = "sem juros";
        if (i === 1) {
          this.state.parcela =
            p.parcela + "x" + Number(p.valor).toFixed(2) + " sem juros";
          this.state.parcelamento.push(p);
        } else {
          if (p.valor >= valorminimo) {
            this.state.parcelamento.push(p);
          }
        }
      }
      if (taxa > 0) {
        var pos = 1;
        for (var i = maxparcelas + 1; i <= maxparcelas2; i++) {
          p = {};
          p.parcela = i;

          var totald = Number(total) * (Number(taxa) / 100.0) * pos;
          var totalc = Number(total + totald).toFixed(2);
          p.valor = Number(totalc / i).toFixed(2);
          p.tipo = "com juros(" + totalc + ")";

          if (p.valor >= valorminimo) {
            this.state.parcelamento.push(p);
          }
          pos++;
        }
      }
    }
  }

  handleOptionChange(evt) {
    this.setState({
      selectedOption: evt.target.value,
    });
  }

  async salvar(carrinho, loja) {
    this.setState({ isActive: true });
    this.setState({ textoloading: "Processando seu pedido..." });
    var ok = true;

    var pedido = {};

    if (loja.tipointegracao == "cielo" || loja.tipointegracao == "rede" || loja.tipointegracao == "getnet") {
      pedido.bandeira = "master";
      if (
        document.getElementsByClassName("rccs__card rccs__card--visa").length >
        0
      ) {
        pedido.bandeira = "visa";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--elo").length > 0
      ) {
        pedido.bandeira = "elo";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--hipercard")
          .length > 0
      ) {
        pedido.bandeira = "hipercard";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--amex").length >
        0
      ) {
        pedido.bandeira = "amex";
      }
      if (
        document.getElementsByClassName("rccs__card rccs__card--dinersclub")
          .length > 0
      ) {
        pedido.bandeira = "diners";
      }
    }

    pedido.email = this.state.email;
    pedido.telefone = this.state.telefone
      .replace("_", "")
      .replace("-", "")
      .replace("(", "")
      .replace(")", "");
    pedido.nome = this.state.nome;
    pedido.cpfcnpj = this.state.cpf
      .replace("-", "")
      .split(".")
      .join("")
      .replace("_", "");
    pedido.cep = this.state.cep
      .replace("-", "")
      .split(".")
      .join("")
      .replace("_", "");
    pedido.logradouro = this.state.logradouro;
    pedido.numero = this.state.numero;
    pedido.bairro = this.state.bairro;
    pedido.cidade = this.state.cidade;
    pedido.uf = this.state.uf;
    pedido.complemento = this.state.complemento;
    pedido.status = "Processando";
    pedido.tipopagamento = this.state.selectedOption;
    pedido.datapedido = new Date();
    pedido.subtotal = carrinho.subtotal;
    pedido.desconto = carrinho.desconto;
    pedido.frete = carrinho.frete;
    pedido.servico = carrinho.servico;
    pedido.prazo = carrinho.prazo;
    pedido.valormontagem = carrinho.valormontagem;
    pedido.total = carrinho.total;
    pedido.despesa = 0;
    //
    if (pedido.tipopagamento === "cartaocredito") {
      pedido.number = this.state.number;
      pedido.cvc = this.state.cvc;
      pedido.expiry = this.state.expiry;
      pedido.parcela = this.state.parcela;

      if (pedido.parcela.includes("com juros")) {
        var oo = pedido.parcela.split("com juros");
        var voo = oo[1].replaceAll("(", "").replaceAll(")", "");
        var totaldesp = Number(voo) - Number(carrinho.total);
        pedido.despesa = Number(totaldesp).toFixed(2);
        pedido.total = Number(carrinho.total) + Number(pedido.despesa);
      }
    }
    console.log(pedido);
    //
    pedido.itens = [];
    console.log(carrinho.itens);
    for (var i = 0; i < carrinho.itens.length; i++) {
      var itemx = carrinho.itens[i];
      console.log(itemx);
      var pedidoitem = {};
      pedidoitem.nome = itemx.nome;
      pedidoitem.quantidade = itemx.quantidade;
      pedidoitem.desconto = itemx.desconto;
      pedidoitem.valor = itemx.preco;
      pedidoitem.despesa = 0;
      pedidoitem.frete = 0;
      pedidoitem.cor = itemx.cor;
      pedidoitem.produto = {};
      pedidoitem.valormontagem = itemx.valormontagem;
      pedidoitem.produto.id = itemx.codigo;
      pedidoitem.total =
        Number(itemx.preco) * Number(itemx.quantidade) -
        Number(itemx.desconto) * Number(itemx.quantidade) +
        Number(itemx.valormontagem);
      console.log(pedidoitem);
      pedido.itens.push(pedidoitem);
    }

    var erros = "";

    if (pedido.tipopagamento === "cartaocredito") {
      var valid = require("card-validator");

      var card = null;

      if (
        this.state.number.trim().startsWith("37") ||
        this.state.number.trim().startsWith("34")
      ) {
        card = {
          card: "american-express",
          isPotentiallyValid: true,
          isValid: true,
        };
      }
      console.log(this.state.number.trim());
      console.log(this.state.number.trim().startsWith("37"));
      console.log(this.state.cvc.trim());
      console.log(card);

      var numberValidation = valid.number(this.state.number.trim());
      console.log(numberValidation);
      /*if (!numberValidation.isPotentiallyValid) {
        ok = false;
        erros += 'Número do Cartão de Crédito Inválido! ';
      }*/

      var datacartao = valid.expirationDate(this.state.expiry.trim());
      if (!datacartao.isPotentiallyValid) {
        ok = false;
        erros += "Data de Validade do Cartão de Crédito Inválido!";
      }

      var tamcvv = 3;
      if (
        this.state.number.trim().startsWith("37") ||
        this.state.number.trim().startsWith("34")
      ) {
        tamcvv = 4;
      }

      var cvc = valid.cvv(this.state.cvc.trim(), this.state.cvc.length);
      if (!cvc.isPotentiallyValid) {
        ok = false;
        erros += "CVC do Cartão de Crédito Inválido!";
      }

      if (this.state.name.length < 0) {
        ok = false;
        erros += "Nome do Cartão de Crédito Inválido!";
      }
    }

    if (
      pedido.cep.replace("-", "").split(".").join("").replace("_", "")
        .length === 0
    ) {
      ok = false;
      erros += "CEP Inválido!";
    }

    if (pedido.logradouro.length === 0) {
      ok = false;
      erros += "Logradouro Inválido!,";
    }

    if (pedido.nome.length === 0) {
      ok = false;
      erros += "Nome Inválido!,";
    }
    if (pedido.cpfcnpj.length === 0) {
      ok = false;
      erros += "CPF Inválido!,";
    }

    if (this.state.selectedOption === "cartaocredito") {
      if (this.state.number.replaceAll(" ", "").length < 14) {
        ok = false;
        erros += "Número do Cartão de Crédito Inválido! ";
      }

      if (this.state.name.trim().length === 0) {
        ok = false;
        erros += "Nome Titular do Cartão de Crédito Inválido! ";
      }

      if (this.state.expiry.replaceAll("/", "").length !== 4) {
        ok = false;
        erros += "Validade do Cartão de Crédito Inválido! ";
      }

      /* if (this.state.cvc.length !== tamcvv) {
        ok = false;
        erros += 'CVC do Cartão de Crédito Inválido! ';
      }*/
    }

    if (ok === false) {
      window.scrollTo(0, 0);
      this.setState({ isActive: false });
      this.setState({ textoloading: "" });
      this.setState({ message: erros });
    }

    if (ok) {
      await ApiService.salvarpedido(pedido)
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("itenscarrinho", 0);
            carrinho.itens = [];
            carrinho.total = 0;
            carrinho.frete = 0;
            carrinho.servico = 0;
            carrinho.prazo = 0;
            carrinho.valormontagem = 0;
            this.props.salvaCarrinho(carrinho);
            this.setState({ pedidon: response.data.numeropedido });
            if (response.data.status == "Pix") {
              this.setState({
                submitted4: true,
              });
            } else {
              this.setState({
                submitted: true,
              });
            }
            this.setState({ isActive: false });
            this.setState({ textoloading: "" });
          }
        })
        .catch((e) => {
          this.setState({ isActive: false });
          this.setState({ textoloading: "" });
          this.setState({ message: "Erro ao Salvar Pedido" });
          console.log(e);
        });
    }
  }

  render() {
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=pagamento" />;
    }

    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to={"/confirmacao?numero=" + this.state.pedidon} />;
    }

    const submitted4 = this.state.submitted4;
    if (submitted4 === true) {
      return <Redirect to={"/pix?numero=" + this.state.pedidon} />;
    }

    const submitted5 = this.state.submitted5;
    if (submitted5 === true) {
      return <Redirect to={"/carrinho"} />;
    }

    const { carrinho } = this.props.carrinho;
    const { loja } = this.props.loja;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="checkout-section spad">
          <div className="container">
            <div className="checkout-form">
              {this.state.message && (
                <div className="alert alert-danger alert-dismissible">
                  {this.state.message}
                </div>
              )}
              <div className="row">
                <div className="col-lg-4" style={{ marginBottom: "25px" }}>
                  <div className="place-order">
                    <h4>Seu Pedido</h4>
                    <div className="order-total">
                      <ul className="order-table">
                        <li>
                          Produtos <span>Total</span>
                        </li>

                        {carrinho.itens &&
                          carrinho.itens.map((item, index) => (
                            <li
                              key={index}
                              className="fw-normal"
                              style={{ textTransform: "uppercase" }}
                            >
                              {item.nome}
                              {item.cor != undefined
                                ? "(" + item.cor + ")"
                                : ""}
                              {" x "} {item.quantidade}
                              <span>
                                R${" "}
                                {Number(item.preco * item.quantidade).toFixed(
                                  2
                                )}
                              </span>
                            </li>
                          ))}
                        <li
                          className="fw-normal"
                          style={{ fontWeight: "bold" }}
                        >
                          Subtotal{" "}
                          <span>R$ {Number(carrinho.subtotal).toFixed(2)}</span>
                        </li>
                        <li
                          className="fw-normal"
                          style={{ fontWeight: "bold" }}
                        >
                          Frete{" "}
                          <span>R$ {Number(carrinho.frete).toFixed(2)}</span>
                        </li>
                        <li
                          className="fw-normal"
                          style={{ fontWeight: "bold", color: "red" }}
                        >
                          Desconto{" "}
                          <span>R$ {Number(carrinho.desconto).toFixed(2)}</span>
                        </li>
                        {loja.segmento == 11 && (
                          <li
                            className="fw-normal"
                            style={{ fontWeight: "bold" }}
                          >
                            Montagem{" "}
                            <span>
                              R$ {Number(carrinho.valormontagem).toFixed(2)}
                            </span>
                          </li>
                        )}
                        <li
                          className="total-price"
                          style={{ color: "#00b0ef", fontSize: "18px" }}
                        >
                          Total{" "}
                          <span>R$ {Number(carrinho.total).toFixed(2)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <h4>Detalhes da Entrega</h4>
                  <div className="row">
                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="fir"
                      >
                        NOME:&nbsp;
                        <span />
                      </label>
                      <label
                        id="fir"
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.nome}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="cun-name"
                      >
                        CPF:&nbsp;
                      </label>
                      <label
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.cpf}
                      </label>
                    </div>
                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="zip"
                      >
                        CEP:&nbsp;
                      </label>
                      <label
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.cep}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="street"
                      >
                        ENDEREÇO:&nbsp;
                        <span />
                      </label>
                      <label
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.logradouro}
                        ,&nbsp;
                        {this.state.numero}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="street"
                      >
                        BAIRRO:&nbsp;
                        <span />
                      </label>
                      <label
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.bairro}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="street"
                      >
                        COMP:&nbsp;
                        <span />
                      </label>
                      <label
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.complemento}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="town"
                      >
                        CIDADE E UF:&nbsp;
                        <span />
                      </label>
                      <label
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.cidade},&nbsp;{this.state.uf}
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="phone"
                      >
                        TELEFONE:&nbsp;
                        <span />
                      </label>
                      <label
                        style={{ textTransform: "uppercase", fontSize: "15px" }}
                      >
                        {this.state.telefone}
                      </label>
                    </div>
                    <div className="col-lg-12">
                      <label
                        style={{
                          fontWeight: "bold",
                          width: "100px",
                          fontSize: "15px",
                        }}
                        htmlFor="phone"
                      >
                        EMAIL:&nbsp;
                        <span />
                      </label>
                      <label
                        style={{  fontSize: "15px" }}
                      >
                        {this.state.email}
                      </label>
                    </div>
                  </div>
                  <br />
                </div>

                <div className="col-lg-4">
                  <div className="place-order">
                    <h4>Pagamento</h4>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        value="cartaocredito"
                        checked={this.state.selectedOption === "cartaocredito"}
                        onChange={(evt) => this.handleOptionChange(evt)}
                        id="defaultGroupExample1"
                        name="groupOfDefaultRadios"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="defaultGroupExample1"
                      >
                        Cartão de Crédito
                      </label>
                      <br />
                      <img
                        src={
                          this.state.visa !== ""
                            ? "data:image/jpeg;base64," + this.state.visa
                            : "img/visa.png"
                        }
                        alt={"visa"}
                        height="30px"
                        width="45px"
                      />
                      <img
                        src={
                          this.state.mastercard !== ""
                            ? "data:image/jpeg;base64," + this.state.mastercard
                            : "img/mastercard.png"
                        }
                        alt={"mastercard"}
                        height="30px"
                        width="45px"
                      />
                      <img
                        src={
                          this.state.american !== ""
                            ? "data:image/jpeg;base64," + this.state.american
                            : "img/american-express.png"
                        }
                        alt={"american-express"}
                        height="30px"
                        width="45px"
                      />
                      <img
                        src={
                          this.state.hiper !== ""
                            ? "data:image/jpeg;base64," + this.state.hiper
                            : "img/hiper.png"
                        }
                        alt={"hiper"}
                        height="30px"
                        width="45px"
                      />
                      <img
                        src={
                          this.state.cabal !== ""
                            ? "data:image/jpeg;base64," + this.state.cabal
                            : "img/cabal.png"
                        }
                        alt={"cabal"}
                        height="30px"
                        width="45px"
                      />
                      <img
                        src={
                          this.state.diners !== ""
                            ? "data:image/jpeg;base64," + this.state.diners
                            : "img/diners.png"
                        }
                        alt={"diners"}
                        height="30px"
                        width="45px"
                      />
                      <img
                        src={
                          this.state.elo !== ""
                            ? "data:image/jpeg;base64," + this.state.elo
                            : "img/elo.png"
                        }
                        alt={"elo"}
                        height="30px"
                        width="45px"
                      />
                    </div>
                    {loja.tipointegracao === "esitef" && (
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          value="pix"
                          checked={this.state.selectedOption === "pix"}
                          onChange={(evt) => this.handleOptionChange(evt)}
                          id="defaultGroupExample2"
                          name="groupOfDefaultRadios"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="defaultGroupExample2"
                        >
                          Pix
                        </label>
                        <br />
                        <img
                          src={
                            this.state.pix !== ""
                              ? "data:image/jpeg;base64," + this.state.pix
                              : "img/pix.png"
                          }
                          alt={"pix"}
                          height="30px"
                          width="45px"
                        />
                      </div>
                    )}
                    <br />
                    {this.state.selectedOption === "cartaocredito" && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Cards
                            cvc={this.state.cvc}
                            expiry={this.state.expiry}
                            focused={this.state.focus}
                            name={this.state.name}
                            number={this.state.number}
                            placeholders={{ name: "" }}
                          />
                        </div>
                        &nbsp;
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <label
                              style={{ fontWeight: "bold" }}
                              htmlFor="town"
                            >
                              Número do Cartão<span>*</span>:
                            </label>
                            <input
                              type="tel"
                              name="number"
                              className="form-control"
                              placeholder="**** **** **** ****"
                              pattern="[\d| ]{16,22}"
                              required
                              onChange={this.handleInputChange}
                              onFocus={this.handleInputFocus}
                            />
                          </div>

                          <div className="col-lg-12">
                            <label
                              style={{ fontWeight: "bold" }}
                              htmlFor="town"
                            >
                              Nome Dono do Cartão<span>*</span>:
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Nome Completo"
                              required
                              onChange={this.handleInputChange}
                              onFocus={this.handleInputFocus}
                            />
                          </div>

                          <div className="col-lg-6">
                            <label
                              style={{ fontWeight: "bold" }}
                              htmlFor="town"
                            >
                              Validade<span>*</span>:
                            </label>
                            <input
                              type="tel"
                              name="expiry"
                              className="form-control"
                              placeholder="**/**"
                              pattern="\d\d/\d\d"
                              required
                              onChange={this.handleInputChange}
                              onFocus={this.handleInputFocus}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              style={{ fontWeight: "bold" }}
                              htmlFor="town"
                            >
                              {" "}
                              CVC<span>*</span>:
                            </label>
                            <input
                              type="password"
                              name="cvc"
                              maxLength={4}
                              className="form-control"
                              placeholder="CVC"
                              pattern="\d{3,4}"
                              required
                              onChange={this.handleInputChange}
                              onFocus={this.handleInputFocus}
                            />
                          </div>

                          <div className="col-lg-12">
                            <label
                              style={{ fontWeight: "bold" }}
                              htmlFor="town"
                            >
                              Parcelamento
                              <span />:
                            </label>

                            <select
                              className="form-control"
                              value={this.state.parcela}
                              onChange={this.onChangeParcela}
                              name="status"
                              style={{ height: "46px" }}
                            >
                              {this.state.parcelamento &&
                                this.state.parcelamento.map((parcela) => (
                                  <option
                                    value={
                                      parcela.parcela +
                                      "x" +
                                      Number(parcela.valor).toFixed(2) +
                                      " " +
                                      parcela.tipo
                                    }
                                  >
                                    {parcela.parcela +
                                      " x R$" +
                                      Number(parcela.valor).toFixed(2)}{" "}
                                    {parcela.tipo}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    &nbsp;&nbsp;
                    <div className="order-btn">
                      <button
                        onClick={() => this.salvar(carrinho, loja)}
                        className="site-btn place-btn"
                        style={{
                          backgroundColor: "green",
                          border: "none",
                          color: "white",
                          padding: "12px 27px",
                          textAlign: "center",
                          textDecoration: "none",
                          display: "inline-block",
                          fontSize: "14px",
                          borderRadius: "5px 5px 5px 5px",
                        }}
                      >
                        Finalizar Compra
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  carrinho: state.carrinho,
  loja: state.loja,
});

const mapDispatchToProps = (dispatch) => ({
  getCarrinho: () => dispatch(getCarrinho()),
  salvaCarrinho: (carrinho) => dispatch(salvaCarrinho(carrinho)),
  getLoja: () => dispatch(getLoja()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(FormPagamento));
