import React, {Component} from 'react';
import ApiService from '../service/ApiService';
import Moment from 'react-moment';
import {withCookies} from 'react-cookie';
import {Redirect} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import QRCode from 'react-qr-code';

class FormPix extends Component {
  constructor () {
    super ();
    this.state = {
      pedido: {},
      submitted2: false,
      submitted:false,
      eventos: [],
      isActive: false,
      textoloading: '',
      message: '',
      countdown: 0,
    };
    this.processarpedido = this.processarpedido.bind (this);
  }

  componentWillUnmount () {
    clearInterval (this.state.countdown);
  }

  componentDidMount () {
    ApiService.lojatitulo ()
    .then (response => {
      if (response.status === 200) {
        document.title = response.data + ' | Pedido';
      }
    })
    .catch (e => {
      ///  alert ('Erro ao recuperar idtawkto email');
      console.log (e);
    });
    //  window.scrollTo (0, 0);
    var user = this.props.cookies.get ('user') || '';

    if (user === '') {
      this.setState ({submitted2: true});
    }

    const search = window.location.search;
    const params = new URLSearchParams (search);
    var numero = params.get ('numero');

    ApiService.pedidopix (numero, user.usuario)
      .then (response => {
        if (response.status === 200) {
          this.setState ({
            pedido: response.data,
          });
          var countdown = setInterval (
            () => this.processarpedido (numero, user.usuario),
            3000
          );
          this.setState ({countdown: countdown});
          console.log (response.data);
        } else {
          this.setState ({message: 'Erro ao recuperar pedido'});
        }
      })
      .catch (e => {
        this.setState ({message: 'Erro ao recuperar pedido'});
        console.log (e);
      });

    /* ApiService.eventosp (numero)
      .then (response => {
        this.setState ({
          eventos: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar eventos'});
        console.log (e);
      });*/
  }

  processarpedido (numero, usuario) {
    console.log ('processando pedido ' + numero + ' ' + usuario);
    ApiService.pedidopago (numero, usuario)
      .then (response => {
        if (response.status === 200) {
         
          this.setState ({
            pedidon: response.data.numeropedido,
          });
          if(response.data.status=='Pago'){
            this.setState ({
              submitted: true,
            });
          }
          console.log (response.data);
        } else {
          //this.setState ({message: 'Erro ao recuperar pedido'});
        }
      })
      .catch (e => {
        // this.setState ({message: 'Erro ao recuperar pedido'});
        console.log (e);
      });
  }

  render () {
    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login?redirect=pedido" />;
    }
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to={'/confirmacao?numero=' + this.state.pedidon} />;
    }
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text={this.state.textoloading}
      >
        <section className="content">
          <div className="container">
            {this.state.message &&
              <div className="alert alert-danger alert-dismissible">
                {this.state.message}
              </div>}
            {this.state.pedido.status == 'Pix' &&
              <div className="row">

                <div className="col-6">
                  <span style={{color: 'green', fontWeight: 'bold'}}>
                    Para a conclusão da compra, escaneie o QR Code abaixo com seu aplicativo Pix.
                  </span><br /><br />

                  <div style={{textAlign: 'center'}}>
                    <QRCode value={this.state.pedido.qrcode} />
                  </div>

                </div>

                <div className="col-6">
                  <span>
                    Data:<Moment format="DD/MM/YYYY HH:mm:ss">
                      {this.state.pedido.datapedido}
                    </Moment>
                  </span><br />
                  <span>Pedido:{this.state.pedido.numeropedido}</span><br />
                  <span>
                    Valor SubTotal:
                    {' '}
                    {Number (this.state.pedido.subtotal).toFixed (2)}
                  </span>
                  <br />
                  <span>
                    Valor Total: {Number (this.state.pedido.total).toFixed (2)}
                  </span>
                  <br />
                  <span>Pagamento: PIX</span><br />
                </div>

              </div>}
          </div>
          <br />
          <br />
          <br />
        </section>
      </LoadingOverlay>
    );
  }
}

export default withCookies (FormPix);
